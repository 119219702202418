import axios from 'axios';
import findIndex from "lodash/findIndex";
import { planIsLoading, planIsLoaded } from "./plan";

import {checkFileSize, collectErrors, documentUrl} from "./helpers";
import {getRefId, setGetParam, errorMessage, isLoggedIn} from "./global";
import {API_URL} from '../config/config';
import {
    USER_SESSION,
    USER_SESSION_IS_LOADING,
    USER_SESSION_LOADED,
    USER_ACTIVATION_STATE,
    USER_COURSES,
    USER_COURSES_LOADING,
    USER_DOCUMENTS,
    USER_DOCUMENTS_ARE_LOADING,
    USER_DOCUMENT_IS_UPLOADING,
    USER_PROFILE_IS_UPDATING,
    USER_PROFILE_UPDATED,
    USER_CONSULT_INFO,
    SET_USER_COURSE_SLUG
} from "../constants/users";
import {
    COUNTRIES_ARE_LOADING, COUNTRIES_CODES_LIST, COUNTRIES_LIST, IP_ADDRESS, COUNTRY_CODE
} from "../constants/global";
import {FILE_MAX_SIZE_ERROR, UNABLE_TO_DELETE_COURSE} from "../constants/errors";
import i18next from "i18next";
import { SOURCE } from "../constants/global";


export function fetchUserInfo(locale) {
    return (dispatch, getState) => {
        const sessionLoaded = getState().users.sessionLoaded;

        if(!isLoggedIn()) {
            dispatch(userSessionIsLoading(false));
            return;
        }

        if(!sessionLoaded) {

            dispatch(userSessionIsLoading(true));

            const config = {params: { locale: locale }};

            axios.get(`${API_URL}user/session/`, config)
                .then((response) => {
                    return response.data;
                })
                .then((response) => {
                    dispatch(userActivationStatus(response.data.attributes["activation-state"] === "active"));
                    dispatch(fetchUserSessionSuccess(response.data.attributes));
                    dispatch(userSessionIsLoading(false));
                    dispatch(userSessionLoaded(true));

                })
                .catch((error) => {
                    console.log("error loading user info", error);
                    dispatch(userSessionIsLoading(false));
                    dispatch(userSessionLoaded(false));
                });
        } else {
            dispatch(userSessionIsLoading(false));
        }
    }
}

export function fetchUserSessionSuccess(user) {
    return {
        type: USER_SESSION,
        user
    };
}

export function userSessionIsLoading(bool) {
    return {
        type: USER_SESSION_IS_LOADING,
        bool
    };
}

export function userSessionLoaded(bool) {
    return {
        type: USER_SESSION_LOADED,
        bool
    };
}

export function userActivationStatus(bool) {
    return {
        type: USER_ACTIVATION_STATE,
        bool
    };
}

export function fetchUserCourses(locale) {
    return (dispatch) => {
        dispatch(coursesAreLoading(true));

        const config = {params: { locale: locale }};

        axios.get(`${API_URL}user/courses`, config)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch(fetchUserCoursesSuccess(response.data));
                dispatch(coursesAreLoading(false));
            })
            .catch((error) => console.log("error loading user courses", error));
    }
}

function coursesAreLoading(bool) {
    return {
        type: USER_COURSES_LOADING,
        bool
    };
}

function fetchUserCoursesSuccess(courses) {
    return {
        type: USER_COURSES,
        courses
    };
}

export function deleteUserCourse(slug) {
    return (dispatch, getState) => {
        const courses = getState().users.courses;

        axios.delete(`${API_URL}user/courses/${slug}`)
            .then((response) => {
                const newCourses = courses.filter(item => item.attributes.slug !== slug);
                dispatch(fetchUserCoursesSuccess(newCourses));
                return;
            })
            .catch((error) => {
                console.log("error deleting user course", error);
                dispatch(errorMessage(collectErrors({ error: [i18next.t(UNABLE_TO_DELETE_COURSE)] })));
            });
    }
}

export function fetchUserDocuments() {
    return (dispatch) => {

        dispatch(documentsAreLoading(true));

        axios.get(`${API_URL}user/documents`)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch(fetchUserDocumentsSuccess(response.data));
                dispatch(documentsAreLoading(false));
            })
            .catch((error) => console.log("error loading user documents", error));
    }
}

function documentsAreLoading(bool) {
    return {
        type: USER_DOCUMENTS_ARE_LOADING,
        bool
    };
}

function fetchUserDocumentsSuccess(documents) {
    return {
        type: USER_DOCUMENTS,
        documents
    };
}

export function submitDocument(categoryId, type, file) {

    return (dispatch, getState) => {
        let documents = getState().users.documents;

        if(!checkFileSize(file.size)) {
            dispatch(errorMessage(collectErrors({ error: [i18next.t(FILE_MAX_SIZE_ERROR)] })));
            return;
        }

        dispatch(documentIsUploading(type));

        const formData = new FormData();
        formData.append('file', file);
        formData.append('category_id', categoryId);

        axios.post(`${API_URL}user/documents`, formData)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                // update documents state
                const docIdx = findIndex(documents,doc => doc["attributes"]["category-id"] === categoryId);

                if(docIdx === -1) {
                    documents.push(response.data);
                } else {
                    documents[docIdx] = response.data;
                }

                dispatch(fetchUserDocumentsSuccess([...documents]));

                dispatch(documentIsUploading(""));
            })
            .catch((error) => {
                console.log("error uploading document", error)
                dispatch(documentIsUploading(""));
            });
    }
}

function documentIsUploading(docType) {
    return {
        type: USER_DOCUMENT_IS_UPLOADING,
        docType
    };
}

export function deleteDocument(id) {
    return (dispatch, getState) => {
        let documents = getState().users.documents;

        axios.delete(`${API_URL}user/documents/${id}`)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                const docIdx = findIndex(documents,doc => doc["id"] === id);

                documents.splice(docIdx, 1);
                dispatch(fetchUserDocumentsSuccess([...documents]));

            })
            .catch((e) => console.log("error deleting document", e));
    }
}

export function viewDocument(id) {
    return (dispatch) => {

        axios.get(`${API_URL}user/documents/${id}`)
            .then((response) => {
                documentUrl(response.data.url);

                return response.data;
            })
            .catch((e) => console.log("error viewing document", e));
    }
}

export function submitProfile(values, file) {
    return (dispatch) => {

        if(file && !checkFileSize(file.size)) {
            dispatch(errorMessage(collectErrors({ error: [i18next.t(FILE_MAX_SIZE_ERROR)] })));
            return;
        }

        dispatch(userProfileIsUpdating(true));
        dispatch(userProfileUpdated(false));

        let formData = new FormData();
        if(file) {
            formData.append("photo", file);
        }

        if(values) {
            for (let key in values) {
                formData.append(key, values[key]);
            }
        }

        axios.put(`${API_URL}user/account`, formData)
            .then((response) => {
                dispatch(fetchUserSessionSuccess(response.data.data.attributes));
                dispatch(userProfileIsUpdating(false));
                dispatch(userProfileUpdated(true));

                return response;
            })
            .catch((error) => {
                dispatch(errorMessage(collectErrors(error.response.data.errors)));
                dispatch(userProfileIsUpdating(false));
                dispatch(userProfileUpdated(false));
            });
    }
}

function userProfileIsUpdating(bool) {
    return {
        type: USER_PROFILE_IS_UPDATING,
        bool
    };
}

function userProfileUpdated(bool) {
    return {
        type: USER_PROFILE_UPDATED,
        bool
    };
}

export function cropProfileAvatar(data) {
    return (dispatch) => {

        let formData = new FormData();

        if(data.data) {
            for (let key in data.data) {
                formData.append(key, data.k*data.data[key]);
            }
        }

        axios.post(`${API_URL}user/account/crop`, formData)
            .then((response) => {
                dispatch(fetchUserSessionSuccess(response.data.data.attributes));

                return response;
            })
            .catch((e) => console.log("error", e));
    }
}

export function fetchCountries(locale) {
    return (dispatch) => {

        dispatch(countriesAreLoading(true));
        const config = { params: { locale: locale } };

        axios.get(`${API_URL}countries`, config)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch(fetchCountriesSuccess(response));
                dispatch(countriesAreLoading(false));

                let countriesCodes = [];
                response.forEach(item => {
                    if(!countriesCodes[item.phone_code]) {
                        countriesCodes[item.phone_code] = [];
                    }
                    countriesCodes[item.phone_code].push(item);
                });
                dispatch(fetchCountriesCodesSuccess(countriesCodes));

            })
            .catch((error) => console.log("error loading countries", error));
    }
}

export function countriesAreLoading(bool) {
    return {
        type: COUNTRIES_ARE_LOADING,
        bool
    };
}

export function fetchCountriesSuccess(data) {
    return {
        type: COUNTRIES_LIST,
        data
    };
}

export function fetchCountriesCodesSuccess(data) {
    return {
        type: COUNTRIES_CODES_LIST,
        data
    };
}

export function getIpAddress() {
    return (dispatch) => {

        axios.get(`https://get.geojs.io/v1/ip/country.json`)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                dispatch(ipAddressSuccess(response.ip));
                dispatch(countryCodeSuccess(response.country));
            })
            .catch((error) => console.log("error fetch ip", error));
    }
}

function ipAddressSuccess(value) {
    return {
        type: IP_ADDRESS,
        value
    };
}

function countryCodeSuccess(value) {
    return {
        type: COUNTRY_CODE,
        value
    };
}

export function fetchConsultInfo(refId, locale) {
    return (dispatch, getState) => {
        let config = {};
        const storedRefId = getRefId();

        if(refId || storedRefId) {
            config = {params: {source: SOURCE, locale: locale, refid: refId ? refId:storedRefId}};
        }

        axios.get(`${API_URL}user/consultant`, config)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                const data = response.data;
                if(data.id) {
                    setGetParam("refid", data.id);
                }
                dispatch(fetchUserConsultSuccess(data));
            })
            .catch((error) => console.log("error loading user consult", error));
    }
}

export function fetchUserConsultSuccess(data) {
    return {
        type: USER_CONSULT_INFO,
        data
    };
}

export function fetchProgramRequest(refP) {
    return (dispatch) => {

        const formData = new FormData();
        formData.append('program_name', refP);

        axios.post(`${API_URL}user/programs/request`, formData)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
            })
            .catch((error) => console.log("error", error));
    }
}

export function setUserCourse(courseId) {
    return (dispatch, getState) => {
        const data = new FormData();

        data.append("id", courseId);

        axios.post(`${API_URL}user/courses`, data)
            .then((response) => {
                if (response.status !== 200) {
                    throw Error(response.statusText);
                }

                return response.data;
            })
            .then((response) => {
                dispatch(setUserCourseSlug(response.data.attributes.slug));
            })
            .catch((e) => {
                dispatch(planIsLoaded(false));
                dispatch(planIsLoading(false));
                return console.log("error adding course", e)});
    }
}

export function setUserCourseSlug(data) {
    return {
        type: SET_USER_COURSE_SLUG,
        data
    };
}
