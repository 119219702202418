import { useEffect, useState } from "react";
import { Form, Input, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams, useNavigate, Navigate } from "react-router-dom";
import { setGetParam } from "../../actions/global";
import { userRegistration, userSignInSocial } from "../../actions/auth";
import { getIpAddress, fetchCountries } from "../../actions/users";
import MainLayout from "../layout";
import { loadingIcon, UserIcon, MailIcon, PhoneIcon } from "../utils/svg";
import InputMask from "../utils/input-mask";
import { RUSSIAN_COUNTRY_CODE, RUSSIAN_PHONE_MASK, MASK_LETTER, RUSSIAN_PHONE_CODE } from "../../constants/global";
import { checkRussianPhoneNumber } from "../../validators/russian-phone-number";
import { checkPhoneNumber } from "../../validators/phone-number";
import { Title, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { GoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import { RETURN_URL } from "../../config/config";

const { Option } = Select;

function Register() {
  const [countryCode, setCountryCode] = useState(null);
  const [oldCountryCode, setOldCountryCode] = useState(null);
  const [phoneCode, setPhoneCode] = useState(null);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { countriesList, countriesCodesList, initialCountryCode } = useSelector((state) => state.users);
  const { authFormIsSending, userRegistrationSuccess } = useSelector((state) => state.auth);
  const { t } = useTranslation();
  let locale = i18next.language;
  const { slug } = useParams();

  let phone = null;

  useEffect(() => {
    if (slug) {
      setGetParam("referral", slug);

      navigate({
        pathname: "/register",
      });
    }
  }, []);

  useEffect(() => {
    window.ym(56168566, "hit", "/sign_in");
  });

  useEffect(() => {
    dispatch(fetchCountries(locale));
    dispatch(getIpAddress());
  }, [dispatch, locale]);

  useEffect(() => {
    form.setFieldsValue({
      phone: phone,
    });
  }, [form, phone]);

  useEffect(() => {
    const countryCodeLower = initialCountryCode.toLowerCase();

    form.setFieldsValue({
      country_id: countryCodeLower,
    });

    const country = countriesList.filter((item) => item.code === countryCodeLower);
    if (country.length) {
      setPhoneCode(country[0].phone_code);
    }

    setCountryCode(countryCodeLower);
  }, [form, initialCountryCode, countriesList]);

  if (userRegistrationSuccess) {
    return <Navigate to="/profile" />;
  }

  const onFinish = (values) => {
    const params = new URLSearchParams(location.search);
    let formValues = { ...values, locale: locale, phone: !countryCode || countryCode === RUSSIAN_COUNTRY_CODE ? values.phone : `+${phoneCode}${values.phone}` };

    dispatch(userRegistration(formValues, params.get("refid"), params.get("refpr")));
    window.ym(56168566, "reachGoal", "register_button");
  };

  const findCountryByCode = (e) => {
    // TODO закомментил, а то, например, при выборе Питкерна, начинаешь номер вводить и он перескакивает на Новую Зеландию
    return;
    const { setFieldsValue } = form;

    const value = e.target.value;

    if (value[0] !== "+") {
      return;
    }

    if (!value[1]) return;

    const oneLetterCode = value[1];

    if (oneLetterCode && countriesCodesList[oneLetterCode]) {
      setFieldsValue({
        country_id: countriesCodesList[oneLetterCode][0].code,
      });
      setCountryCode(countriesCodesList[oneLetterCode][0].code);
      return;
    }

    if (!value[2]) return;

    const twoLetterCode = oneLetterCode + value[2];
    if (twoLetterCode && countriesCodesList[twoLetterCode]) {
      setFieldsValue({
        country_id: countriesCodesList[twoLetterCode][0].code,
      });
      setCountryCode(countriesCodesList[twoLetterCode][0].code);
      return;
    }

    if (!value[3]) return;

    const threeLetterCode = twoLetterCode + value[3];
    if (threeLetterCode && countriesCodesList[threeLetterCode]) {
      setFieldsValue({
        country_id: countriesCodesList[threeLetterCode][0].code,
      });
      setCountryCode(countriesCodesList[threeLetterCode][0].code);
    }
  };

  const changePhoneCode = (countryCode) => {
    const { getFieldValue, setFieldsValue } = form;
    const phone = getFieldValue("phone");

    if (!countryCode) {
      return;
    }

    const country = countriesList.filter((item) => item.code === countryCode);
    const oldCountry = countriesList.filter((item) => item.code === oldCountryCode);

    if (!country.length) {
      return;
    }

    setCountryCode(country[0].code);
    setPhoneCode(country[0].phone_code);
    setOldCountryCode(countryCode);
    setFieldsValue({
      phone: "",
    });
    // TODO максимально упростил тут логику - сменил код страны, вводи заново
    return;

    if (!phone) {
      setFieldsValue({
        phone: `+${country[0].phone_code}`,
      });
    } else {
      // try to replace code
      const firstLetter = phone[0];

      if (firstLetter !== "+") {
        setFieldsValue({
          phone: `+${country[0].phone_code} ${phone}`,
        });
      } else {
        if (oldCountry.length) {
          setFieldsValue({
            phone: phone.replace(`+${oldCountry[0].phone_code}`, `+${country[0].phone_code}`),
          });
        } else {
          setFieldsValue({
            phone: `+${country[0].phone_code}`,
          });
        }
      }
    }
  };

  const handleRecoveryBtn = () => {
    window.ym(56168566, "reachGoal", "register_to_recovery_button");
  };

  const title = {
    caption: t("ui.head.register_title"),
  };

  const signInGoogle = (response) => {
    dispatch(userSignInSocial("google", response.credential));
  };

  const signInApple = (response) => {
    dispatch(userSignInSocial("apple", response.authorization.id_token));
  };

  return (
    <MainLayout chat={false} title={title}>
      <section className="page__auth">
        <Form colon={false} onFinish={onFinish} autoComplete="off" className="form form_auth" form={form}>
          <Title decor={false} className="form__title" data-key="ui.signup">
            {t("ui.signup")}
          </Title>
          <h3 className="page__intro page__intro_center page__auth-intro" data-key="auth.subtitle">
            {t("auth.subtitle")}
          </h3>

          <div className="form__wrapper">
            <Form.Item
              name="name"
              className="form__item"
              rules={[
                {
                  required: true,
                  message: t("auth.enter_full_name"),
                },
              ]}
              validateTrigger="onSubmit"
              data-key="auth.enter_full_name"
            >
              <Input placeholder={t("auth.full_name")} prefix={<UserIcon />} data-key="auth.full_name" />
            </Form.Item>
            <Form.Item
              name="email"
              className="form__item"
              rules={[
                {
                  required: true,
                  message: t("auth.enter_email"),
                },
                {
                  type: "email",
                  message: t("auth.enter_correct_email"),
                },
              ]}
              validateTrigger="onSubmit"
              data-key="auth.enter_email, auth.enter_correct_email"
            >
              <Input placeholder={t("auth.email")} prefix={<MailIcon />} data-key="auth.email" />
            </Form.Item>
            <Form.Item className="form__item form__item_select" name="country_id" data-key="ui.not_specified">
              <Select
                placeholder={t("ui.not_specified")}
                onChange={changePhoneCode}
                showSearch
                filterOption={(input, option) => option.children.props.children[1].props.children.toLowerCase().includes(input.toLowerCase())}
              >
                {countriesList.map((item, key) => (
                  <Option key={item.id} value={item.code}>
                    <div className="select-country-item">
                      <div className="select-country-item__image">
                        <img src={item.image} alt="" />
                      </div>
                      <span className="select-country-item__name">{item.name}</span>
                      <span className="select-country-item__code">(+{item.phone_code})</span>
                    </div>
                  </Option>
                ))}
              </Select>
            </Form.Item>
            {countryCode === RUSSIAN_COUNTRY_CODE ? (
              <Form.Item
                name="phone"
                className="form__item"
                rules={[
                  {
                    required: true,
                    message: t("auth.enter_phone"),
                  },
                  {
                    validator: checkRussianPhoneNumber,
                  },
                ]}
                validateTrigger="onSubmit"
                data-key="auth.enter_phone"
                data-key-placeholder="auth.phone"
              >
                <InputMask icon={<PhoneIcon />} placeholder={t("auth.phone")} format={RUSSIAN_PHONE_MASK} mask={MASK_LETTER} />
              </Form.Item>
            ) : (
              <Form.Item
                name="phone"
                className="form__item"
                rules={[
                  {
                    required: true,
                    message: t("auth.enter_phone"),
                  },
                  {
                    validator: checkPhoneNumber,
                  },
                ]}
                validateTrigger="onSubmit"
                data-key="auth.enter_phone"
              >
                <Input
                  prefix={
                    <div className="ant-input-prefix-text">
                      <PhoneIcon /> {phoneCode ? `+${phoneCode}` : RUSSIAN_PHONE_CODE}
                    </div>
                  }
                  autoComplete="off"
                />
              </Form.Item>
            )}
            <Button type="submit" maxWidth={true} disabled={authFormIsSending} data-key="ui.signup">
              {authFormIsSending ? <Spin indicator={loadingIcon} /> : t("ui.signup")}
            </Button>

            <Link to="/sign_in" className="btn btn_outline btn_max-width-sm form__button-link" data-key="ui.login">
              {t("ui.login")}
            </Link>

            <div className="form__note form__note_separator" data-key="auth.or">
              {t("auth.or")}
            </div>

            <div className="form__button-wrap">
              <div className="form__button">
                <GoogleLogin onSuccess={(codeResponse) => signInGoogle(codeResponse)} onError={(error) => console.error(error)} type="icon" shape="circle" />
              </div>
              <div className="form__button">
                <AppleSignin
                  authOptions={{
                    clientId: "com.siil.web.auth",
                    scope: "email name",
                    redirectURI: RETURN_URL,
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  uiType="light"
                  className="apple-auth-btn"
                  onSuccess={(response) => signInApple(response)}
                  onError={(error) => console.error(error)}
                  buttonExtraChildren=""
                />
              </div>
            </div>
          </div>
          <div className="form__note form__note_inverse" data-key="auth.note_recovery">
            {t("auth.note_recovery")}{" "}
            <Link to="/recovery" onClick={handleRecoveryBtn} data-key="auth.note_recovery_btn">
              {t("auth.note_recovery_btn")}
            </Link>
            .
          </div>
        </Form>
      </section>
    </MainLayout>
  );
}

export default Register;
