import React, { useEffect } from "react";
import { useCountdown } from "./useCountdown";
import DateTimeDisplay from "./DateTimeDisplay";
import { useTranslation } from "react-i18next";

const ShowCounter = ({ hours, minutes, seconds }) => {
  const { t } = useTranslation();

  return (
    <div className="notification" data-key="test.expired_notification">
      <div className="notification__inner">
        {t("test.expired_notification")}
        {` `}
        <DateTimeDisplay value={hours} />
        :
        <DateTimeDisplay value={minutes} />
        :
        <DateTimeDisplay value={seconds} />
      </div>
    </div>
  );
};

const CountdownTimer = ({ targetDate, diffDate, isExpired, setExpired }) => {
  const [hours, minutes, seconds] = useCountdown(targetDate, diffDate);

  useEffect(() => {
    if (hours + minutes + seconds <= 0) {
      setExpired(true);
    } else {
      isExpired && setExpired(false);
    }
  }, [hours, minutes, seconds]);

  return <>{hours + minutes + seconds > 0 && <ShowCounter hours={hours} minutes={minutes} seconds={seconds} />}</>;
};

export default CountdownTimer;
