import axios from "axios";
import { WORKS_DATA_LOADING, WORKS_DATA, SEND_RATING_WORKS_SUCCESS, RATING_WORKS_IS_SENDING } from "../constants/works";
import { SOURCE } from "../constants/global";

import { API_URL } from "../config/config";
import { collectErrors } from "./helpers";
import { errorMessage } from "./global";

export function fetchWorksData(locale) {
  return (dispatch) => {
    dispatch(worksAreLoading(true));
    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}staff/homeworks`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(worksDataSuccess(response.data.data));
        dispatch(worksAreLoading(false));
        return response;
      })
      .catch((e) => {
        console.log("error loading works", e);
      });
  };
}

function worksAreLoading(bool) {
  return {
    type: WORKS_DATA_LOADING,
    bool,
  };
}

function worksDataSuccess(data) {
  return {
    type: WORKS_DATA,
    data,
  };
}

export const removeWork = (id) => ({
  type: "WORKS_REMOVE",
  payload: {
    id,
  },
});

export function sendRatingWorks(id, values) {
  return (dispatch) => {
    dispatch(formRatingWorksSending(true));
    dispatch(sendRatingWorksSuccess(false));

    axios
      .put(`${API_URL}staff/homeworks/${id}`, values)
      .then((response) => {
        dispatch(sendRatingWorksSuccess(true));
        dispatch(formRatingWorksSending(false));
      })
      .catch((error) => {
        dispatch(errorMessage(collectErrors(error.response.data.errors)));
        dispatch(formRatingWorksSending(false));
      });
  };
}

function sendRatingWorksSuccess(bool) {
  return {
    type: SEND_RATING_WORKS_SUCCESS,
    bool,
  };
}

function formRatingWorksSending(bool) {
  return {
    type: RATING_WORKS_IS_SENDING,
    bool,
  };
}
