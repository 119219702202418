import {Link} from "react-router-dom";
import { Title } from "@vshp/ui-components";

import MainLayout from "../../layout";
import { useTranslation } from "react-i18next";


function PasswordSuccess(props) {
    const { t } = useTranslation();

    return (
        <MainLayout chat={false} title={{ caption: t("ui.head.password_title_success") }}>
            <section className="page__auth">
                <div className="form form_auth">
                    <Title decor={false} className="form__title" data-key="auth.password_changed_title">{t("auth.password_changed_title")}</Title>
                    <div className="form__note" data-key="auth.password_changed_text">{t("auth.password_changed_text")}</div>
                    <Link className="btn btn_primary btn_max-width-sm" to="/profile" data-key="ui.account">{t("ui.account")}</Link>
                </div>
            </section>
        </MainLayout>
    )
}

export default PasswordSuccess;
