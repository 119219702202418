import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link, useLocation} from "react-router-dom";

import {fetchUserCourses, fetchProgramRequest} from "../../actions/users";
import ProfileProgram from "./program";
import {Loading} from "../utils/loading";
import useWindowSize from "../../hooks/size";
import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


function ProfilePrograms(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const {courses, coursesAreLoading} = useSelector(state => state.users);
    const coursesLength = coursesAreLoading ? 0 : courses.length;
    const params = new URLSearchParams(location.search);
    const refP = params.get("refp");

    const { t } = useTranslation();

    const size = useWindowSize();
    const isTablet = size.width >= 768 && size.width < 1025;
    const elsCount = isTablet ? 2 : 3;
    let locale = i18next.language;

    useEffect(() => {
        if(refP) {
            dispatch(fetchProgramRequest(refP));
        }

        dispatch(fetchUserCourses(locale));
    }, [dispatch, refP, locale]);

    return (
        <Section className="programs" border={true}>
            <Title type="h2" data-key="profile.programs_title">{t("profile.programs_title")}</Title>
            <div className="programs-feed">
                {coursesAreLoading ? (<Loading />):(courses.length ? courses.map((item, key) => {
                    return (<ProfileProgram key={key} item={item}/>);
                }) : <div className="programs-feed__notice" data-key="profile.programs_empty">{t("profile.programs_empty")}</div>)}
                <div className={`programs__add${coursesLength && coursesLength % elsCount !== 0 ? " programs__add_short":""}`}>
                    <div className="programs__add-container">
                        <Link className="btn btn_primary" to="/programs" data-key="profile.program_add">{t("profile.program_add")}</Link>
                    </div>
                </div>
            </div>
        </Section>
    )
}

export default ProfilePrograms;
