import { useSelector } from "react-redux";
import MainLayout from "../layout";
import PlanList from "./plan-list";
import PlanPaymentsInfo from "./plan-payments-info";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

const PlanSemester = () => {
  const { t } = useTranslation();
  const { plan, planSemesters } = useSelector((state) => state.plan);

  const semesterNames = plan.attributes.course.semester_names;
  const materials = plan.attributes.course.materials;

  const title = {
    caption: plan.attributes.course.title,
    intro: plan.attributes.course.tagline,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_plan page__header_short">
        <img className="page__header-cover" src={plan.attributes.photo.cover} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="plan.title">
            {t("plan.title")}
          </Title>
          <h2 className="page__header-subtitle t-h2">{plan.attributes.course.title}</h2>
        </div>
      </div>
      <div className="page__blocks">
      <PlanList curriculum={planSemesters} materials={materials} semesterNames={semesterNames} completed={plan.attributes.completed} planSlug={plan.attributes.slug} />

      {!plan.attributes.free && <PlanPaymentsInfo plan={plan} />}
      </div>

    </MainLayout>
  );
};

export default PlanSemester;
