import { combineReducers } from 'redux';
import {
    USER_SESSION,
    USER_SESSION_LOADED,
    USER_SESSION_IS_LOADING,
    USER_COURSES,
    USER_COURSES_LOADING,
    USER_DOCUMENTS,
    USER_DOCUMENTS_ARE_LOADING,
    USER_DOCUMENT_IS_UPLOADING,
    USER_PROFILE_IS_UPDATING,
    USER_PROFILE_UPDATED,
    USER_CONSULT_INFO,
    SET_USER_COURSE_SLUG
} from "../constants/users";
import {
    IP_ADDRESS,
    COUNTRY_CODE,
    COUNTRIES_LIST,
    COUNTRIES_CODES_LIST,
    COUNTRIES_ARE_LOADING
} from "../constants/global";


export function session(state = {}, action) {
    switch (action.type) {
        case USER_SESSION:
            return action.user;
        default:
            return state;
    }
}

export function sessionIsLoading(state = true, action) {
    switch (action.type) {
        case USER_SESSION_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function sessionLoaded(state = false, action) {
    switch (action.type) {
        case USER_SESSION_LOADED:
            return action.bool;
        default:
            return state;
    }
}

export function courses(state = [], action) {
    switch (action.type) {
        case USER_COURSES:
            return action.courses;
        default:
            return state;
    }
}

export function coursesAreLoading(state = true, action) {
    switch (action.type) {
        case USER_COURSES_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function documents(state = [], action) {
    switch (action.type) {
        case USER_DOCUMENTS:
            return action.documents;
        default:
            return state;
    }
}

export function documentsAreLoading(state = true, action) {
    switch (action.type) {
        case USER_DOCUMENTS_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function documentIsUploading(state = "", action) {
    switch (action.type) {
        case USER_DOCUMENT_IS_UPLOADING:
            return action.docType;
        default:
            return state;
    }
}

export function profileUpdated(state = false, action) {
    switch (action.type) {
        case USER_PROFILE_UPDATED:
            return action.bool;
        default:
            return state;
    }
}

export function profileIsUpdating(state = false, action) {
    switch (action.type) {
        case USER_PROFILE_IS_UPDATING:
            return action.bool;
        default:
            return state;
    }
}

export function countriesAreLoading(state = true, action) {
    switch (action.type) {
        case COUNTRIES_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function countriesList(state = [], action) {
    switch (action.type) {
        case COUNTRIES_LIST:
            return action.data;
        default:
            return state;
    }
}

export function countriesCodesList(state = [], action) {
    switch (action.type) {
        case COUNTRIES_CODES_LIST:
            return action.data;
        default:
            return state;
    }
}

export function ipAddress(state = '', action) {
    switch (action.type) {
        case IP_ADDRESS:
            return action.value;
        default:
            return state
    }
}

export function countryCode(state = '', action) {
    switch (action.type) {
        case COUNTRY_CODE:
            return action.value;
        default:
            return state
    }
}

export function userConsult(state = {}, action) {
    switch (action.type) {
        case USER_CONSULT_INFO:
            return action.data;
        default:
            return state;
    }
}

export function setUserCourseSlug(state = '', action) {
    switch (action.type) {
        case SET_USER_COURSE_SLUG:
            return action.data;
        default:
            return state
    }
}

export const usersReducers = () => combineReducers({
    session: session,
    sessionIsLoading: sessionIsLoading,
    sessionLoaded: sessionLoaded,
    courses: courses,
    coursesAreLoading: coursesAreLoading,
    documents: documents,
    documentsAreLoading: documentsAreLoading,
    documentIsUploading: documentIsUploading,
    profileIsUpdating: profileIsUpdating,
    profileUpdated: profileUpdated,
    countriesAreLoading: countriesAreLoading,
    countriesList: countriesList,
    countriesCodesList: countriesCodesList,
    ipAddress: ipAddress,
    initialCountryCode: countryCode,
    userConsult: userConsult,
    setUserCourseSlug: setUserCourseSlug
});
