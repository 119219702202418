import { InfoIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";

const HeaderNoticeError = () => {
  const { t } = useTranslation();

  return (
    <div className="notice notice_red">
      <div className="notice__inner">
        <div className="notice__info-icon">
          <InfoIcon />
        </div>
        <div className="notice__content">
          <div className="notice__title" data-key="ui.error_server_notice">{t("ui.error_server_notice")}</div>
        </div>
      </div>
    </div>
  );
};

export default HeaderNoticeError;
