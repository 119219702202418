import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, Outlet, useLocation } from "react-router-dom";
import { fetchPlanBySlug } from "../../actions/plan";
import { Loading } from "../utils/loading";
import PageNotFound from "../not-found";
import i18next from "i18next";
import { setUserCourse } from "../../actions/users";

function Plan() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { state, search } = useLocation();
  const { planIsLoading, planIsLoaded, planTestCompleteResponse } = useSelector((state) => state.plan);
  const { setUserCourseSlug } = useSelector((state) => state.users);
  let locale = i18next.language;

  const params = new URLSearchParams(search);
  const refCid = params.get("refcid");

  useEffect(() => {
    if (refCid && setUserCourseSlug.length === 0) {
      dispatch(setUserCourse(refCid));
    } else if (refCid) {
      dispatch(fetchPlanBySlug(setUserCourseSlug, locale));
    } else {
      dispatch(fetchPlanBySlug(slug, locale));
    }
  }, [dispatch, slug, refCid, setUserCourseSlug, locale]);

  useEffect(() => {
    state !== null && state.passed && planTestCompleteResponse.passed === true && dispatch(fetchPlanBySlug(slug, locale));
  }, [state]);

  if (planIsLoading) {
    return <Loading contentPreloader />;
  }

  if (!planIsLoaded) {
    return <PageNotFound />;
  }

  return (
    <>
      <Outlet />
    </>
  );
}

export default Plan;
