import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Slider from "../utils/Slider";
import { fetchSchedule } from "../../actions/schedule";
import { ArchiveIcon } from "../utils/svg";
import { Loading } from "../utils/loading";
import { Title, Section } from "@vshp/ui-components";
import { formatDate, formatTime } from "../utils/format-date";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function ProfileSchedule() {
  const { schedule, scheduleAreLoading, scheduleIsArchive } = useSelector((state) => state.schedule);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  let locale = i18next.language;

  useEffect(() => {
    dispatch(fetchSchedule(locale));
  }, [dispatch, locale]);

  const settings = {
    slidesToShow: 3,
    arrows: true,
    infinite: false,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1220,
        settings: {
          arrows: false,
          slidesToShow: 3,
          variableWidth: false,
        },
      },
      {
        breakpoint: 1075,
        settings: {
          arrows: false,
          slidesToShow: 2,
          variableWidth: false,
        },
      },
      {
        breakpoint: 920,
        settings: {
          arrows: false,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          arrows: false,
          slidesToShow: 1,
          variableWidth: true,
        },
      },
    ],
  };

  return (
    <Section border={true}>
      <Title className="schedule__title" type="h2" data-key="profile.schedule_title">
        {t("profile.schedule_title")}
        {scheduleIsArchive && (
          <Link className="btn btn_primary btn_small schedule__archive" to="/archive">
            <ArchiveIcon />
            <span>{t("profile.schedule.archive")}</span>
          </Link>
        )}
      </Title>

      {scheduleAreLoading ? (
        <Loading />
      ) : schedule.length > 0 ? (
        <div className="schedule">
          <Slider className="schedule__slider slider__dots_accent" sliderSettings={settings}>
            {schedule.map(({ name, place, username, start_at, end_at, extra }, key) => (
              <div className="slider__item" key={key}>
                <div className="schedule__item-wrap">
                  <div className="schedule__item">
                    <div className="schedule__item-body">
                      <div className="schedule__item-label">{place}</div>
                      <div className="schedule__item-header">
                        <div className="schedule__item-title">{t("profile.schedule_item.event_title")}</div>
                        <div className="schedule__item-subtitle">{name}</div>
                      </div>
                      <div className="schedule__item-row">
                        <div className="schedule__item-title">{t("profile.schedule_item.date_title")}</div>
                        <div className="schedule__item-subtitle">
                          {formatDate("ru", start_at)}, {formatTime("ru", start_at)} - {formatTime("ru", end_at)}
                        </div>
                      </div>
                      <div className="schedule__item-row">
                        <div className="schedule__item-title">{t("profile.schedule_item.responsible_title")}</div>
                        <div className="schedule__item-subtitle">{username}</div>
                      </div>
                    </div>
                    {extra && (
                      <a className="btn btn_primary schedule__item-more" href={extra} target="_blank" rel="noopener noreferrer">
                        {t("profile.schedule_item.webinar_title")}
                      </a>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="wysiwyg">
          <b>{t("profile.schedule.empty")}</b>
        </div>
      )}
    </Section>
  );
}

export default ProfileSchedule;
