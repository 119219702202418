import PlanListItem from "./plan-list-item";
import PlanListEmpty from "./plan-list-empty";
import { List } from "@vshp/ui-components";

function PlanListSection(props) {
  const { semester, planSlug, semesterNumber, hideCompleted, semesterName } = props;

  return (
    <List headerText={semesterName}>
      {semester.length ? (
        semester.map((item, key) =>
          hideCompleted && item.state === "completed" ? null : (
            <PlanListItem
              key={key}
              item={item}
              planSlug={planSlug}
              semesterNumber={semesterNumber}
            />
          )
        )
      ) : (
        <PlanListEmpty />
      )}
    </List>
  );
}

export default PlanListSection;
