import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { fetchProgramsPopular } from "../../actions/programs";
import { Loading } from "../utils/loading";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import ProgramCard from "./card";
import { Title, Section } from "@vshp/ui-components";

function ProgramsPopular() {
  const dispatch = useDispatch();
  const { programsPopular, programsPopularLoading } = useSelector((state) => state.programs);
  const { t } = useTranslation();
  let locale = i18n.language;

  useEffect(() => {
    dispatch(fetchProgramsPopular(locale));
  }, [dispatch, locale]);

  const randomProgramsPopular = _.sampleSize(programsPopular, 3);
  const renderProgramCard = (data) => data.map((item, key) => <ProgramCard key={key} item={item} />);

  return programsPopular.length > 0 ? (
    <Section>
      <Title type="h2" decor={false} data-key="program.popular_programs">
        {t("program.popular_programs")}
      </Title>
      <div className="programs-feed__wrapper">{programsPopularLoading ? <Loading /> : renderProgramCard(randomProgramsPopular)}</div>
    </Section>
  ) : null;
}

export default ProgramsPopular;
