import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import DayJS from "react-dayjs";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT } from "../../constants/global";
import { fetchWebinar } from "../../actions/webinars";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import PageNotFound from "../not-found";
import { Title } from "@vshp/ui-components";
import Plyr from "plyr-react";
import webinarBg from "../../images/profile.jpg";

const WebinarPage = () => {
  const { external_id } = useParams();

  const dispatch = useDispatch();
  const { webinar, webinarIsLoading, webinarLoaded } = useSelector((state) => state.webinars);
  const { t } = useTranslation();

  let content = null;
  let title = null;

  useEffect(() => {
    dispatch(fetchWebinar(external_id));
  }, [dispatch, external_id]);

  if (webinarIsLoading) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  if (!webinarLoaded) {
    const reason = "webinar.error_not_exist";
    return <PageNotFound reason={reason} />;
  }

  const recordings = webinar.recordings;
  const videos = recordings.filter((record) => record.human_type === "video");
  const videosCount = videos.length;

  const renderTitle = (count) => {
    if (count === 1) {
      return <Title type="h2" data-key="webinar.title">{t("webinar.title")}</Title>;
    } else if (count > 1) {
      return <Title type="h2" data-key="webinar.title_plural">{t("webinar.title_plural")}</Title>;
    } else {
      return (
        <>
          <Title type="h2" data-key="webinar.not_ready">{t("webinar.not_ready")}</Title>
          <p data-key="webinar.try_later">{t("webinar.try_later")}</p>
        </>
      );
    }
  };

  const renderRecord = (record) => {
    let result;
    const link = record.url;
    switch (record.human_type) {
      case "video":
        const configVideo = {
          type: "video",
          sources: [
            {
              src: link,
            },
          ],
        };
        result = (
          <>
            {videosCount > 1 && <h3 data-key="webinar.video_title">{t("webinar.video_title")}{" "}{record.date}</h3>}
            <Plyr source={configVideo} options={{ preload: "none" }} />
          </>
        );
        break;
      case "audio":
        const configAudio = {
          type: "audio",
          preload: "none",
          sources: [
            {
              src: link,
            },
          ],
        };
        result = (
          <>
            <h3 data-key="webinar.audio_only">{t("webinar.audio_only")}:</h3>
            <Plyr source={configAudio} />
          </>
        );
        break;
      case "text":
        result = (
          <a href={link} data-key="webinar.text_download_txt">{t("webinar.text_download_txt")}, {record.file_size})</a>
        );
        break;
      default:
        break;
    }
    return result;
  };

  title = {
    caption: `${t("webinar.name")} ${webinar.name}`,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={webinarBg} alt="" />
        <div className="page__header-body page__header-body_center">
          <h2 className="page__header-subtitle">
            <DayJS format={DATE_FORMAT}>{webinar.start_at}</DayJS>
          </h2>
          <Title decor={false} className="page__header-title">
            {webinar.name}
          </Title>
          <div
            className="page__intro"
            dangerouslySetInnerHTML={{ __html: webinar.description }}
          ></div>
        </div>
      </div>
      <div className="page__blocks">

      <div className="page__block">
        {recordings.map((record, id) => {
          return (
            <div className="wysiwyg webinar__item" key={id}>
              <div className="wysiwyg__block webinar__item-inner">
                {id === 0 && renderTitle(videosCount)}
                {renderRecord(record)}
              </div>
            </div>
          );
        })}
      </div>
      </div>
    </MainLayout>
  );
}

export default WebinarPage;
