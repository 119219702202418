import { Title, Section } from "@vshp/ui-components";
import { useSelector } from "react-redux";
import offerBg from "../../images/try.jpg";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

function Offer() {
  const { t } = useTranslation();
  const { programsPopular } = useSelector((state) => state.programs);

  return (
    <Section coverSrc={offerBg} centered={true} noSpaceBottom={!programsPopular.length > 0} coverType="light">
      <Title type="h2" decor={false}>
        <span data-key="offer.heading" dangerouslySetInnerHTML={{ __html: t("offer.heading") }}></span>
      </Title>

      <Link className="btn btn_primary" to="/programs" rel="noopener noreferrer" data-key="offer.button_start">
        {t("offer.button_start")}
      </Link>
    </Section>
  );
}

export default Offer;
