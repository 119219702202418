import { List, Switch } from "@vshp/ui-components";

import { setGetParam } from "../../actions/global";
import { useTranslation } from "react-i18next";

function PlanSettings(props) {
  const { completed } = props;
  const { t } = useTranslation();

  const onChange = () => {
    props.onChange(!completed);
    setGetParam("hideCompleted", !completed);
  };

  return (
    <List headerText={t("plan.settings_title")} data-key="plan.settings_title">
      <List.Item
        className="plan-settings"
        title={t("plan.settings_hide")}
        titleClass="plan-settings__title"
        mark={<Switch defaultChecked={completed} onChange={onChange} />}
        data-key="plan.settings_hide"
      />
    </List>
  );
}

export default PlanSettings;
