import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import MainLayout from "../layout";
import { Loading } from "../utils/loading";
import ProgramCard from "./card";
import { Title, Section } from "@vshp/ui-components";
import PageNotFound from "../not-found";
import programsBg from "../../images/programs.jpg";
import ProgramsFilter from "./filter";
import { useTranslation } from "react-i18next";

function ProgramList() {
  const { programsDegrees, programsDegreesAreLoading, programs, programsAreLoading } = useSelector((state) => state.programs);
  const { slug } = useParams();
  const { t } = useTranslation();
  let degreeId = "";

  if (programsDegrees.length > 0) {
    const currentDegreeType = programsDegrees.filter((typeData) => typeData.attributes.slug === slug);
    currentDegreeType.length > 0 ? (degreeId = currentDegreeType[0].id) : (degreeId = null);
  }

  if (slug && !programsAreLoading && degreeId === null) {
    return <PageNotFound />;
  }

  const renderProgramCard = (data) => data.map((item, key) => <ProgramCard key={key} item={item} />);

  return (
    <MainLayout title={{ caption: t("ui.head.programs_title") }} global={false}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={programsBg} alt={t("ui.head.programs_title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="ui.head.programs_title">
            {t("ui.head.programs_title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <Section>
          <ProgramsFilter slug={slug} degreeId={degreeId} />

          <div className="programs-feed">{programsAreLoading || programsDegreesAreLoading ? <Loading /> : renderProgramCard(programs)}</div>
        </Section>
      </div>
    </MainLayout>
  );
}

export default ProgramList;
