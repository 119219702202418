import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Spin, Select } from "antd";
import { Button } from "@vshp/ui-components";

import { MASK_LETTER, RUSSIAN_PHONE_CODE, RUSSIAN_COUNTRY_CODE, RUSSIAN_PHONE_MASK, LANGUAGES } from "../../../constants/global";
import InputMask from "../../utils/input-mask";
import { submitProfile, fetchCountries } from "../../../actions/users";
import { checkPasswordStrength } from "../../../validators/password-strength";
import { UserIcon, LockIcon, loadingIcon, PhoneIcon, MailIcon } from "../../utils/svg";
import { checkRussianPhoneNumber } from "../../../validators/russian-phone-number";
import { checkPhoneNumber } from "../../../validators/phone-number";
import { useTranslation } from "react-i18next";
import i18n from "i18next";

const { Option } = Select;

function ProfileForm(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const { profileIsUpdating, countriesList } = useSelector((state) => state.users);
  const { t } = useTranslation();
  let locale = i18n.language;

  const toFormatPhone = (phone, code) => {
    if (!phone) {
      return;
    }
    const phoneRegex = new RegExp("\\+" + (code ? code : RUSSIAN_PHONE_CODE) + "|[- )(]", "g");
    return phone.replace(phoneRegex, "");
  };

  const [countryCode, setCountryCode] = useState(user["country-code"]);
  const [phoneCode, setPhoneCode] = useState(user["phone-code"]);
  const [phone, setPhone] = useState(toFormatPhone(user.phone, phoneCode));

  const getCurrentCountry = () => {
    if (!user.phone) {
      return;
    }
    const phoneUser = user.phone;
    let result = "";
    let current;
    for (let num of phoneUser.slice(1)) {
      result += num;
      if (result === RUSSIAN_PHONE_CODE.slice(1)) {
        current = countriesList.filter((item) => item.code === RUSSIAN_COUNTRY_CODE);
      } else {
        const resultNum = parseInt(result, 10);
        current = countriesList.filter((item) => item.phone_code === resultNum);
      }
      if (current.length > 0) {
        const currentPhone = toFormatPhone(phoneUser, current[0].phone_code);
        setCountryCode(current[0].code);
        setPhoneCode(current[0].phone_code);
        setPhone(currentPhone);
        break;
      }
    }
  };

  useEffect(() => {
    dispatch(fetchCountries(locale));
  }, [dispatch, locale]);

  useEffect(() => {
    form.setFieldsValue({
      fake_name: user.name,
      phone: phone,
      password: "",
      country_id: countryCode,
      locale: user.locale,
      email: user.email,
    });
  }, [form, user, countryCode, phone]);

  if (countryCode === null && countriesList.length > 0) {
    getCurrentCountry();
  }

  const onFinish = (values) => {
    const country = countriesList.filter((item) => item.code === values["country_id"]);
    const countryId = country[0].id;

    let formValues = {
      ...values,
      phone: !values.phone || countryCode === RUSSIAN_COUNTRY_CODE || !countryCode ? values.phone : `+${phoneCode}${values.phone}`,
      country_id: countryId,
    };

    dispatch(submitProfile(formValues));
  };

  const changePhoneCode = (countryCode) => {
    if (!countryCode) {
      return;
    }
    const country = countriesList.filter((item) => item.code === countryCode);
    if (!country.length) {
      return;
    }
    setCountryCode(country[0].code);
    setPhoneCode(country[0].phone_code);
  };

  return (
    <Form colon={false} onFinish={onFinish} autoComplete="off" form={form} className="profile-info__form form form_profile">
      <Form.Item
        name="fake_name"
        className="form__item"
        rules={[
          {
            required: true,
            message: t("auth.enter_full_name"),
          },
        ]}
        validateTrigger="onSubmit"
        data-key="auth.enter_full_name"
      >
        <Input placeholder={t("auth.full_name")} prefix={<UserIcon />} data-key="auth.full_name" />
      </Form.Item>

      <Form.Item
        name="password"
        className="form__item form__item_single"
        rules={[
          {
            validator: checkPasswordStrength,
          },
        ]}
        validateTrigger="onSubmit"
      >
        <Input.Password placeholder={t("auth.password")} prefix={<LockIcon />} type="password" data-key="auth.password" />
      </Form.Item>

      <Form.Item className="form__item form__item_select" name="country_id" data-key="ui.not_specified">
        <Select placeholder={t("ui.not_specified")} onChange={changePhoneCode}>
          {countriesList.map((item, key) => (
            <Option key={item.id} value={item.code}>
              <div className="select-country-item">
                <div className="select-country-item__image">
                  <img src={item.image} alt="" />
                </div>
                <span className="select-country-item__name">{item.name}</span>
                <span className="select-country-item__code">(+{item.phone_code})</span>
              </div>
            </Option>
          ))}
        </Select>
      </Form.Item>

      {countryCode === RUSSIAN_COUNTRY_CODE || !countryCode ? (
        <Form.Item
          name="phone"
          className="form__item"
          rules={[
            {
              required: true,
              message: t("auth.enter_phone"),
            },
            {
              validator: checkRussianPhoneNumber,
            },
          ]}
          validateTrigger="onSubmit"
          data-key="auth.enter_phone, auth.phone"
        >
          <InputMask icon={<PhoneIcon />} placeholder={t("auth.phone")} format={RUSSIAN_PHONE_MASK} mask={MASK_LETTER} val={phone} />
        </Form.Item>
      ) : (
        <Form.Item
          name="phone"
          className="form__item"
          rules={[
            {
              required: true,
              message: t("auth.enter_phone"),
            },
            {
              validator: checkPhoneNumber,
            },
          ]}
          validateTrigger="onSubmit"
          data-key="auth.enter_phone"
        >
          <Input
            prefix={
              <div className="ant-input-prefix-text">
                <PhoneIcon /> {phoneCode ? `+${phoneCode}` : RUSSIAN_PHONE_CODE}
              </div>
            }
          />
        </Form.Item>
      )}

      <Form.Item className="form__item form__item_select" name="locale" data-key="ui.not_specified">
        <Select placeholder={t("ui.not_specified")}>
          {Object.entries(LANGUAGES).map(([key, value], id) => (
            <Option value={key} key={id}>
              {value}
            </Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="email"
        className="form__item form__item_single"
        rules={[
          {
            required: true,
            message: t("auth.enter_email"),
          },
          {
            type: "email",
            message: t("auth.enter_correct_email"),
          },
        ]}
        data-key="auth.enter_email, auth.enter_correct_email"
      >
        <Input placeholder={t("auth.email")} prefix={<MailIcon />} data-key="auth.email" />
      </Form.Item>

      <div className="form__buttons-group">
        <Button maxWidth={true} type="submit" disabled={profileIsUpdating} data-key="ui.save">
          {profileIsUpdating ? <Spin indicator={loadingIcon} /> : t("ui.save")}
        </Button>
        <Button maxWidth={true} onClick={props.onCancel} data-key="ui.cancel">
          {t("ui.cancel")}
        </Button>
      </div>
    </Form>
  );
}

export default ProfileForm;
