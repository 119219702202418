import { combineReducers } from "redux";
import { ARCHIVE, ARCHIVE_LOADING } from "../constants/archive";

export function archive(state = [], action) {
  switch (action.type) {
    case ARCHIVE:
      return action.data;
    default:
      return state;
  }
}

export function archiveAreLoading(state = true, action) {
  switch (action.type) {
    case ARCHIVE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const archiveReducers = () =>
  combineReducers({
    archive: archive,
    archiveAreLoading: archiveAreLoading,
  });
