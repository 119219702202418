import axios from "axios";
import { API_URL } from "../config/config";
import i18next from "i18next";

import { setToken, errorMessage, getGetParam, removeParam, getRefId, getReferral, getRoistatVisit } from "./global";

import { collectErrors } from "./helpers";
import { fetchUserConsultSuccess, fetchUserSessionSuccess, userActivationStatus, userSessionLoaded } from "./users";

import {
  AUTH_SUCCESS,
  AUTH_FORM_IS_SENDING,
  USER_RECOVERY_REQUEST_SUCCESS,
  USER_RECOVERY_PASSWORD_SUCCESS,
  USER_REGISTRATION_SUCCESS,
  USER_ACTIVATION_LINK_IS_SENDING,
  USER_ACTIVATION_LINK_SUCCESS,
  USER_ACTIVATION_PROGRESS,
  USER_ACTIVATION_STATUS,
  USER_ACTIVATION_PASSWORD_SUCCESS,
} from "../constants/auth";
import { WRONG_DATA_ERROR, NETWORK_ERROR, USER_BY_EMAIL_ERROR, SERVER_ERROR } from "../constants/errors";
import { SOURCE } from "../constants/global";

export function userSignIn(values) {
  return (dispatch) => {
    dispatch(authFormIsSending(true));

    let formData = new FormData();
    if (values) {
      for (let key in values) {
        formData.append(key, values[key]);
      }
    }

    formData.append("source", SOURCE);

    axios
      .post(`${API_URL}user/session/`, formData)
      .then((response) => {
        setToken(response.data.token);
        dispatch(authFormIsSending(false));
        dispatch(fetchUserConsultSuccess({}));
        // get user Info
        dispatch(userActivationStatus(response.data.user["activation-state"] === "active"));
        dispatch(fetchUserSessionSuccess(response.data.user));
        dispatch(userSessionLoaded(true));
      })
      .catch((e) => {
        if (e.response.status === 500) {
          dispatch(errorMessage(collectErrors({ errors: [i18next.t(SERVER_ERROR)] })));
        } else {
          dispatch(errorMessage(collectErrors({ errors: [i18next.t(WRONG_DATA_ERROR)] })));
        }

        dispatch(authFormIsSending(false));
        dispatch(userSessionLoaded(false));
      });
  };
}

function authFormIsSending(bool) {
  return {
    type: AUTH_FORM_IS_SENDING,
    bool,
  };
}

// TODO пока не выпиливаю, но надо с getToken в компонентах разбираться окончатеньно, тогда и эта штука шужна будет
export function authSuccess(bool) {
  return {
    type: AUTH_SUCCESS,
    bool,
  };
}

export function userRecovery(values) {
  return (dispatch) => {
    dispatch(authFormIsSending(true));
    dispatch(userRecoveryRequestSuccess(false));

    let formData = new FormData();
    if (values) {
      for (let key in values) {
        formData.append(key, values[key]);
      }
    }

    axios
      .post(`${API_URL}user/password`, formData)
      .then((response) => {
        dispatch(userRecoveryRequestSuccess(true));
        dispatch(authFormIsSending(false));
      })
      .catch((error) => {
        dispatch(errorMessage(collectErrors({ errors: [i18next.t(USER_BY_EMAIL_ERROR)] })));
        dispatch(authFormIsSending(false));
      });
  };
}

function userRecoveryRequestSuccess(bool) {
  return {
    type: USER_RECOVERY_REQUEST_SUCCESS,
    bool,
  };
}

export function userRegistration(values, refid, refpr) {
  return (dispatch, getState) => {
    const countries = getState().users.countriesList;
    const storedRefId = getRefId();
    const storedRoistatVisit = getRoistatVisit();
    const storedReferral = getReferral();
    const storedRefP = getGetParam("refp");
    const storedRefCid = getGetParam("refcid");
    const storedRefPr = getGetParam("refpr");
    const referer = getGetParam("referer");

    dispatch(authFormIsSending(true));
    dispatch(userRegistrationSuccess(false));

    let formData = new FormData();

    if (values) {
      for (let key in values) {
        if (key === "country_id") {
          const country = countries.filter((item) => item.code === values[key]);
          formData.append(`registration[${key}]`, country.length ? country[0].id : 0);
        } else {
          formData.append(`registration[${key}]`, values[key]);
        }
      }
    }

    if (storedRefId || refid) {
      formData.append("registration[refid]", refid ? refid : storedRefId);
    }

    if (storedRefPr || refpr) {
      formData.append("registration[refpr]", refpr ? refpr : storedRefPr);
    }

    if (storedRoistatVisit) {
      formData.append("registration[roistat_visit]", storedRoistatVisit);
    }

    if (storedReferral) {
      formData.append("registration[referral]", storedReferral);
    }

    if (storedRefCid) {
      formData.append("registration[refcid]", storedRefCid);
    } else {
      if (storedRefP) {
        formData.append("registration[refp]", storedRefP);
      }
    }

    if (referer) {
      formData.append("registration[referer]", referer);
    }

    formData.append("registration[source]", SOURCE);

    axios
      .post(`${API_URL}user/registration`, formData)
      .then((response) => {
        setToken(response.data.token);
        dispatch(userActivationStatus(response.data.user["activation-state"] === "active"));
        dispatch(fetchUserSessionSuccess(response.data.user));
        dispatch(userSessionLoaded(true));
        dispatch(userRegistrationSuccess(true));
        dispatch(authFormIsSending(false));

        removeParam("refp");
        removeParam("refid");
        removeParam("refcid");
        removeParam("referer");
      })
      .catch((error) => {
        dispatch(errorMessage(collectErrors(error.response.data.errors)));
        dispatch(authFormIsSending(false));
        dispatch(userSessionLoaded(false));
      });
  };
}

export function userRegistrationSuccess(bool) {
  return {
    type: USER_REGISTRATION_SUCCESS,
    bool,
  };
}

export function userRecoveryPassword(values, token) {
  return (dispatch) => {
    dispatch(authFormIsSending(true));
    dispatch(userRecoveryPasswordSuccess(false));

    let formData = new FormData();
    if (values) {
      for (let key in values) {
        formData.append(key, values[key]);
      }
    }

    formData.append("token", token);

    axios
      .put(`${API_URL}user/password`, formData)
      .then((response) => {
        dispatch(authFormIsSending(false));
        dispatch(userRecoveryPasswordSuccess(true));
      })
      .catch((error) => {
        dispatch(authFormIsSending(false));
      });
  };
}

function userRecoveryPasswordSuccess(bool) {
  return {
    type: USER_RECOVERY_PASSWORD_SUCCESS,
    bool,
  };
}

export function sendActivationLink(user) {
  return (dispatch) => {
    dispatch(activationLinkIsSending(true));
    dispatch(activationLinkSuccess(false));

    axios
      .post(`${API_URL}user/account/retry_activation`, {})
      .then((response) => {
        dispatch(activationLinkSuccess(true));
        dispatch(activationLinkIsSending(false));
      })
      .catch((error) => {
        dispatch(errorMessage(collectErrors({ error: [i18next.t(NETWORK_ERROR)] })));
        dispatch(activationLinkIsSending(false));
      });
  };
}

function activationLinkIsSending(bool) {
  return {
    type: USER_ACTIVATION_LINK_IS_SENDING,
    bool,
  };
}

function activationLinkSuccess(bool) {
  return {
    type: USER_ACTIVATION_LINK_SUCCESS,
    bool,
  };
}

export function userActivation(token) {
  return (dispatch) => {
    dispatch(activateUserProgress(true));
    dispatch(activateUserStatus(false));

    axios
      .get(`${API_URL}user/account/activation`, { params: { token: token } })
      .then((response) => {
        setToken(response.data.token);
        dispatch(activateUserProgress(false));
        dispatch(activateUserStatus(response.status === 200));
      })
      .catch((e) => {
        console.log("error activate user", e);
        dispatch(activateUserProgress(false));
      });
  };
}

function activateUserProgress(bool) {
  return {
    type: USER_ACTIVATION_PROGRESS,
    bool,
  };
}

function activateUserStatus(bool) {
  return {
    type: USER_ACTIVATION_STATUS,
    bool,
  };
}

export function userActivationPassword(values) {
  return (dispatch) => {
    dispatch(authFormIsSending(true));
    dispatch(userActivationPasswordSuccess(false));

    let formData = new FormData();
    if (values) {
      for (let key in values) {
        formData.append(key, values[key]);
      }
    }

    axios
      .post(`${API_URL}user/account/password`, formData)
      .then((response) => {
        dispatch(userActivationStatus(response.data.data.attributes["activation-state"] === "active"));
        dispatch(fetchUserSessionSuccess(response.data.data.attributes));
        dispatch(userSessionLoaded(true));
        dispatch(userActivationPasswordSuccess(true));
        dispatch(authFormIsSending(false));
      })
      .catch((error) => {
        dispatch(errorMessage(collectErrors(error.response.data.errors)));
        dispatch(authFormIsSending(false));
        dispatch(userSessionLoaded(false));
      });
  };
}

function userActivationPasswordSuccess(bool) {
  return {
    type: USER_ACTIVATION_PASSWORD_SUCCESS,
    bool,
  };
}

export function userAccountDelete() {
  return () => {
    axios
      .post(`${API_URL}user/account/delete`)
      .then((response) => {})
      .catch((e) => console.log("error loading account delete", e));
  };
}

export function userSignInSocial(type, accessToken) {
  return (dispatch) => {
    dispatch(authFormIsSending(true));

    let formData = new FormData();

    formData.append("source", SOURCE);
    formData.append("id_token", accessToken);

    axios
      .post(`${API_URL}user/id/${type}`, formData)
      .then((response) => {
        setToken(response.data.token);
        dispatch(authFormIsSending(false));
        dispatch(fetchUserConsultSuccess({}));
        // get user Info
        dispatch(userActivationStatus(response.data.user["activation-state"] === "active"));
        dispatch(fetchUserSessionSuccess(response.data.user));
        dispatch(authFormIsSending(false));
        dispatch(userSessionLoaded(true));
      })
      .catch((e) => {
        if (e.response.status === 500) {
          dispatch(errorMessage(collectErrors({ errors: [SERVER_ERROR] })));
        } else {
          dispatch(errorMessage(collectErrors({ errors: [WRONG_DATA_ERROR] })));
        }
        dispatch(authFormIsSending(false));
        dispatch(userSessionLoaded(false));
      });
  };
}
