import { useSelector } from "react-redux";

import MainLayout from "../layout";
import ProfileInfo from "./profile";
import ProfilePrograms from "./programs";
import ProfileDocuments from "./documents";
import ProfileWorks from "./works";
import { useTranslation } from "react-i18next";
import ProfileSchedule from "./schedule";

function Profile(props) {
  const { session, sessionIsLoading } = useSelector((state) => state.users);
  const { t } = useTranslation();

  return (
    <MainLayout title={{ caption: t("ui.head.account_title") }}>
      <ProfileInfo user={session} loading={sessionIsLoading} />
      <div className="page__blocks">
        <ProfileSchedule />
        <ProfilePrograms user={session} loading={sessionIsLoading} />
        <ProfileDocuments user={session} loading={sessionIsLoading} border={session.staff} />
        {session.staff && <ProfileWorks user={session} loading={sessionIsLoading} />}
      </div>
    </MainLayout>
  );
}

export default Profile;
