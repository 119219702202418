export const formatDate = (lng, value) =>
  new Intl.DateTimeFormat(lng, {
    year: "numeric",
    month: "numeric",
    day: "numeric",
  }).format(new Date(value * 1000));

export const formatTime = (lng, value) =>
  new Intl.DateTimeFormat(lng, {
    hour: "numeric",
    minute: "numeric",
  }).format(new Date(value * 1000));
