import { useDispatch } from "react-redux";
import { Form } from "antd";
import { getPaymentLink } from "../../actions/plan";
import InputMask from "../utils/input-mask";
import { WalletIcon } from "../utils/svg";
import { Title, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function PlanPaymentsForm(props) {
  const { plan } = props;
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const balance = plan.attributes.balance;
  const balanceNum = parseInt(balance, 10);
  const balanceCurrency = `0.00 ${plan.attributes["balance-currency"]}`;
  const { t } = useTranslation();

  const onFinish = (values) => {
    const formValues = { ...values, amount: values.amount.toString().replace(/\s+/g, "") };

    dispatch(getPaymentLink(plan.attributes.slug, formValues));
  };

  const checkAmount = (rule, value) => {
    value = value.toString().replace(/\s+/g, "");

    if (!value || +value > 0) {
      return Promise.resolve();
    }

    return Promise.reject(t("plan.payments_note"));
  };

  return (
    <div id="scroll" className="page__header page__header_short page__header_block">
      <img className="page__header-cover" src={plan.attributes.photo.cover} alt="" />

      <div className="page__header-body">
        <Title type="h2" decor={false} className="page__header-title page__header-title_center" data-key="plan.payments_enter_sum">
          {t("plan.payments_enter_sum")}
        </Title>

        <Form
          onFinish={onFinish}
          autoComplete="off"
          form={form}
          colon={false}
          className="form form_payments"
          initialValues={{
            amount: balanceNum < 0 ? balance.substring(1) : "",
          }}
        >
          <Form.Item
            name="amount"
            className="form__item"
            rules={[
              {
                required: true,
                message: t("plan.payments_enter_sum"),
              },
              {
                validator: checkAmount,
              },
            ]}
            validateTrigger="onSubmit"
            data-key="plan.payments_enter_sum"
          >
            <InputMask icon={<WalletIcon />} autoComplete="off" placeholder={balanceCurrency} thousandSeparator=" " val={balanceNum < 0 ? balance.substring(1) : ""} />
          </Form.Item>
          <Button maxWidth={true} type="submit" data-key="plan.payments_pay">
            {t("plan.payments_pay")}
          </Button>
        </Form>
      </div>
    </div>
  );
}

export default PlanPaymentsForm;
