import { combineReducers } from "redux";
import { SCHEDULE, SCHEDULE_LOADING, SCHEDULE_IS_ARCHIVE } from "../constants/schedule";

export function schedule(state = [], action) {
  switch (action.type) {
    case SCHEDULE:
      return action.data;
    default:
      return state;
  }
}

export function scheduleAreLoading(state = true, action) {
  switch (action.type) {
    case SCHEDULE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function scheduleIsArchive(state = false, action) {
  switch (action.type) {
    case SCHEDULE_IS_ARCHIVE:
      return action.bool;
    default:
      return state;
  }
}

export const scheduleReducers = () =>
  combineReducers({
    schedule: schedule,
    scheduleAreLoading: scheduleAreLoading,
    scheduleIsArchive: scheduleIsArchive,
  });
