import {RUSSIAN_PHONE_REGEX} from "../constants/global";
import i18next from "i18next";

export function checkRussianPhoneNumber(rule, value) {
    value = value || "";

    if(!value) {
        return Promise.resolve();
    }

    if(!value.match(RUSSIAN_PHONE_REGEX)) {
        return Promise.reject(i18next.t("auth.enter_phone_wrong_format"));
    }

    return Promise.resolve();
}
