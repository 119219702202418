import { Title, Button, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import programConsultationBg from "../../images/consultation.jpg";
import { CHAT_OPEN_CLASS } from "../../constants/global";

function Consultation() {
  const { t } = useTranslation();

  const handleClick = () => {
    document.body.classList.add(CHAT_OPEN_CLASS);
  };

  return (
    <Section coverSrc={programConsultationBg} coverAlt={t("program.consultation.title")} coverType="white" centered={true}>
      <Title type="h2" decor={false} data-key="program.consultation.title">
        {t("program.consultation.title")}
      </Title>
      <Section.BodySmall>
        <div data-key="program.consultation.description" dangerouslySetInnerHTML={{ __html: t("program.consultation.description") }}></div>
        <Button spaceTop={true} target="_blank" rel="noopener noreferrer" onClick={handleClick} data-key="program.consultation.ask_question">
          {t("program.consultation.ask_question")}
        </Button>
      </Section.BodySmall>
    </Section>
  );
}

export default Consultation;
