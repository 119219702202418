import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function ProgramInfo({ cost, degree, form, organization, term }) {
  const { t } = useTranslation();

  return (
    <Section>
      <Title type="h2" decor={false} data-key="program.details">
        {t("program.details")}
      </Title>
      <div className="program__info">
        <div className="program__info-row">
          <div className="program__info-title t-h3" data-key="program.details_organization">{t("program.details_organization")}</div>
          <div className="program__info-text">{organization.length ? organization : "—"}</div>
        </div>
        <div className="program__info-row">
          <div className="program__info-title t-h3" data-key="program.details_form">{t("program.details_form")}</div>
          <div className="program__info-text">{form.length ? form : "—"}</div>
        </div>
        <div className="program__info-row">
          <div className="program__info-title t-h3" data-key="program.details_degree">{t("program.details_degree")}</div>
          <div className="program__info-text">{degree.length ? degree : "—"}</div>
        </div>
        <div className="program__info-row">
          <div className="program__info-title t-h3" data-key="program.details_term">{t("program.details_term")}</div>
          <div className="program__info-text">{term.length ? term : "—"}</div>
        </div>
        <div className="program__info-row">
          <div className="program__info-title t-h3" data-key="program.details_cost">{t("program.details_cost")}</div>
          <div className="program__info-text">{cost.length ? cost : "—"}</div>
        </div>
      </div>
    </Section>
  );
}

export default ProgramInfo;
