import { useEffect } from 'react';
import {Form, Input, Spin} from "antd";
import {useDispatch, useSelector} from "react-redux";
import {userRecovery} from "../../actions/auth";
import MainLayout from "../layout";
import RecoverySuccess from "./success/recovery";
import {loadingIcon, MailIcon} from "../utils/svg";
import { Title, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function Recovery() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {userRecoverySuccess, authFormIsSending}= useSelector(state => state.auth);
    const { t } = useTranslation();
    const title = {
        caption: t("ui.head.recovery_title"),
    }
    useEffect(() => {
        window.ym(56168566, "hit", "/recovery");
    });

    const onFinish = values => {
        dispatch(userRecovery(values));
        window.ym(56168566, "reachGoal", "recovery_button");
    };

    if(userRecoverySuccess) {
        return <RecoverySuccess />;
    }

    return (<MainLayout chat={false} title={title}>
        <section className="page__auth">
            <Form
                colon={false}
                onFinish={onFinish}
                autoComplete="off"
                className="form form_auth"
                form={form}
            >
                <Title decor={false} className="form__title" data-key="ui.password_recovery">{t("ui.password_recovery")}</Title>
                <h3 className="page__intro page__intro_center page__auth-intro" data-key="auth.subtitle">
                    {t("auth.subtitle")}
                </h3>

                <div className="form__wrapper">
                    <Form.Item
                        name="email"
                        className="form__item form__item_single"
                        rules={[{
                            required: true,
                            message: t("auth.enter_email")
                        }, {
                            type: "email",
                            message: t("auth.enter_correct_email")
                        }
                        ]}
                        validateTrigger="onSubmit"
                        data-key="auth.enter_email, auth.enter_correct_email"
                    >
                        <Input placeholder={t("auth.email")} prefix={<MailIcon />} data-key="auth.email" />
                    </Form.Item>
                </div>
                <Button type="submit" maxWidth={true} disabled={authFormIsSending} data-key="auth.send">
                    {authFormIsSending ? <Spin indicator={loadingIcon} />: t("auth.send") }
                </Button>
                <div className="form__note form__note_inverse" data-key="auth.note_recovery_email">{t("auth.note_recovery_email")}</div>
            </Form>
        </section>
    </MainLayout>)
}

export default Recovery;
