import { Link } from "react-router-dom";
import Slider from "../utils/Slider";
import { useState } from "react";
import { Switch } from "@vshp/ui-components";

import PrismCode from "../utils/prism-code";
import { DocumentIcon } from "../utils/svg";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import Plyr from "plyr-react";
import { setGetParam, getGetParam } from "../../actions/global";

const PlanThemeContentText = (props) => {
  return (
    <div>
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      <div dangerouslySetInnerHTML={{ __html: props.text }}></div>
    </div>
  );
};

const PlanThemeContentList = (props) => {
  return (
    <div className="wysiwyg__steps">
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      <ol>
        {props.list.map((item, key) => {
          return (
            <li key={key}>
              <span>{item.content}</span>
              <div className="wysiwyg__steps-tail"></div>
            </li>
          );
        })}
      </ol>
    </div>
  );
};

const PlanThemeContentCode = (props) => {
  return (
    <div>
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      <PrismCode code={props.code} language="js" />
    </div>
  );
};

const PlanThemeContentImages = (props) => {
  return (
    <div>
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      {props.images.length > 1 ? (
        <Slider className="carousel-wrap slider__dots_blue">
          {props.images.map((item, key) => {
            return (
              <figure key={key}>
                <img src={item.image} alt="" />
                <figcaption>{item.content}</figcaption>
              </figure>
            );
          })}
        </Slider>
      ) : (
        props.images.map((item, key) => {
          return (
            <figure key={key}>
              <img src={item.image} alt="" />
              <figcaption>{item.content}</figcaption>
            </figure>
          );
        })
      )}
    </div>
  );
};

const PlanThemeContentVideo = (props) => {
  const { t } = useTranslation();

  const videoSettingsFlag = getGetParam("isYouTubeOn") === "true";
  const [isVideoYouTube, setStateVideoYouTube] = useState(videoSettingsFlag || false);

  const onChangeSwitch = () => {
    setGetParam("isYouTubeOn", !isVideoYouTube, 20 * 365);
    setStateVideoYouTube(!isVideoYouTube);
  };

  return (
    <div>
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      {props.item.videos.length > 1 ? (
        <>
          {isVideoYouTube && props.item.videos.some((el) => el.provider === "youtube")
            ? props.item.videos.map((video, key) => video.provider === "youtube" && <div className="video" key={key} dangerouslySetInnerHTML={{ __html: video.player }}></div>)
            : props.item.videos.map((video, key) => video.provider === "rutube" && <div className="video" key={key} dangerouslySetInnerHTML={{ __html: video.player }}></div>)}

          {props.item.videos.some((el) => el.provider === "youtube") && (
            <div className="video__settings" data-key="plan.video.settings_text">
              {t("plan.video.settings_text")}
              <Switch defaultChecked={isVideoYouTube} onChange={onChangeSwitch} />
            </div>
          )}
        </>
      ) : (
        <div className="video" dangerouslySetInnerHTML={{ __html: props.item.videos[0].player }}></div>
      )}
    </div>
  );
};

const PlanThemeContentButton = (props) => {
  return (
    <div className="wysiwyg__button-block">
      {props.title.length > 0 && <Title type="h2">{props.title}</Title>}
      {props.text.length > 0 && <div dangerouslySetInnerHTML={{ __html: props.text }}></div>}
      <div className="wysiwyg__button-block-container">
        <Link className="btn btn_outline" to={props.button.url}>
          {props.button.title}
        </Link>
      </div>
    </div>
  );
};

const PlanThemeContentDocuments = (props) => {
  const { t } = useTranslation();

  return (
    <div className="wysiwyg__documents">
      <Title type="h2" data-key="plan.materials_title">
        {t("plan.materials_title")}
      </Title>

      {props.files.map((item, key) => {
        return (
          <a href={item.material_source_url} key={key} className="wysiwyg__documents-item" target="_blank" rel="noreferrer">
            <span className="wysiwyg__documents-item-icon">
              <DocumentIcon />
            </span>
            <span className="wysiwyg__documents-item-label">{item.material_source_title}</span>
          </a>
        );
      })}
    </div>
  );
};

const PlanThemeContentAudio = ({ audio }) => {
  const configAudio = {
    type: "audio",
    preload: "none",
    sources: [
      {
        src: audio.file,
      },
    ],
  };

  return (
    <div>
      {audio.title.length > 0 && <Title type="h2">{audio.title}</Title>}
      {audio.content.length > 0 && <p dangerouslySetInnerHTML={{ __html: audio.content }}></p>}
      <Plyr source={configAudio} />
    </div>
  );
};

const PlanThemeContent = ({ content }) => {
  return (
    <div className="wysiwyg">
      {content.attributes.contents.map((item, key) => {
        let content = null;
        switch (item.kind) {
          case "text":
            content = <PlanThemeContentText text={item.content} title={item.title} />;
            break;
          case "list":
            content = <PlanThemeContentList list={item.contents} title={item.title} />;
            break;
          case "image":
            content = <PlanThemeContentImages images={item.contents} title={item.title} />;
            break;
          case "video":
            content = <PlanThemeContentVideo item={item} title={item.title} />;
            break;
          case "code":
            content = <PlanThemeContentCode code={item.content} title={item.title} />;
            break;
          case "button":
            content = <PlanThemeContentButton button={item.data} text={item.content} title={item.title} />;
            break;
          case "documents":
            content = <PlanThemeContentDocuments files={item.contents} />;
            break;
          case "audio":
            content = <PlanThemeContentAudio audio={item} />;
            break;
          default:
            break;
        }
        return (
          <div key={key} className="wysiwyg__block">
            {content}
          </div>
        );
      })}
    </div>
  );
};

export default PlanThemeContent;
