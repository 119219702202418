import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import { fetchPartners } from "../../actions/partners";
import { Loading } from "../utils/loading";
import PartnerItem from "./partner-item";
import _ from "lodash";
import i18n from "i18next";
import { URL } from "../../config/config";

function Partners() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  let locale = i18n.language;
  const { partners, partnersAreLoading } = useSelector((state) => state.partners);

  useEffect(() => {
    if (partners.length < 1) {
      dispatch(fetchPartners(locale));
    }
  }, [dispatch, locale]);

  const randomPartners = _.sampleSize(partners, 5);

  return (
    <>
      {partnersAreLoading ? (
        <Loading />
      ) : (
        <>
          <Title type="h2" data-key="partners.title">
            {t("partners.title")}
          </Title>
          <Section.BodySmall className="wysiwyg" data-key="partners.description">
            <p dangerouslySetInnerHTML={{ __html: t("partners.description") }}></p>
          </Section.BodySmall>

          <PartnerItem partners={randomPartners} locale={locale} />
          <div className="text-center">
            <a className="btn btn_outline btn_max-width-sm" href={`${URL}/partners`} data-key="partners.show_all">
              {t("partners.show_all")}
            </a>
          </div>
        </>
      )}
    </>
  );
}

export default Partners;
