import axios from "axios";
import { API_URL } from '../config/config';
import {
    PLAN,
    PLAN_IS_LOADING,
    PLAN_IS_LOADED,
    PLAN_PAYMENTS,
    PLAN_PAYMENTS_IS_LOADING,
    PAYMENT_LINK,
    PLAN_SEMESTER,
    PLAN_SEMESTER_IS_LOADING,
    PLAN_SEMESTER_THEMES,
    PLAN_SEMESTER_THEMES_ARE_LOADING,
    PLAN_SEMESTER_THEMES_LOADED,
    PLAN_SEMESTER_UNIT_IS_LOADING,
    PLAN_SEMESTER_UNIT_LOADED,
    PLAN_SEMESTER_UNIT,
    PLAN_TEST,
    PLAN_TEST_IS_LOADING,
    PLAN_TEST_ANSWER_IS_SENDING,
    PLAN_TEST_ANSWER_SUCCESS,
    PLAN_TEST_COMPLETE,
    HOMEWORK_IS_UPLOADING,
    PLAN_TEST_COMPLETE_RESPONSE,
    PLAN_TEST_RESULTS_SENDING,
    PLAN_UNIT_COMPLETE_SENDING,
    PLAN_UNIT_COMPLETE_DONE,
    PLAN_SEMESTERS,
    PLAN_SEMESTERS_UPDATE,
    QUESTIONS_WITHOUT_ANSWER
} from "../constants/plan";
import i18next from "i18next";

import {errorMessage} from "./global";

import {checkFilesSize, collectErrors, documentUrl} from "./helpers";
import {FILE_MAX_SIZE_ERROR} from "../constants/errors";


export function fetchPlanBySlug(slug, locale) {
    return (dispatch, getState) => {

        dispatch(planIsLoading(true));
        dispatch(planIsLoaded(false));

        const config = {params: {locale: locale}};

        axios.get(`${API_URL}user/courses/${slug}`, config)
            .then((response) => {
                return response;
            })
            .then((response) => {
                const plan = response.data.data;
                const semesters = [];
                const curriculum = plan.attributes.course.curriculum;

                curriculum.forEach((item, key) => {
                    let completed = true;
                    item.forEach(discipline => {
                        if(discipline.state !== "completed") {
                          completed = false;
                        }
                    });

                    semesters.push({
                        completed: completed,
                        data: item
                    });
                });

                dispatch(planSuccess({
                    ...plan,
                    attributes: {
                        ...plan.attributes,
                        course: {
                            ...plan.attributes.course,
                            curriculum: semesters
                        },
                        completed: plan.attributes["units-completed"] === plan.attributes["units-total"]
                    }
                }));

                dispatch(planSemesters(semesters));

                dispatch(planIsLoading(false));
                dispatch(planIsLoaded(true));

                return response;
            })
            .catch((e) => {
                console.log("error loading plan", e);
                dispatch(planIsLoading(false));
                dispatch(planIsLoaded(false));
            });
    };
}

export function planIsLoading(bool) {
    return {
        type: PLAN_IS_LOADING,
        bool
    };
}

export function planIsLoaded(bool) {
    return {
        type: PLAN_IS_LOADED,
        bool
    };
}

function planSuccess(data) {
    return {
        type: PLAN,
        data
    };
}

export function fetchPlanPaymentsBySlug(slug, locale) {
    return (dispatch) => {

        dispatch(planPaymentsIsLoading(true));

        const config = {params: {locale: locale}};

        axios.get(`${API_URL}user/courses/${slug}/payments`, config)
            .then((response) => {
                return response;
            })
            .then((response) => {

                dispatch(planPaymentsSuccess(response.data.data));
                dispatch(planPaymentsIsLoading(false));

                return response;
            })
            .catch((e) => console.log("error loading plan payments", e));
    };
}

function planPaymentsIsLoading(bool) {
    return {
        type: PLAN_PAYMENTS_IS_LOADING,
        bool
    };
}

function planPaymentsSuccess(data) {
    return {
        type: PLAN_PAYMENTS,
        data
    };
}

export function fetchPlanListBySemesterNumber(planSlug, semesterNumber) {
    return (dispatch) => {

        dispatch(planSemesterIsLoading(true));

        axios.get(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units`)
            .then((response) => {
                return response;
            })
            .then((response) => {

                dispatch(planSemesterSuccess(response.data.data));
                dispatch(planSemesterIsLoading(false));

                return response;
            })
            .catch((e) => console.log("error loading plan semester", e));
    };
}

function planSemesterIsLoading(bool) {
    return {
        type: PLAN_SEMESTER_IS_LOADING,
        bool
    };
}

function planSemesterSuccess(data) {
    return {
        type: PLAN_SEMESTER,
        data
    };
}

export function fetchPlanSemesterUnit(planSlug, semesterNumber, unitSlug, locale) {
    return (dispatch) => {
        dispatch(fetchPlanSemesterUnitIsLoading(true));
        dispatch(fetchPlanSemesterUnitLoaded(false));

        const config = {params: {locale: locale}};

        axios.get(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${unitSlug}`, config)
            .then((response) => {
                return response;
            })
            .then((response) => {

                dispatch(fetchPlanSemesterUnitLoaded(true));
                dispatch(fetchPlanSemesterUnitSuccess(response.data.data));
                dispatch(fetchPlanSemesterUnitIsLoading(false));

                return response;
            })
            .catch((e) => {
                console.log("error loading plan semester unit", e);
                dispatch(fetchPlanSemesterUnitLoaded(false));
                dispatch(fetchPlanSemesterUnitIsLoading(false));
            });
    };
}

function fetchPlanSemesterUnitIsLoading(bool) {
    return {
        type: PLAN_SEMESTER_UNIT_IS_LOADING,
        bool
    };
}

function fetchPlanSemesterUnitLoaded(bool) {
    return {
        type: PLAN_SEMESTER_UNIT_LOADED,
        bool
    };
}

function fetchPlanSemesterUnitSuccess(data) {
    return {
        type: PLAN_SEMESTER_UNIT,
        data
    };
}

export function fetchPlanSemesterThemes(planSlug, semesterNumber, topicSlug, locale) {
    return (dispatch) => {

        dispatch(planSemesterThemesLoaded(false));
        dispatch(planSemesterThemesAreLoading(true));

        const config = {params: {locale: locale}};

        axios.get(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${topicSlug}/topics`, config)
            .then((response) => {
                return response;
            })
            .then((response) => {

                dispatch(planSemesterThemesLoaded(true));
                dispatch(planSemesterThemesSuccess(response.data.data));
                dispatch(planSemesterThemesAreLoading(false));

                return response;
            })
            .catch((e) => {
                console.log("error loading plan semester themes", e);
                dispatch(planSemesterThemesLoaded(false));
                dispatch(planSemesterThemesAreLoading(false));
            });
    };
}

function planSemesterThemesAreLoading(bool) {
    return {
        type: PLAN_SEMESTER_THEMES_ARE_LOADING,
        bool
    };
}

function planSemesterThemesLoaded(bool) {
    return {
        type: PLAN_SEMESTER_THEMES_LOADED,
        bool
    };
}


function planSemesterThemesSuccess(data) {
    return {
        type: PLAN_SEMESTER_THEMES,
        data
    };
}

export function fetchPlanTest(planSlug, semesterNumber, themeSlug, locale) {
    return (dispatch) => {

        dispatch(planTestIsLoading(true));
        // обнуляем ответ теста (в случае перезагрузки, например попытки пройти второй раз)
        dispatch(testCompleteResponse({}));

        const config = {params: {locale: locale}};

        axios.get(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${themeSlug}/test`, config)
            .then((response) => {
                return response;
            })
            .then((response) => {

                dispatch(planTestSuccess(response.data));
                dispatch(planTestIsLoading(false));

                dispatch(isTestComplete(response.data.meta.completed));

                return response;
            })
            .catch((e) => console.log("error loading plan test", e));
    };
}

function planTestIsLoading(bool) {
    return {
        type: PLAN_TEST_IS_LOADING,
        bool
    };
}

function planTestSuccess(data) {
    return {
        type: PLAN_TEST,
        data
    };
}

export function sendAnswer(planSlug, semesterNumber, themeSlug, questionId, number, answerId) {
    return (dispatch, getState) => {
        let test = getState().plan.planTest;

        dispatch(sendingAnswer(true));

        let formData = new FormData();
        formData.append("question_id", questionId);
        formData.append("answer_id[]", answerId);

        test.data[number - 1].attributes["answer-ids"] = [answerId];

        axios.put(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${themeSlug}/test`, formData)
            .then((response) => {
                dispatch(questionsWithoutAnswer(response.data.without_answer));
                dispatch(planTestSuccess({...test}));
                dispatch(sendingAnswer(false));
                dispatch(sendingAnswerSuccess(true));
                dispatch(isTestComplete(response.data.completed));
                return response;
            })
            .catch((e) => {
                dispatch(sendingAnswerSuccess(false));
                console.log("error", e);
            });
    }
}

function sendingAnswer(bool) {
    return {
        type: PLAN_TEST_ANSWER_IS_SENDING,
        bool
    };
}

export function sendingAnswerSuccess(bool) {
    return {
        type: PLAN_TEST_ANSWER_SUCCESS,
        bool
    };
}

function isTestComplete(bool) {
    return {
        type: PLAN_TEST_COMPLETE,
        bool
    };
}

function questionsWithoutAnswer(data) {
    return {
        type: QUESTIONS_WITHOUT_ANSWER,
        data
    };
}

export function completePlanTest(planSlug, semesterNumber, themeSlug, locale) {
    return (dispatch, getState) => {

        dispatch(testCompleteResponse({}));
        dispatch(sendingTest(true));

        const config = {params: {locale: locale}};

        axios.post(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${themeSlug}/test/complete`, config)
            .then((response) => {
                dispatch(testCompleteResponse(response.data));
                dispatch(sendingTest(false));

                return response;
            })
            .catch((e) => {
                console.log("error", e);
                dispatch(sendingTest(false));
            });
    }
}

function testCompleteResponse(data) {
    return {
        type: PLAN_TEST_COMPLETE_RESPONSE,
        data
    };
}

function sendingTest(data) {
    return {
        type: PLAN_TEST_RESULTS_SENDING,
        data
    };
}

export function uploadWork(planSlug, semesterNumber, themeSlug, files) {
    return (dispatch, getState) => {
        let unit = getState().plan.planSemesterUnit;

        if(!checkFilesSize(files)) {
            dispatch(errorMessage(collectErrors({ error: [i18next.t(FILE_MAX_SIZE_ERROR)] })));
            return;
        }

        dispatch(workUploading(true));

        const formData = new FormData();

        for (let key in files) {
            formData.append(`files[]`, files[key]);
        }

        axios.post(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${themeSlug}/homework`, formData)
            .then((response) => {
                return response.data;
            })
            .then((response) => {
                // set new work status
                unit.attributes = {...unit.attributes, state: "moderation"};
                dispatch(fetchPlanSemesterUnitSuccess(unit));

                dispatch(workUploading(false));

            })
            .catch((error) => {
                console.log("error uploading work", error);
                dispatch(workUploading(false));
            });
    }
}

function workUploading(bool) {
    return {
        type: HOMEWORK_IS_UPLOADING,
        bool
    };
}

export function getPaymentLink(planSlug, values) {
    return (dispatch) => {
        dispatch(paymentLink(""));

        let formData = new FormData();
        if(values) {
            for (let key in values) {
                formData.append(key, values[key]);
            }
        }

        axios.post(`${API_URL}user/courses/${planSlug}/payments/`, formData)
            .then((response) => {
                dispatch(paymentLink(response.data.url));

                documentUrl(response.data.url, false);

                return response;
            })
            .catch((e) => console.log("error", e));
    }
}

function paymentLink(link) {
    return {
        type: PAYMENT_LINK,
        link
    };
}

export function completePlanUnit(planSlug, semesterNumber, themeSlug, locale, unitNumber, unitGrade) {
    return (dispatch, getState) => {
        dispatch(completePlanUnitSending(true));
        dispatch(completePlanUnitDone(false));

        const config = {params: {locale: locale}};

        axios.post(`${API_URL}user/courses/${planSlug}/semesters/${semesterNumber}/units/${themeSlug}/complete`, config)
            .then((response) => {
                dispatch(planSemestersUpdate(semesterNumber - 1, unitNumber, unitGrade));
                dispatch(completePlanUnitSending(false));
                dispatch(completePlanUnitDone(true));
                return response;
            })
            .catch((e) => {
                console.log("error", e);
                dispatch(completePlanUnitSending(false));
                dispatch(completePlanUnitDone(false));
            });
    }
}


function completePlanUnitSending(bool) {
    return {
        type: PLAN_UNIT_COMPLETE_SENDING,
        bool
    };
}

export function completePlanUnitDone(bool) {
    return {
        type: PLAN_UNIT_COMPLETE_DONE,
        bool
    };
}

function planSemesters(semesters) {
    return {
        type: PLAN_SEMESTERS,
        semesters
    };
}

function planSemestersUpdate(semesterNum, number, grade) {
    return {
        type: PLAN_SEMESTERS_UPDATE,
        semesterNum,
        number,
        grade
    };
}
