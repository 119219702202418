import { useTranslation } from "react-i18next";

const FailedQuestion = ({ info }) => {
  const { t } = useTranslation();

  return (
    <div className="test__note wysiwyg">
      <h3 data-key="test.failed.list_of_incorrect_answers">{t("test.failed.list_of_incorrect_answers")}</h3>
      <div className="test__note-errors">
        {info.errors.map(({ question, images }, id) => (
          <div key={id}>
            <div className="test__note-errors-question" data-key="test.failed.question" dangerouslySetInnerHTML={{ __html: `${t("test.failed.question")}: ${question}` }}></div>
            {images &&
              images.length > 0 &&
              images.map(({ image }, id) => <img className="test__question-img test__note-question-img" src={image} alt="" key={id} onClick={() => window.open(image, "_blank")} />)}
          </div>
        ))}
      </div>
    </div>
  );
};

export default FailedQuestion;
