import { useTranslation } from "react-i18next";

function PlanComplete(props) {
  const { t } = useTranslation();

    return (<div className="plan-complete">
        <h4 className="plan-complete__title plan-complete__title_mobile" data-key="plan.complete_title">{t("plan.complete_title")}</h4>
        <h4 className="plan-complete__title" data-key="plan.complete_title">{t("plan.complete_title")}</h4>
        <p className="plan-complete__note" data-key="plan.complete_text">{t("plan.complete_text")}</p>
    </div>)
}

export default PlanComplete;
