export const PLAN = "PLAN";
export const PLAN_IS_LOADING = "PLAN_IS_LOADING";
export const PLAN_IS_LOADED = "PLAN_IS_LOADED";
export const PLAN_PAYMENTS = "PLAN_PAYMENTS";
export const PLAN_PAYMENTS_IS_LOADING = "PLAN_PAYMENTS_IS_LOADING";
export const PAYMENT_LINK = "PAYMENT_LINK";
export const PLAN_SEMESTER = "PLAN_SEMESTER";
export const PLAN_SEMESTER_IS_LOADING = "PLAN_SEMESTER_IS_LOADING";
export const PLAN_SEMESTER_UNIT_IS_LOADING = "PLAN_SEMESTER_UNIT_IS_LOADING";
export const PLAN_SEMESTER_UNIT_LOADED = "PLAN_SEMESTER_UNIT_LOADED";
export const PLAN_SEMESTER_UNIT = "PLAN_SEMESTER_UNIT";
export const PLAN_SEMESTER_THEMES = "PLAN_SEMESTER_THEMES";
export const PLAN_SEMESTER_THEMES_ARE_LOADING = "PLAN_SEMESTER_THEMES_ARE_LOADING";
export const PLAN_SEMESTER_THEMES_LOADED = "PLAN_SEMESTER_THEMES_LOADED";
export const PLAN_TEST = "PLAN_TEST";
export const PLAN_TEST_IS_LOADING = "PLAN_TEST_IS_LOADING";
export const PLAN_TEST_ANSWER_IS_SENDING = "PLAN_TEST_ANSWER_IS_SENDING";
export const PLAN_TEST_ANSWER_SUCCESS = "PLAN_TEST_ANSWER_SUCCESS";
export const PLAN_TEST_COMPLETE = "PLAN_TEST_COMPLETE";
export const PLAN_TEST_COMPLETE_RESPONSE = "PLAN_TEST_COMPLETE_RESPONSE";
export const PLAN_TEST_RESULTS_SENDING = "PLAN_TEST_RESULTS_SENDING";
export const HOMEWORK_IS_UPLOADING = "HOMEWORK_IS_UPLOADING";
export const PLAN_UNIT_COMPLETE_SENDING = "PLAN_UNIT_COMPLETE_SENDING";
export const PLAN_UNIT_COMPLETE_DONE = "PLAN_UNIT_COMPLETE_DONE";
export const PLAN_SEMESTERS = "PLAN_SEMESTERS";
export const PLAN_SEMESTERS_UPDATE = "PLAN_SEMESTERS_UPDATE";
export const QUESTIONS_WITHOUT_ANSWER = "QUESTIONS_WITHOUT_ANSWER";
