export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FORM_IS_SENDING = "AUTH_FORM_IS_SENDING";
export const AUTH_FORM_RESPONSE = "AUTH_FORM_RESPONSE";
export const USER_RECOVERY_REQUEST_SUCCESS = "USER_RECOVERY_REQUEST_SUCCESS";
export const USER_RECOVERY_PASSWORD_SUCCESS = "USER_RECOVERY_PASSWORD_SUCCESS";
export const USER_REGISTRATION_SUCCESS = "USER_REGISTRATION_SUCCESS";
export const USER_ACTIVATION_PROGRESS = "USER_ACTIVATION_PROGRESS";
export const USER_ACTIVATION_STATUS = "USER_ACTIVATION_STATUS";
export const USER_ACTIVATION_LINK_IS_SENDING = "USER_ACTIVATION_LINK_IS_SENDING";
export const USER_ACTIVATION_LINK_SUCCESS = "USER_ACTIVATION_LINK_SUCCESS";
export const USER_ACTIVATION_PASSWORD_SUCCESS = "USER_ACTIVATION_PASSWORD_SUCCESS";