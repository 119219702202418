import axios from "axios";
import { SETTINGS, SETTINGS_LOADING, TRANSLATE, TRANSLATE_LOADING } from "../constants/settings";
import { errorServer } from "./global";
import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchSettings(locale) {
  return (dispatch) => {
    dispatch(settingsAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}settings`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(settingsSuccess(response.data));
        dispatch(settingsAreLoading(false));
        return response;
      })
      .catch((e) => {
        if (e.response.status === 500) {
          dispatch(errorServer(true));
        }
        console.log("error loading settings", e);
      });
  };
}

function settingsAreLoading(bool) {
  return {
    type: SETTINGS_LOADING,
    bool,
  };
}

function settingsSuccess(data) {
  return {
    type: SETTINGS,
    data,
  };
}

export function fetchTranslate(keys, locale) {
  return (dispatch) => {
    dispatch(translateLoading(true));

    const config = { params: { source: SOURCE, keys: keys, locale: locale } };

    axios
      .get(`${API_URL}translations`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(translateSuccess(response.data));
        dispatch(translateLoading(false));
        return response;
      })
      .catch((e) => console.log("error loading translate", e));
  };
}

function translateLoading(bool) {
  return {
    type: TRANSLATE_LOADING,
    bool,
  };
}

function translateSuccess(data) {
  return {
    type: TRANSLATE,
    data,
  };
}
