import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import teachersBg from "../../images/teachers.jpg";
import Slider from "../utils/Slider";

function Teachers({ teachers }) {
  const { t } = useTranslation();

  return (
    <Section coverSrc={teachersBg} coverAlt={t("teachers.title")}>
      <Title type="h2" decor={false} data-key="teachers.title">
        {t("teachers.title")}
      </Title>
      <div className="slider-description">
        <Slider className="slider-description__inner">
          {teachers.map((teacher, key) => (
            <div className="slider__item" key={key}>
              <div className="slider-description__item">
                <div className="slider-description__header">
                  <img className="slider-description__photo" src={teacher.attributes.photo.preview} alt={teacher.attributes.name} />
                  <div className="slider-description__name">{teacher.attributes.name}</div>
                  <div className="slider-description__post">
                    {teacher.attributes.position}
                    <br />
                    {teacher.attributes.organization}
                  </div>
                </div>
                <div className="slider-description__content" dangerouslySetInnerHTML={{ __html: teacher.attributes.description }}></div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </Section>
  );
}

export default Teachers;
