import React from "react";
import { createRoot } from "react-dom/client";
import { Provider } from "react-redux";

import { BrowserRouter } from "react-router-dom";
import configureStore from "./store";

import { ConfigProvider } from "antd";
import "./i18n";
import { Loading } from "./components/utils/loading";

import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GoogleOAuthProvider } from "@react-oauth/google";

export const store = configureStore(/* provide initial state if any */);
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <GoogleOAuthProvider clientId="1091607395268-ba5j1ev7lbjmo0mtpjms0ogp62qsi82u.apps.googleusercontent.com">
    <React.StrictMode>
      <Provider store={store}>
        <BrowserRouter>
          <ConfigProvider>
            <React.Suspense fallback={<Loading contentPreloader />}>
              <App />
            </React.Suspense>
          </ConfigProvider>
        </BrowserRouter>
      </Provider>
    </React.StrictMode>
  </GoogleOAuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
