import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty";

import ProfileAvatar from "./common/avatar";
import ProfileData from "./common/data";
import ProfileForm from "./common/form";
import ProfileNotice from "./notice";
import { useTranslation } from "react-i18next";

import {Loading} from "../utils/loading";
import { Title, Button } from "@vshp/ui-components";
import profileBg from "../../images/profile.jpg";


function ProfileInfo(props) {
    const [isEdit, toggleEdit] = useState(false);
    const {user, loading} = props;
    const emptyUser = isEmpty(user);
    const {profileUpdated} = useSelector(state => state.users);
    const { t } = useTranslation();

    const photoExists = loading ? false:user["photo-exists"];

    useEffect(() => {
        if(profileUpdated) {
            toggleEdit(!profileUpdated);
        }
    }, [profileUpdated]);

    const onCancel = () => {
        toggleEdit(!isEdit);
    }

    return (
        <div className="page__header">
            <img className="page__header-cover" src={profileBg} alt="" />
            <div className="page__header-body profile-info">
                <Title decor={false} data-key="ui.edit, ui.account">{isEdit ? t("ui.edit") : t("ui.account")}</Title>
                {emptyUser ? "":<ProfileAvatar
                    user={user}
                    isEdit={isEdit}
                    photoExists={photoExists}
                    loading={loading}
                />}

                {emptyUser ? <Loading />: isEdit ? <ProfileForm user={user} onCancel={onCancel}/>:<ProfileData user={user} />}

                {!emptyUser && user["activation-state"] !== "active" && <ProfileNotice user={user} />}

                {emptyUser || isEdit ? "":<Button onClick={() => toggleEdit(!isEdit)} data-key="ui.edit, ui.account">{!isEdit ? t("ui.edit") : t("ui.cancel")}</Button>}
            </div>
        </div>
    )
}

export default ProfileInfo;
