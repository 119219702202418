import { Navigate, useLocation, useParams } from "react-router-dom";
import { isLoggedIn, setGetParam } from "../../actions/global";
import { useSelector } from "react-redux";

const PrivateRoute = ({ children, redirect = true }) => {
  let isToken = isLoggedIn();
  let location = useLocation();
  const { sessionLoaded } = useSelector((state) => state.users);
  const { slug } = useParams();

  if (location.pathname.startsWith(`/r/`) && slug) {
    setGetParam("referral", slug);
  }

  if (!isToken && redirect) {
    return <Navigate to="/sign_in" state={{ from: location }} />;
  } else if (isToken && sessionLoaded && !redirect) {
    if (location.state !== null && location.state.from.pathname && location.state.from.pathname !== "/sign_out") {
      return <Navigate to={`${location.state.from.pathname}${location.state.from.search}`} state={{ from: location }} />;
    } else {
      return <Navigate to="/profile" state={{ from: location }} />;
    }
  }

  return children;
};

export default PrivateRoute;
