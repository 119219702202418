import { useEffect } from "react";
import { Form, Input, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { userSignIn, userSignInSocial } from "../../actions/auth";
import { isLoggedIn } from "../../actions/global";
import MainLayout from "../layout";
import { useTranslation } from "react-i18next";
import { Title, Button } from "@vshp/ui-components";
import { loadingIcon, LockIcon, UserIcon } from "../utils/svg";
import { GoogleLogin } from "@react-oauth/google";
import AppleSignin from "react-apple-signin-auth";
import { RETURN_URL } from "../../config/config";

function SignIn() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { authFormIsSending } = useSelector((state) => state.auth);
  const { sessionLoaded } = useSelector((state) => state.users);
  const { t } = useTranslation();
  const { state } = useLocation();

  const title = {
    caption: t("ui.head.auth_title"),
  };

  let auth = isLoggedIn() ? sessionLoaded : false;

  useEffect(() => {
    window.ym(56168566, "hit", "/register");
  });

  const onFinish = (values) => {
    dispatch(userSignIn(values));
    window.ym(56168566, "reachGoal", "sign_in_button");
  };

  useEffect(() => {
    if (auth) {
      if (state !== null && state.prev) {
        return navigate(state.prev, { replace: true });
      }
      return navigate("/profile", { replace: true });
    }
  }, [auth]);

  const handleRecoveryBtn = () => {
    window.ym(56168566, "reachGoal", "sign_in_to_recovery_button");
  };

  const signInGoogle = (response) => {
    dispatch(userSignInSocial("google", response.credential));
  };

  const signInApple = (response) => {
    dispatch(userSignInSocial("apple", response.authorization.id_token));
  };

  return (
    <MainLayout chat={false} title={title}>
      <section className="page__auth">
        <Form colon={false} onFinish={onFinish} autoComplete="off" className="form form_auth" form={form}>
          <Title decor={false} className="form__title" data-key="ui.login">
            {t("ui.login")}
          </Title>
          <h3 className="page__intro page__intro_center page__auth-intro" data-key="auth.subtitle">
            {t("auth.subtitle")}
          </h3>
          <div className="form__wrapper">
            <Form.Item
              name="login"
              className="form__item form__item_left"
              rules={[
                {
                  required: true,
                  message: t("auth.enter_username"),
                },
              ]}
              validateTrigger="onSubmit"
              data-key="auth.enter_username"
            >
              <Input placeholder={t("auth.username")} prefix={<UserIcon />} data-key="auth.username" />
            </Form.Item>
            <Form.Item
              name="password"
              className="form__item form__item_right"
              rules={[
                {
                  required: true,
                  message: t("auth.enter_password"),
                },
              ]}
              validateTrigger="onSubmit"
              data-key="auth.enter_password"
            >
              <Input.Password placeholder={t("auth.password")} type="password" prefix={<LockIcon />} data-key="auth.password" />
            </Form.Item>
            <Button type="submit" maxWidth={true} disabled={authFormIsSending} data-key="ui.login">
              {authFormIsSending ? <Spin indicator={loadingIcon} /> : t("ui.login")}
            </Button>

            <Link to="/register" className="btn btn_outline btn_max-width-sm form__button-link" data-key="ui.signup">
              {t("ui.signup")}
            </Link>

            <div className="form__note form__note_separator" data-key="auth.or">
              {t("auth.or")}
            </div>

            <div className="form__button-wrap">
              <div className="form__button">
                <GoogleLogin onSuccess={(codeResponse) => signInGoogle(codeResponse)} onError={(error) => console.error(error)} type="icon" shape="circle" />
              </div>
              <div className="form__button">
                <AppleSignin
                  authOptions={{
                    clientId: "com.siil.web.auth",
                    scope: "email name",
                    redirectURI: RETURN_URL,
                    state: "",
                    nonce: "nonce",
                    usePopup: true,
                  }}
                  uiType="light"
                  className="apple-auth-btn"
                  onSuccess={(response) => signInApple(response)}
                  onError={(error) => console.error(error)}
                  buttonExtraChildren=""
                />
              </div>
            </div>
          </div>
          <div className="form__note form__note_inverse">
            <p data-key="auth.note">{t("auth.note")}</p>
            <p data-key="auth.note_recovery">
              {t("auth.note_recovery")}{" "}
              <Link to="/recovery" onClick={handleRecoveryBtn} data-key="auth.note_recovery_btn">
                {t("auth.note_recovery_btn")}
              </Link>
              .
            </p>
          </div>
        </Form>
      </section>
    </MainLayout>
  );
}

export default SignIn;
