import axios from "axios";
import {
  WEBINARS_DATA,
  WEBINARS_DATA_LOADING,
  WEBINAR_IS_LOADING,
  WEBINAR_LOADED,
  WEBINAR,
} from "../constants/webinars";

import { API_URL } from "../config/config";

export function fetchWebinarsData(cache = false) {
  return (dispatch, getState) => {
    dispatch(webinarsDataAreLoading(true));

    axios
      .get(`${API_URL}user/webinars`)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(webinarsDataSuccess(response.data.webinars));
        dispatch(webinarsDataAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading webinars", e));
  };
}

function webinarsDataAreLoading(bool) {
  return {
    type: WEBINARS_DATA_LOADING,
    bool,
  };
}

function webinarsDataSuccess(data) {
  return {
    type: WEBINARS_DATA,
    data,
  };
}

export function fetchWebinar(webinar_id) {
  return (dispatch) => {
    dispatch(webinarIsLoading(true));
    dispatch(webinarLoaded(false));

    axios
      .get(`${API_URL}user/webinars/${webinar_id}`)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(webinarSuccess(response.data.webinar));
        dispatch(webinarIsLoading(false));
        dispatch(webinarLoaded(true));


        return response;
      })
      .catch((e) => {
        console.log("error loading webinar", e);
        dispatch(webinarLoaded(false));
        dispatch(webinarIsLoading(false));
      });
  };
}

function webinarIsLoading(bool) {
  return {
    type: WEBINAR_IS_LOADING,
    bool,
  };
}

function webinarLoaded(bool) {
  return {
    type: WEBINAR_LOADED,
    bool,
  };
}

function webinarSuccess(data) {
  return {
    type: WEBINAR,
    data,
  };
}
