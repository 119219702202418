import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useLocation} from "react-router-dom";
import {fetchConsultInfo} from "../../../actions/users";
import { useTranslation } from "react-i18next";
import i18next from "i18next";


function FooterConsult(props) {
    const location = useLocation();
    const dispatch = useDispatch();
    const {userConsult} = useSelector(state => state.users);
    const params = new URLSearchParams(location.search);
    const refid = params.get("refid");
    const isConsultLoaded = userConsult.id;
    const { t } = useTranslation();
    let locale = i18next.language;

    useEffect(() => {
        dispatch(fetchConsultInfo(refid, locale));
    }, [dispatch, refid, locale]);

    if(!isConsultLoaded) {
        return <div className="footer__consult"></div>;
    }

    return (
        <div className="footer__consult">
            <div className="footer__consult-label" data-key="ui.consultant">{t("ui.consultant")}{" "}{userConsult.attributes.name}</div>
            <a href={userConsult.attributes["phone-href"]} className="footer__consult-number">{userConsult.attributes.phone}</a>
        </div>
    );
}

export default FooterConsult;
