import { combineReducers } from "redux";
import {
  PROGRAMS_DEGREES,
  PROGRAMS_DEGREES_LOADING,
  PROGRAM,
  PROGRAM_LOADING,
  PROGRAM_LOADED,
  PROGRAMS_FORMS,
  PROGRAMS_FORMS_LOADING,
  PROGRAMS_CATEGORIES,
  PROGRAMS_CATEGORIES_LOADING,
  PROGRAMS,
  PROGRAMS_ARE_LOADING,
  PROGRAMS_POPULAR,
  PROGRAMS_POPULAR_LOADING,
} from "../constants/programs";

export function programsDegrees(state = [], action) {
  switch (action.type) {
    case PROGRAMS_DEGREES:
      return action.data;
    default:
      return state;
  }
}

export function programsDegreesAreLoading(state = true, action) {
  switch (action.type) {
    case PROGRAMS_DEGREES_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function programsForms(state = [], action) {
  switch (action.type) {
    case PROGRAMS_FORMS:
      return action.data;
    default:
      return state;
  }
}

export function programsFormsAreLoading(state = true, action) {
  switch (action.type) {
    case PROGRAMS_FORMS_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function programsCategories(state = [], action) {
  switch (action.type) {
    case PROGRAMS_CATEGORIES:
      return action.data;
    default:
      return state;
  }
}

export function programsCategoriesAreLoading(state = true, action) {
  switch (action.type) {
    case PROGRAMS_CATEGORIES_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function program(state = [], action) {
  switch (action.type) {
    case PROGRAM:
      return action.data;
    default:
      return state;
  }
}

export function programIsLoading(state = true, action) {
  switch (action.type) {
    case PROGRAM_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function programIsLoaded(state = true, action) {
  switch (action.type) {
    case PROGRAM_LOADED:
      return action.bool;
    default:
      return state;
  }
}

export function programs(state = [], action) {
  switch (action.type) {
    case PROGRAMS:
      return action.data;
    default:
      return state;
  }
}

export function programsAreLoading(state = true, action) {
  switch (action.type) {
    case PROGRAMS_ARE_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function programsPopular(state = [], action) {
  switch (action.type) {
    case PROGRAMS_POPULAR:
      return action.data;
    default:
      return state;
  }
}

export function programsPopularLoading(state = true, action) {
  switch (action.type) {
    case PROGRAMS_POPULAR_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export const programsReducers = () =>
  combineReducers({
    programsDegrees: programsDegrees,
    programsDegreesAreLoading: programsDegreesAreLoading,
    programsForms: programsForms,
    programsFormsAreLoading: programsFormsAreLoading,
    programsCategories: programsCategories,
    programsCategoriesAreLoading: programsCategoriesAreLoading,
    program: program,
    programIsLoading: programIsLoading,
    programIsLoaded: programIsLoaded,
    programs: programs,
    programsAreLoading: programsAreLoading,
    programsPopular: programsPopular,
    programsPopularLoading: programsPopularLoading,
  });
