import { useState } from "react";
import MainLayout from "../layout";
import { useDispatch, useSelector } from "react-redux";
import { userAccountDelete } from "../../actions/auth";
import { Link, useLocation } from "react-router-dom";
import { Title, Button } from "@vshp/ui-components";
import accountBg from "../../images/profile.jpg";
import { useTranslation } from "react-i18next";
import { CHAT_OPEN_CLASS } from "../../constants/global";
import { isLoggedIn } from "../../actions/global";

function AccountDelete() {
  const { sessionLoaded } = useSelector((state) => state.users);
  const [resultText, setResultText] = useState("");
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loggedIn = isLoggedIn();
  let location = useLocation();

  const handleDelete = () => {
    const result = window.confirm(t("ui.account_delete_confirm"));
    if (result) {
      dispatch(userAccountDelete());
      document.body.classList.add(CHAT_OPEN_CLASS);
      setResultText(t("page.account_delete.result"));
    }
  };

  return (
    <MainLayout global={false} title={{ caption: t("page.account_delete.title") }}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={accountBg} alt={t("page.account_delete.title")} />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="page.account_delete.title">
            {t("page.account_delete.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block wysiwyg">
          <div data-key="page.account_delete.content" dangerouslySetInnerHTML={{ __html: t("page.account_delete.content") }}></div>
          <div className="text-center">
            {loggedIn && sessionLoaded ? (
              <>
                <Button spaceTop={true} disabled={resultText.length > 0} onClick={handleDelete} data-key="page.account_delete.button">
                  {t("page.account_delete.button")}
                </Button>
                {resultText.length > 0 && <div className="btn_space_top" dangerouslySetInnerHTML={{ __html: resultText }}></div>}
              </>
            ) : (
              <Link to="/sign_in" className="btn btn_primary btn_space_top" state={{ from: location }} data-key="page.account_delete.button">
                {t("page.account_delete.button")}
              </Link>
            )}
          </div>
        </div>
      </div>
    </MainLayout>
  );
}

export default AccountDelete;
