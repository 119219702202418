import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {Form, Input, Spin} from "antd";

import {userRecoveryPassword} from "../../actions/auth";
import MainLayout from "../layout";
import PageNotFound from "../not-found";
import PasswordSuccess from "./success/password";
import {loadingIcon, LockIcon} from "../utils/svg";
import {checkPasswordStrength} from "../../validators/password-strength";
import { Title, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function RecoveryPassword() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {authFormIsSending, userRecoveryPasswordSuccess}= useSelector(state => state.auth);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const { t } = useTranslation();
    const title = {
        caption: t("ui.head.recovery_title"),
    }

    const onFinish = (values) => {
        dispatch(userRecoveryPassword(values, token));
    };

    if(!token) {
        return <PageNotFound/>;
    }

    if(userRecoveryPasswordSuccess) {
        return <PasswordSuccess />;
    }

    return (<MainLayout chat={false} title={title}>
        <section className="page__auth">
            <Form
                colon={false}
                onFinish={onFinish}
                autoComplete="off"
                className="form form_auth"
                form={form}
            >
                <Title decor={false} className="form__title" data-key="auth.password_enter_title">{t("auth.password_enter_title")}</Title>

                <div className="form__wrapper">
                    <Form.Item
                        name="password"
                        className="form__item"
                        rules={[{
                            required: true,
                            message: t("auth.enter_password")
                        }, {
                            validator: checkPasswordStrength
                        }
                        ]}
                        validateTrigger="onSubmit"
                        data-key-error="auth.enter_password"
                    >
                        <Input.Password placeholder={t("auth.password")} type="password" prefix={<LockIcon />} data-key="auth.password" />
                    </Form.Item>
                    <Form.Item
                        name="password_confirmation"
                        className="form__item"
                        rules={[{
                            required: true,
                            message: t("auth.enter_password_repeat")
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                value = value || "";
                                if (!value || getFieldValue("password") === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(t("auth.password_dont_match"));
                                },
                        })
                        ]}
                        validateTrigger="onSubmit"
                        data-key="auth.enter_password_repeat, auth.password_dont_match"
                    >
                        <Input.Password placeholder={t("auth.password_repeat")} type="password" prefix={<LockIcon />} data-key="auth.password_repeat"/>
                    </Form.Item>
                    <Button type="submit" maxWidth={true} disabled={authFormIsSending} data-key="ui.save">
                        {authFormIsSending ? <Spin indicator={loadingIcon} />:t("ui.save") }
                    </Button>
                </div>
                <div className="form__note form__note_inverse" data-key="auth.password_note">
                    {t("auth.password_note")}
                </div>
            </Form>
        </section>
    </MainLayout>)
}

export default RecoveryPassword;
