import { Link } from "react-router-dom";
import { Title } from "@vshp/ui-components";
import payBg from "../../images/pay.jpg";
import { useTranslation } from "react-i18next";

const PlanPaymentsInfo = ({ plan }) => {
  const planPaymentsLink = `/plans/${plan.attributes.slug}/payments`;
  const { t } = useTranslation();

  return (
    <div className="page__header page__header_short page__header_block">
      <img className="page__header-cover" src={payBg} alt="" />
      <div className="page__header-body page__header-body_center">
        <Title type="h2" decor={false} className="page__header-title page__header-title_center" data-key="plan.payment_and_schedule">
          {t("plan.payment_and_schedule")}
        </Title>
        <p className="page__intro page__intro_center" data-key="plan.payment_info">{t("plan.payment_info")}</p>
        <Link className="btn btn_outline btn_max-width-sm page__header-payments" to={planPaymentsLink} data-key="plan.balance">
          {t("plan.balance")} {plan.attributes.balance} {plan.attributes["balance-currency"]}
        </Link>
      </div>
    </div>
  );
};

export default PlanPaymentsInfo;
