import { Title, Section, Button } from "@vshp/ui-components";
import programDisciplinesBg from "../../images/disciplines.jpg";
import Slider from "../utils/Slider";
import { useTranslation } from "react-i18next";

const ProgramDisciplines = ({ disciplines, planUrl }) => {
  const { t } = useTranslation();

  return (
    <Section coverSrc={programDisciplinesBg} coverAlt={t("program.disciplines")} centered={true} noSpaceBottom={true}>
      <Title type="h2" decor={false} data-key="program.disciplines">
        {t("program.disciplines")}
      </Title>

      <Slider className="slider-disciplines">
        {disciplines.map(({ title, content }, key) => (
          <div className="slider__item" key={key}>
            <div className="slider-disciplines__item">
              <div className="slider-disciplines__name t-h3">{title}</div>
              <div className="slider-disciplines__text wysiwyg">
                <p>{content}</p>
              </div>
            </div>
          </div>
        ))}
      </Slider>

      {planUrl !== null && (
        <Button spaceTop={true} href={planUrl} target="_blank" rel="noopener noreferrer" data-key="program.disciplines.plan_download_button">
          {t("program.disciplines.plan_download_button")}
        </Button>
      )}
    </Section>
  );
};

export default ProgramDisciplines;
