import { Link, useParams } from "react-router-dom";
import MainLayout from "./layout";
import { Title } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

const PageNotFound = (props) => {
  const { t } = useTranslation();
  const { slug } = useParams();

  const errorContent = (
    <div className="not-found">
      <div className="not-found__inner">
        <div className="not-found__error">{props.error}</div>
        <Title decor={false} className="not-found__title" data-key={props.reason}>
          {t(props.reason)}
        </Title>
        <div className="not-found__text">{t(props.details)}</div>

        <div className="not-found__btn">
          {props.goBackPayments ? (
            <Link to={slug && `/plans/${slug}/payments`} className="btn btn_outline btn_max-width-sm" data-key="ui.back_to_payments">
              {t("ui.back_to_payments")}
            </Link>
          ) : (
            <Link className="btn btn_outline btn_max-width-sm" to="/profile" data-key="ui.back_to_main">
              {t("ui.back_to_main")}
            </Link>
          )}
        </div>
      </div>
    </div>
  );

  return props.full ? (
    <MainLayout chat={false} title={{ caption: t("ui.page_not_found") }} global={false}>
      {errorContent}
    </MainLayout>
  ) : (
    errorContent
  );
};

PageNotFound.defaultProps = {
  full: true,
  error: 404,
  reason: "error.reason",
  details: "error.details",
  goBackPayments: false,
};

export default PageNotFound;
