import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { fetchProgram } from "../../actions/programs";
import { Loading } from "../utils/loading";
import MainLayout from "../layout";
import PageNotFound from "../not-found";
import { Title, ListOrdered, Section, LinkDecor } from "@vshp/ui-components";
import i18next from "i18next";
import Teachers from "../shared/teachers";
import Partners from "../shared/partners";
import Career from "../shared/career";
import Videos from "../shared/Videos";
import Installment from "../shared/installment";
import ProgramInfo from "./program-info";
import ProgramsPopular from "./programs-popular";
import Consultation from "../shared/consultation";
import Offer from "../shared/offer";
import ProgramDisciplines from "./ProgramDisciplines";
import ProgramCertificates from "./ProgramCertificates";
import { setUserCourseSlug } from "../../actions/users";

function ProgramPage() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const { program, programIsLoading, programIsLoaded } = useSelector((state) => state.programs);
  const { t } = useTranslation();
  let content = null;
  let title = null;
  let locale = i18next.language;

  useEffect(() => {
    dispatch(setUserCourseSlug(""));
    dispatch(fetchProgram(id, locale));
  }, [dispatch, id, locale]);

  if (programIsLoading && programIsLoaded) {
    content = <Loading contentPreloader />;
    title = {
      caption: t("ui.loading"),
    };
    return <MainLayout title={title}>{content}</MainLayout>;
  }

  if (!programIsLoaded) {
    return <PageNotFound />;
  }

  const certificates = program.attributes.certificates;

  title = {
    caption: `${t("program.page_title")} ${program.attributes.title}`,
  };

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={program.attributes.photo.cover} alt={program.attributes.title} />
        <div className="page__header-body page__header-body_center page__header-body_theme">
          <Title decor={false} className="page__header-title">
            {program.attributes.title}
          </Title>
          {program.attributes.tagline && program.attributes.tagline.length > 0 && <div className="page__intro" dangerouslySetInnerHTML={{ __html: program.attributes.tagline }}></div>}

          <Link
            className="btn btn_primary"
            to={`${program.attributes["course-exists"] ? `/plans/${program.attributes.slug}?refcid=${program.attributes["course-id"]}` : `/profile?refp=${program.attributes.title.replace(/ /g, "+")}`}`}
            data-key="program.join"
          >
            {t("program.join")}
          </Link>
        </div>
      </div>

      <div className="page__blocks">

      {program.attributes.description && program.attributes.description.length > 0 && (
        <Section border={true} className="wysiwyg">
          <Title type="h2" data-key="program.description">{t("program.description")}</Title>
          <div dangerouslySetInnerHTML={{ __html: program.attributes.description }}></div>
        </Section>
      )}

      {program.attributes.presentation && (
          <Section border={true}>
            <Title type="h2" data-key="program.presentation">
              {t("program.presentation")}
            </Title>
            <LinkDecor link={program.attributes.presentation.url}>{program.attributes.presentation.title}</LinkDecor>
          </Section>
        )}

        {program.attributes.documents && program.attributes.documents.length > 0 && (
          <Section border={true}>
            <Title type="h2" data-key="program.documents">
              {t("program.documents")}
            </Title>
            {program.attributes.documents.map(({ title, url }, id) => (
              <LinkDecor link={url} key={id}>
                {title}
              </LinkDecor>
            ))}
          </Section>
        )}

      {program.attributes.videos.data.length > 0 && <Videos videos={program.attributes.videos.data} />}

      {program.attributes.advantages.length > 0 && (
        <Section>
          <Title type="h2" data-key="program.advantages">{t("program.advantages")}</Title>
          <ListOrdered isTwoColumns={true}>
            {program.attributes.advantages.map((text, key) => (
              <ListOrdered.Item key={key}>{text}</ListOrdered.Item>
            ))}
          </ListOrdered>
        </Section>
      )}

      {program.attributes.disciplines.length > 0 && <ProgramDisciplines disciplines={program.attributes.disciplines} planUrl={program.attributes["plan-url"]} />}

      <Consultation />

      {certificates.length > 0 && <ProgramCertificates title={program.attributes.title} certificates={certificates} degree={program.attributes["degree-id"]} border={!program.attributes.teachers.data.length > 0}/>}

      {program.attributes.teachers.data.length > 0 && <Teachers teachers={program.attributes.teachers.data} />}

      <Section border={program.attributes.career.salary !== null}>
        <Partners />
      </Section>

      {program.attributes.career.salary !== null && <Career locale={locale} salary={program.attributes.career.salary} vacancies={program.attributes.career.vacancies} />}

      {locale === "ru" && <Installment />}

      <ProgramInfo
        cost={program.attributes["details-cost"]}
        degree={program.attributes["details-degree"]}
        form={program.attributes["details-form"]}
        organization={program.attributes["details-organization"]}
        term={program.attributes["details-term"]}
      />

      <Offer />

      <ProgramsPopular />
      </div>
    </MainLayout>
  );
}

export default ProgramPage;
