import { LANGUAGES } from "../../../constants/global";

function ProfileData(props) {
  const { user } = props;

  return (
    <div className="profile-info__data">
      <div className="profile-info__data-value profile-info__data-value_title">{user.name}</div>
      <div className="profile-info__data-value">
        {user.phone && `${user.phone}, `} {user.email} {user["activation-state"] !== "active" && <span className="profile-info__data-notice">!</span>}
      </div>
      <div className="profile-info__data-value profile-info__data-value_subtitle">{LANGUAGES[user.locale]}</div>
    </div>
  );
}

export default ProfileData;
