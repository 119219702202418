import { useState } from "react";
import PlanListSection from "./plan-list-section";
import PlanComplete from "./plan-complete";
import PlanSettings from "./plan-settings";
import PlanDocuments from "./plan-documents";
import { getGetParam } from "../../actions/global";

function PlanList(props) {
  const { planSlug, curriculum, completed, semesterNames, materials } = props;
  const storedCompletedFlag = getGetParam("hideCompleted") === "true";
  const [hideCompleted, toggleCompleted] = useState(
    completed || storedCompletedFlag
  );

  return (
    <div className="page__block">
      {completed && hideCompleted ? <PlanComplete /> : null}

      {curriculum.map((item, key) =>
        item.completed && hideCompleted ? null : (
          <PlanListSection
            key={key}
            planSlug={planSlug}
            semesterNumber={key + 1}
            semesterName={semesterNames[key]}
            semester={item.data}
            hideCompleted={hideCompleted}
          />
        )
      )}

      {materials.length > 0 && <PlanDocuments materials={materials} />}

      <PlanSettings onChange={toggleCompleted} completed={hideCompleted} />
    </div>
  );
}

export default PlanList;
