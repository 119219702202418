import i18next from "i18next";

export function checkPasswordStrength(rule, value) {
    value = value || "";

    if(!value) {
        return Promise.resolve();
    }

    const isDigit = value !== value.replace(/[0-9]/g, "");
    const isUpperLetter = value !== value.replace(/[A-Z]/g, "");
    const isLowerLetter = value !== value.replace(/[a-z]/g, "");

    if (value.length < 8 || !isDigit || !isUpperLetter || !isLowerLetter) {
        return Promise.reject(i18next.t("auth.enter_password_not_enough"));
    } else {
        return Promise.resolve();
    }
}
