import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import usePrevious from "../../hooks/previous";
import { Link, useParams } from "react-router-dom";
import { Upload, Spin } from "antd";

import { fetchPlanSemesterThemes, fetchPlanSemesterUnit, uploadWork, completePlanUnit, completePlanUnitDone } from "../../actions/plan";
import MainLayout from "../layout/index";
import PlanThemeContent from "./plan-theme-content";
import PlanThemeNavigation from "./plan-theme-navigation";
import PageNotFound from "../not-found";
import { Loading } from "../utils/loading";
import { loadingIcon } from "../utils/svg";
import { Title, Button, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

const PlanTheme = () => {
  const { slug, semesterNumber, themeSlug, themeNumber } = useParams();
  let locale = i18next.language;
  const dispatch = useDispatch();
  const {
    plan,
    planSemesterThemes,
    planSemesterThemesAreLoading,
    planSemesterThemesLoaded,
    planSemesterUnit,
    planSemesterUnitIsLoading,
    planSemesterUnitLoaded,
    homeWorkIsUploading,
    completePlanUnitSending,
    completePlanUnitDoneState,
  } = useSelector((state) => state.plan);
  const [upload, setUpload] = useState(false);
  const [files, setFilesData] = useState([]);
  const prevUpload = usePrevious(upload);
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(fetchPlanSemesterUnit(slug, semesterNumber, themeSlug, locale));
    dispatch(fetchPlanSemesterThemes(slug, semesterNumber, themeSlug, locale));
  }, [dispatch, slug, semesterNumber, themeSlug, locale]);

  useEffect(() => {
    if (upload && upload !== prevUpload) {
      dispatch(uploadWork(slug, semesterNumber, themeSlug, files));
    }
  }, [dispatch, slug, semesterNumber, themeSlug, files, upload, prevUpload]);

  useEffect(() => {
    dispatch(completePlanUnitDone(false));
  }, [dispatch]);

  if (planSemesterThemesAreLoading || planSemesterUnitIsLoading) {
    return (
      <MainLayout>
        <Loading contentPreloader />
      </MainLayout>
    );
  }

  if (!planSemesterUnitLoaded || !planSemesterThemesLoaded) {
    return <PageNotFound full={false} />;
  }

  const themesLength = planSemesterThemes.length;
  const themeNumberFormatted = themeNumber ? parseInt(themeNumber) : 1;

  if (themeNumberFormatted < 1 || themeNumberFormatted > themesLength) {
    return <PageNotFound full={false} />;
  }

  const handleComplete = () => {
    dispatch(completePlanUnit(slug, semesterNumber, themeSlug, locale, planSemesterUnit.attributes.position - 1, t("plan.complete")));
  };

  const tagline = planSemesterThemes[themeNumberFormatted - 1].attributes.tagline;
  const themeTitle = planSemesterThemes[themeNumberFormatted - 1].attributes.title;
  const title = {
    caption: `${plan.attributes.course.title}. ${themeTitle}`,
    intro: tagline,
  };

  const uploadProps = {
    name: "file",
    multiple: true,
    beforeUpload: (file, filesList) => {
      setFilesData(filesList);
      setUpload(true);
      return false;
    },
  };

  const isComplete = planSemesterUnit.attributes.state === "completed";
  let testButtonText = t("plan.to_test");
  let homeworkButtonText = t("plan.upload_work");
  let homeworkButtonStatus = false;
  const active = planSemesterUnit.attributes.free || planSemesterUnit.attributes.paid;

  switch (planSemesterUnit.attributes.state) {
    case "moderation":
      homeworkButtonText = t("plan.work_on_checking");
      homeworkButtonStatus = true;
      break;
    case "completed":
      testButtonText = planSemesterUnit.attributes.grade;
      homeworkButtonText = planSemesterUnit.attributes.grade;
      homeworkButtonStatus = true;
      break;
    default:
      break;
  }

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={plan.attributes.photo.cover} alt="" />
        <div className="page__header-body page__header-body_theme page__header-body_center">
          <Title decor={false} className="page__header-title">
            {themeTitle}
          </Title>
          {themeTitle ? <h2 className="page__header-subtitle">{plan.attributes.course.title}</h2> : null}
          {tagline ? <div>{tagline}</div> : null}
          {/*  unit button  */}
          <div className="page__header-buttons">
            {active && planSemesterUnit.attributes.control === "test" ? (
              <Link className={`btn btn_primary btn_max-width-sm${isComplete ? " disabled" : ""}`} to={`/plans/${slug}/${semesterNumber}/${planSemesterUnit.attributes.slug}/test/1`}>
                {testButtonText}
              </Link>
            ) : null}
            {active && planSemesterUnit.attributes.control === "homework" ? (
              <div className={`upload-container${homeworkButtonStatus ? " upload-container_disabled" : ""}`}>
                <Upload {...uploadProps} disabled={homeworkButtonStatus} className="btn btn_primary btn_max-width-sm ant-btn-upload">
                  {homeWorkIsUploading ? <Spin indicator={loadingIcon} /> : homeworkButtonText}
                </Upload>
              </div>
            ) : null}
            {active && !isComplete && planSemesterUnit.attributes.control === "no" && (
              <Button onClick={handleComplete} disabled={completePlanUnitDoneState} data-key="plan.to_complete">
                {completePlanUnitSending ? <Spin indicator={loadingIcon} /> : t("plan.to_complete")}
              </Button>
            )}
            {!active ? (
              <Link className="btn btn_primary btn_max-width-sm" to={`/plans/${slug}/payments`} data-key="plan.not_paid">
                {t("plan.not_paid")}
              </Link>
            ) : null}
            <Link to={`/plans/${slug}`} className="btn btn_outline btn_max-width-sm" data-key="plan.title">
              {t("plan.title")}
            </Link>
          </div>
        </div>
      </div>

      <div className="page__blocks">
      <Section className="wysiwyg">
        {themesLength > 1 ? (
          <PlanThemeNavigation title={t("themes.studied")} themes={planSemesterThemes} slug={slug} semesterNumber={semesterNumber} themeSlug={themeSlug} themeNumber={themeNumber} />
        ) : null}

        <PlanThemeContent content={planSemesterThemes[themeNumberFormatted - 1]} />

        {themesLength > 1 ? (
          <PlanThemeNavigation title={t("themes.to_next")} themes={planSemesterThemes} slug={slug} semesterNumber={semesterNumber} themeSlug={themeSlug} themeNumber={themeNumber} />
        ) : null}
      </Section>
      <Section centered={true}>
        <Link className="btn btn_outline btn_max-width-sm page__back-link" to={`/plans/${slug}`} data-key="plan.title">
          {t("plan.title")}
        </Link>
      </Section>

      </div>
    </MainLayout>
  );
};

export default PlanTheme;
