import {useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Upload, Spin} from "antd";
import {submitDocument, deleteDocument, viewDocument} from "../../actions/users";
import {ArrowFullIcon, DeleteIcon, ShowIcon, loadingIcon} from "../utils/svg";
import { Button } from "@vshp/ui-components";

function ProfileDocuments(props) {
    const {documentIsUploading} = useSelector(state => state.users);
    const dispatch = useDispatch();
    const {item, document, icon} = props;
    const disabled = !document;

    const handleUpload = (info) => {
        dispatch(submitDocument(item.id, item.type, info.file));
    };

    const handleViewDocument = useCallback(() => dispatch(viewDocument(document.id)), [document, dispatch]);

    const handleDeleteDocument = useCallback(() => dispatch(deleteDocument(document.id)), [document, dispatch]);

    const uploadProps = {
        name: "file",
        multiple: false,
        showUploadList: false,
        onChange: handleUpload,
        beforeUpload: file => {
            return false;
        }
    };

    return (<div className="documents__item document">
        <div className={`document__icon${disabled ? " document__icon_disabled":""}`}>{icon}</div>
        <div className="document__content">
            <h3 className="document__title">{item.name}</h3>

            <div className="document__actions">
                <Upload {...uploadProps}>
                    <Button size="small" icon={true} disabled={documentIsUploading}>
                        {documentIsUploading ? <Spin indicator={loadingIcon} />:<ArrowFullIcon />}
                    </Button>
                </Upload>
                <Button size="small" icon={true} disabled={disabled} onClick={handleViewDocument}><ShowIcon /></Button>
                <Button size="small" icon={true} disabled={disabled} onClick={handleDeleteDocument}><DeleteIcon /></Button>
            </div>
        </div>
    </div>
    );
}

export default ProfileDocuments;
