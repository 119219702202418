import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import i18next from "i18next";
import { fetchSettings } from "../../actions/settings";
import { LANGUAGES_OPEN_CLASS, AVAILABLE_LANGUAGES } from "../../constants/global";

function HeaderLanguages() {
  const dispatch = useDispatch();
  const [isLanguagesToggled, toggleLanguages] = useState(false);
  const [currentLanguageTitle, setCurrentLanguageTitle] = useState("");
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);
  let locale = i18next.language;

  const changeLanguage = (lng) => {
    i18next.changeLanguage(lng);
  };

  useEffect(() => {
    isLanguagesToggled ? document.body.classList.add(LANGUAGES_OPEN_CLASS) : document.body.classList.remove(LANGUAGES_OPEN_CLASS);
  }, [isLanguagesToggled]);

  useEffect(() => {
    dispatch(fetchSettings(locale));
  }, [dispatch, locale]);

  useEffect(() => {
    !settingsAreLoading ? settings.locales.filter(([lng, title]) => lng === locale && setCurrentLanguageTitle(title)) : setCurrentLanguageTitle(locale);
  }, [settingsAreLoading, settings.locales, locale]);

  const toToggleLanguages = () => toggleLanguages(!isLanguagesToggled);

  const handleChangeLanguageBtn = (item) => () => {
    changeLanguage(item);
    toToggleLanguages();
  };

  return (
    <>
      <div className="header__languages-backdrop" onClick={toToggleLanguages}></div>
      <div className="header__languages">
        <div className="header__languages-item header__languages-item_value" onClick={toToggleLanguages}>
          {currentLanguageTitle}
        </div>
        <div className="header__languages-wrapper">
          <div className="header__languages-item header__languages-item_active">{currentLanguageTitle}</div>
          {!settingsAreLoading
            ? settings.locales.map(([lng, title], id) => (
                <div className={`header__languages-item${title === currentLanguageTitle ? " header__languages-item_hide" : ""}`} key={id} onClick={handleChangeLanguageBtn(lng)}>
                  {title}
                </div>
              ))
            : AVAILABLE_LANGUAGES.map((lng, id) => (
                <div className={`header__languages-item${lng === currentLanguageTitle ? " header__languages-item_hide" : ""}`} key={id} onClick={handleChangeLanguageBtn(lng)}>
                  {lng}
                </div>
              ))}
        </div>
      </div>
    </>
  );
}

export default HeaderLanguages;
