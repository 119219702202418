import {Link} from "react-router-dom";

function PlanThemeNavigation(props) {
    const {themes, slug, semesterNumber, themeSlug, themeNumber, title} = props;

    const themeNumberFormatted  = themeNumber ? parseInt(themeNumber):1;

    return (
        <div className="wysiwyg">
            <div className="wysiwyg__block wysiwyg__block_themes">
                <div className="wysiwyg__title t-h3">{title}</div>
                <ul>
                    {themes.map((item, key) => {
                        return (
                            <li key={key} className={themeNumberFormatted - 1 === key ? 'active':''}>
                                <Link to={`/plans/${slug}/${semesterNumber}/${themeSlug}/${key + 1}`}>{item.attributes.title}</Link>
                            </li>
                        )
                    })}
                </ul>
            </div>
        </div>
    )
}

export default PlanThemeNavigation;