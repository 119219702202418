import axios from "axios";
import { ARCHIVE, ARCHIVE_LOADING } from "../constants/archive";
import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchArchive(locale) {
  return (dispatch) => {
    dispatch(archiveAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}user/timetable/archive`, config)
      .then((response) => {
        dispatch(archiveSuccess(response.data.data));
        dispatch(archiveAreLoading(false));
        return response;
      })
      .catch((e) => console.log("error loading archive", e));
  };
}

function archiveAreLoading(bool) {
  return {
    type: ARCHIVE_LOADING,
    bool,
  };
}

function archiveSuccess(data) {
  return {
    type: ARCHIVE,
    data,
  };
}
