import { useTranslation } from "react-i18next";

const PlanTestExpired = () => {
  const { t } = useTranslation();

  return (
    <div className="test__content test__content_center">
      <div className="test__note">
        <h3 className="test__title" data-key="test.expired_title">
          {t("test.expired_title")}
        </h3>
      </div>
    </div>
  );
};

export default PlanTestExpired;
