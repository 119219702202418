import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { formatPhone } from "../../utils/formatPhone";

function FooterContacts(props) {
  const { settings, settingsAreLoading } = useSelector((state) => state.settings);
  const { t } = useTranslation();

  let phoneNumber = settings.contacts_phone;
  let formatPhoneNumber = formatPhone(phoneNumber);

  return (
    <div className="footer__consult">
      <div className="footer__consult-label" data-key="contacts.phone">{t("contacts.phone")}</div>
      {!settingsAreLoading && phoneNumber != null && (
        <a href={`tel:${formatPhoneNumber.replace(/[^+\d]+/g, "")}`} className="footer__consult-number">
          {phoneNumber}
        </a>
      )}
    </div>
  );
}

export default FooterContacts;
