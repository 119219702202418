import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Spin } from "antd";
import { fetchPlanTest, completePlanTest, sendingAnswerSuccess } from "../../actions/plan";
import TestQuestion from "./question";
import PlanTestCompleted from "./test/success";
import PlanTestFailed from "./test/fail";
import PlanTestExpired from "./test/PlanTestExpired";
import PageNotFound from "../not-found";
import MainLayout from "../layout";
import { Loading } from "../utils/loading";
import { loadingIcon } from "../utils/svg";
import { Title, Button, Section } from "@vshp/ui-components";
import CountdownTimer from "../utils/CountdownTimer";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function PlanTest() {
  const { slug, semesterNumber, themeSlug, testQuestion } = useParams();
  const [stateCountQuestions, setCountQuestions] = useState(0);
  const [isCurrentQuestionLoaded, setCurrentQuestionLoaded] = useState(false);
  const [isExpired, setExpired] = useState(false);
  const { plan, planTest, planTestIsLoading, planTestComplete, planTestCompleteResponse, planTestResultsSending, planTestQuestionsWithoutAnswer } = useSelector((state) => state.plan);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { t } = useTranslation();
  let locale = i18next.language;
  let currentQuestion = testQuestion ? parseInt(testQuestion) : 1;

  useEffect(() => {
    dispatch(fetchPlanTest(slug, semesterNumber, themeSlug, locale));
  }, [dispatch, slug, semesterNumber, themeSlug, locale]);

  useEffect(() => {
    if (!planTestIsLoading) {
      setCountQuestions(testLength - planTestQuestionsWithoutAnswer.length);
    }
  }, [planTestIsLoading, planTestQuestionsWithoutAnswer]);

  useEffect(() => {
    if (!planTestIsLoading) {
      setCountQuestions(testLength - planTest.meta["without-answer"].length);
    }
  }, [planTestIsLoading]);

  useEffect(() => {
    if (!planTestIsLoading) {
      let questionWithoutAnswer = 0;

      planTest.data.some(({ attributes: { "answer-ids": id } }, index) => {
        id.length === 0 && (questionWithoutAnswer = index);
        return id.length === 0;
      });

      currentQuestion !== questionWithoutAnswer + 1 &&
        navigate({
          pathname: `/plans/${slug}/${semesterNumber}/${themeSlug}/test/${questionWithoutAnswer + 1}`,
        });

      setCurrentQuestionLoaded(true);
    }
  }, [planTestIsLoading]);

  const completeTest = () => {
    dispatch(completePlanTest(slug, semesterNumber, themeSlug, locale));
  };

  const handleContinueStudyBtn = () => {
    dispatch(sendingAnswerSuccess(false));
  };

  const reloadTest = () => {
    setExpired(false);
    dispatch(fetchPlanTest(slug, semesterNumber, themeSlug, locale));
    dispatch(sendingAnswerSuccess(false));

    navigate({
      pathname: `/plans/${slug}/${semesterNumber}/${themeSlug}/test/1`,
    });
  };

  if (planTestIsLoading) {
    return <Loading contentPreloader />;
  }

  if (planTest.error) {
    return <PageNotFound full={true} />;
  }

  const testTitle = `${plan.attributes.course.title}. ${planTest.meta.unit.title}`;
  let title = {
    caption: `${testTitle} ${t("test.title")}.`,
  };
  const testQuestions = planTest.data;
  const testLength = testQuestions.length;
  const diffDate = new Date().getTime() - new Date(planTest.meta["current-time"]).getTime();

  if (currentQuestion < 1 || currentQuestion > testLength) {
    return <PageNotFound full={true} />;
  }

  let response = null;
  let reloadButton = false;
  let btnStatePassed = false;

  if (!isEmpty(planTestCompleteResponse) || isExpired) {
    if (planTestCompleteResponse.passed) {
      response = (
        <PlanTestCompleted
          slug={slug}
          semesterNumber={semesterNumber}
          info={planTestCompleteResponse}
          grade={planTestCompleteResponse.grade}
          answers={planTestCompleteResponse.answered_questions}
          testLength={testLength}
        />
      );
      reloadButton = true;
      btnStatePassed = true;
      title = {
        caption: `${testTitle} ${t("test.passed")}.`,
      };
    } else if (isExpired) {
      response = <PlanTestExpired />;
    } else {
      response = <PlanTestFailed slug={slug} semesterNumber={semesterNumber} themeSlug={themeSlug} plan={plan} info={planTestCompleteResponse} testLength={testLength} />;

      title = {
        caption: `${testTitle} ${t("test.failed")}.`,
      };
    }
  }

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={plan.attributes.photo.cover} alt="" />
        <div className="page__header-body page__header-body_center page__header-body_theme">
          <Title decor={false} className="page__header-title">
            {planTest.meta.unit.title}
          </Title>
          <h2 className="page__header-subtitle t-h2">{plan.attributes.course.title}</h2>
        </div>
      </div>
      <CountdownTimer targetDate={planTest.meta["expiration-time"]} diffDate={diffDate} isExpired={isExpired} setExpired={setExpired} />
      <div className="page__blocks">
        <Section className="test">
          {response ? (
            <div className="test__wrap">
              {response}
              <div className="test__nav">
                <Link className="btn btn_primary" onClick={handleContinueStudyBtn} to={`/plans/${slug}`} state={{ passed: btnStatePassed }} data-key="test.continue_studying">
                  {t("test.continue_studying")}
                </Link>
                <Button onClick={reloadTest} disabled={reloadButton} data-key="test.go_again">
                  {t("test.go_again")}
                </Button>
              </div>
            </div>
          ) : (
            isCurrentQuestionLoaded && (
              <div className="test__wrap">
                <TestQuestion
                  q={testQuestions[currentQuestion - 1]}
                  testLength={testLength}
                  questionNumber={currentQuestion}
                  slug={slug}
                  semesterNumber={semesterNumber}
                  themeSlug={themeSlug}
                  testQuestion={currentQuestion}
                />
                <Button className="test__final" onClick={completeTest} disabled={!planTestComplete || planTestResultsSending} data-key="test.complete">
                  {planTestResultsSending ? <Spin indicator={loadingIcon} /> : t("test.complete")}
                </Button>
                <div className="test__note test__note_space_top text-center">
                  <span data-key="test.process.passed.count" dangerouslySetInnerHTML={{ __html: t("test.process.passed.count", { count: stateCountQuestions }) }}></span> <strong>{testLength}</strong>.
                </div>
              </div>
            )
          )}
        </Section>
      </div>
    </MainLayout>
  );
}

export default PlanTest;
