import {useDispatch, useSelector} from "react-redux";
import {sendActivationLink} from "../../actions/auth";
import { useTranslation } from "react-i18next";


function ProfileNotice(props) {
    const {user} = props;
    const dispatch = useDispatch();
    const {activationLinkIsSending, activationLinkSuccess}= useSelector(state => state.auth);
    const { t } = useTranslation();

    const handleLink = () => {
        dispatch(sendActivationLink(user));
    }

    return (
        <div className="profile-notice">
            {!activationLinkSuccess ? (
                <div>
                    <p className="profile-notice__title" data-key="auth.email_confirm_title">{t("auth.email_confirm_title")}</p>
                    <p className="profile-notice__text" data-key="auth.email_confirm_text_01, auth.email_confirm_text_02">
                        {t("auth.email_confirm_text_01")}
                        <br/>
                        {t("auth.email_confirm_text_02")}{" "}{user['email']}?
                    </p>
                    <span onClick={handleLink} className={`profile-notice__link${activationLinkIsSending ? " profile-notice__link_sending":""}`} data-key="auth.email_confirm_send_new">{t("auth.email_confirm_send_new")}</span>
                </div>
            ):(
                <div>
                    <p className="profile-notice__text" data-key="auth.email_confirm_sent, auth.email_confirm_check">
                    {t("auth.email_confirm_sent")}
                        <br/>
                        {t("auth.email_confirm_check")}
                    </p>
                </div>
            )}
        </div>
    );
}

export default ProfileNotice;
