import i18next from "i18next";

function createTextLinks(text) {
  const urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

  let linkedText = (text || "").replace(urlRegex, function (match, space, url) {
    let hyperlink = space;
    let restString = "";

    if (hyperlink.includes("</")) {
      restString = /<(.+)/.exec(hyperlink)[0];
      hyperlink = hyperlink.split("</")[0];
    }

    if (!hyperlink.match("^https?://")) {
      hyperlink = `http://${hyperlink}`;
    }

    return `<a href="${hyperlink}" class="chat__link" target="_blank" rel="noopener noreferrer"><span>${hyperlink}</span> <b>${i18next.t("chat.follow_the_link")} ></b></a>${restString}`;
  });

  return linkedText;
}

export const Linker = (props) => {
  return <div dangerouslySetInnerHTML={{ __html: createTextLinks(props.text) }}></div>;
};
