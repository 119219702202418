export const MENU_OPEN_CLASS = "nav-open";
export const CHAT_OPEN_CLASS = "chat-open";
export const CHAT_MENU_OPEN_CLASS = "chat-pre-open";
export const CHAT_STICKERS_PREFIX = "[sticker:";

export const THEME_DARK_CLASS = "theme_dark";

export const LANGUAGES_OPEN_CLASS = "languages-open";

export const LANGUAGES = {
  ru: "Русский язык",
  en: "English",
};

export const AVAILABLE_LANGUAGES = ["en", "ru", "fr"];

export const RESPONSE_ERRORS = "RESPONSE_ERRORS";
export const RESPONSE_SUCCESS = "RESPONSE_SUCCESS";
export const RESPONSE_ERROR_SERVER = "RESPONSE_ERROR_SERVER";

export const SOURCE = "siil";

export const DATE_FORMAT = "DD.MM.YYYY";
export const DATE_AND_TIME_FORMAT = "DD.MM.YYYY | HH:mm";
export const TIME_FORMAT = "HH:mm";

export const FILE_MAX_SIZE = "31457280";

/* Redux general constants */
export const COUNTRIES_ARE_LOADING = "COUNTRIES_ARE_LOADING";
export const COUNTRIES_LIST = "COUNTRIES_LIST";
export const COUNTRIES_CODES_LIST = "COUNTRIES_CODES_LIST";
export const IP_ADDRESS = "IP_ADDRESS";
export const COUNTRY_CODE = "COUNTRY_CODE";
export const COURSE_DEGREE_ID = 9;

/* Message */
export const MESSAGE_DEFAULT_DURATION = 5;
export const MESSAGE_MOBILE_TOP_OFFSET = 20;
export const MESSAGE_TABLET_TOP_OFFSET = 30;
export const MESSAGE_DESKTOP_TOP_OFFSET = 120;

/* phone field setting*/
export const RUSSIAN_COUNTRY_CODE = "ru";
export const RUSSIAN_PHONE_CODE = "+7";
export const RUSSIAN_PHONE_MASK = "+7 (###) ###-##-##";
export const MASK_LETTER = "_";
export const RUSSIAN_PHONE_REGEX = /^(\+7|7|8)?[\s\-]?\(?[489][0-9]{2}\)?[\s\-]?[0-9]{3}[\s\-]?[0-9]{2}[\s\-]?[0-9]{2}$/gm;
export const PHONE_REGEX = /^[0-9]{5,14}$/gm;

/* select presets */
export const SELECT_DROPDOWN_ALIGN = {
  offset: [0, 0],
  targetOffset: [0, 0],
  points: ["tl", "bl"],
  overflow: {
    adjustX: true,
    adjustY: false,
  },
};

/* FIELDS NAMES */
export const FIELDS_NAMES = { email: "Email", phone: "Phone" };

// Slider

export const MAX_INDICATORS_STD = 2;
export const MAX_INDICATORS_COUNT = 5;

export const NAVIGATION = [
  {
    name: "ui.menu_item_about",
    submenu: [
      {
        name: "ui.menu_item_institute",
        url: "/institute",
        "is-href": true,
      },
      {
        name: "ui.menu_item_reviews",
        url: "/reviews",
        "is-href": true,
      },

      // {
      //   name: "ui.menu_item_teaching_staff",
      //   url: "/teaching-staff",
      //   "is-href": true,
      //   submenu: [
      //     {
      //       name: "ui.menu_item_program_directors",
      //       url: "/teaching-staff/program-directors",
      //       "is-href": true,
      //     },
      //   ],
      // },

      {
        name: "ui.menu_item_community",
        url: "/community",
        "is-href": true,
      },
      {
        name: "ui.menu_item_blog",
        url: "/blog",
        "is-href": true,
      },
      {
        name: "ui.menu_item_contacts",
        url: "/contacts",
        "is-href": true,
        submenu: [
          {
            name: "ui.menu_item_impressum",
            url: "/impressum",
            "is-href": true,
          },
        ],
      },
    ],
  },
  // programs
  {
    name: "ui.menu_item_programs",
    url: "/programs",
    "is-href": false,
    submenu: [
      {
        name: "ui.menu_item_it_faculty",
        url: "/it",
        "is-href": true,
      },
      {
        name: "ui.menu_item_management_faculty",
        url: "/management-faculty",
        "is-href": true,
      },
      {
        name: "ui.menu_item_teaching_staff",
        url: "/teaching-staff",
        "is-href": true,
      },
      {
        name: "ui.menu_item_program_directors",
        url: "/program-directors",
        "is-href": true,
      },
    ],
  },

  // student-services

  {
    name: "ui.menu_item_addmissions",
    url: "/study-siil",
    "is-href": true,
    submenu: [
      {
        name: "ui.menu_item_study_siil",
        url: "/study-siil",
        "is-href": true,
      },
      {
        name: "ui.menu_item_student_services",
        url: "/student-services",
        "is-href": true,

        submenu: [
          {
            name: "ui.menu_item_academic",
            url: "/student-services/academic-assistance-team",
            "is-href": true,
          },
          {
            name: "ui.menu_item_career_advice",
            url: "/student-services/career-advice-team",
            "is-href": true,
          },
          {
            name: "ui.menu_item_learning_support",
            url: "/student-services/learning-support-team",
            "is-href": true,
          },
          {
            name: "ui.menu_item_addmission",
            url: "/student-services/admissions-and-records-team",
            "is-href": true,
          },
        ],
      },

      {
        name: "ui.menu_item_e_learning_platform",
        url: "/e-learning-platform",
        "is-href": true,
      },

      {
        name: "ui.menu_item_alumni_network",
        url: "/alumni",
        "is-href": true,
      },
      {
        name: "ui.menu_item_diploma",
        url: "/documents",
        "is-href": true,
      },

      {
        name: "ui.menu_item_reviews",
        url: "/reviews",
        "is-href": true,
      },
      {
        name: "ui.menu_item_faq",
        url: "/faq",
        "is-href": true,
      },
    ],
  },

  // quality-assurance-office

  {
    name: "ui.menu_item_quality_assurance_office",
    url: "/quality-assurance-office",
    "is-href": true,

    submenu: [
      // {
      //   name: "ui.menu_item_it_security",
      //   url: "/it-security",
      //   "is-href": true,
      // },
    ],
  },

  // documents_and_downloads

  {
    name: "ui.menu_item_documents_and_downloads",

    submenu: [
      {
        name: "ui.menu_item_strategic_plans",
        url: "/strategic-plans",
        "is-href": true,
      },
      {
        name: "ui.menu_item_annual_reports",
        url: "/annual-reports",
        "is-href": true,
      },
      {
        name: "ui.menu_item_statutory_documents",
        url: "/statutory-documents",
        "is-href": true,
      },

      {
        name: "ui.menu_item_general_information",
        url: "/general-information",
        "is-href": true,
      },

      {
        name: "ui.menu_item_awards_and_accreditations",
        url: "/awards-and-accreditations",
        "is-href": true,
      },
      {
        name: "ui.menu_item_presskit",
        url: "/presskit",
        "is-href": true,
      },
      {
        name: "ui.menu_item_policies",
        url: "/policies",
        "is-href": true,
      },
      {
        name: "ui.menu_item_study_plans",
        url: "/study-plans",
        "is-href": true,
      },
      {
        name: "ui.menu_item_important_information_for_students",
        url: "/important-information-for-students",
        "is-href": true,
      },
      // {
      //   name: "ui.menu_item_agreement",
      //   url: "/agreement",
      //   "is-href": true,
      // },
    ],
  },
];

export const NAVIGATION_FOOTER = [
  // {
  //   name: "ui.menu_item_about",
  //   url: "/about-us",
  //   "is-href": true,
  // },
  {
    name: "ui.menu_item_faculties",
    url: "/faculties",
    "is-href": true,
  },
  { name: "ui.menu_item_programs", url: "/programs", "is-href": false },
  { name: "ui.menu_item_addmissions", url: "/student-services", "is-href": true },
  {
    name: "ui.menu_item_community",
    url: "/alumni",
    "is-href": true,
  },
  {
    name: "ui.menu_item_blog",
    url: "/blog",
    "is-href": true,
  },
  {
    name: "ui.menu_item_diploma",
    url: "/documents",
    "is-href": true,
  },
  {
    name: "ui.menu_item_partners",
    url: "/partners",
    "is-href": true,
  },
  // {
  //   name: "ui.menu_item_agreement",
  //   url: "/agreement",
  //   "is-href": true,
  // },
  // {
  //   name: "ui.menu_item_privacy",
  //   url: "/privacy",
  //   "is-href": true,
  // },
  // {
  //   name: "ui.menu_item_security_policy",
  //   url: "/security-policy",
  //   "is-href": true,
  // },
  // {
  //   name: "ui.menu_item_data_protection_policy",
  //   url: "/data-protection-policy",
  //   "is-href": true,
  // },
  // {
  //   name: "ui.menu_item_cookies_policy",
  //   url: "/cookies-policy",
  //   "is-href": true,
  // },
  {
    name: "ui.menu_item_impressum",
    url: "/impressum",
    "is-href": true,
  },
  {
    name: "ui.menu_item_faq",
    url: "/faq",
    "is-href": true,
  },
  {
    name: "ui.menu_item_reviews",
    url: "/reviews",
    "is-href": true,
  },
  {
    name: "ui.menu_item_presskit",
    url: "/presskit",
    "is-href": true,
  },
  { name: "ui.menu_item_contacts", url: "/contacts", "is-href": true },
];
