import {combineReducers} from "redux";
import {
    AUTH_SUCCESS,
    AUTH_FORM_IS_SENDING,
    USER_RECOVERY_REQUEST_SUCCESS,
    USER_RECOVERY_PASSWORD_SUCCESS,
    USER_REGISTRATION_SUCCESS,
    USER_ACTIVATION_LINK_IS_SENDING,
    USER_ACTIVATION_LINK_SUCCESS,
    USER_ACTIVATION_PROGRESS,
    USER_ACTIVATION_STATUS,
    USER_ACTIVATION_PASSWORD_SUCCESS
} from "../constants/auth";


export function authFormIsSending(state = false, action) {
    switch (action.type) {
        case AUTH_FORM_IS_SENDING:
            return action.bool;
        default:
            return state;
    }
}


export function authSuccess(state = false, action) {
    switch (action.type) {
        case AUTH_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}

export function userRecoveryRequestSuccess(state = false, action) {
    switch (action.type) {
        case USER_RECOVERY_REQUEST_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}


export function userRegistrationSuccess(state = false, action) {
    switch (action.type) {
        case USER_REGISTRATION_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}

export function userRecoveryPasswordSuccess(state = false, action) {
    switch (action.type) {
        case USER_RECOVERY_PASSWORD_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}

export function activationLinkIsSending(state = false, action) {
    switch (action.type) {
        case USER_ACTIVATION_LINK_IS_SENDING:
            return action.bool;
        default:
            return state;
    }
}

export function activationLinkSuccess(state = false, action) {
    switch (action.type) {
        case USER_ACTIVATION_LINK_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}

export function userActivationProgress(state = true, action) {
    switch (action.type) {
        case USER_ACTIVATION_PROGRESS:
            return action.bool;
        default:
            return state;
    }
}

export function userActivationStatus(state = false, action) {
    switch (action.type) {
        case USER_ACTIVATION_STATUS:
            return action.bool;
        default:
            return state;
    }
}

export function userActivationPasswordSuccess(state = false, action) {
    switch (action.type) {
        case USER_ACTIVATION_PASSWORD_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}


export const authReducers = () => combineReducers({
    authFormIsSending: authFormIsSending,
    authSuccess: authSuccess,
    userRecoverySuccess: userRecoveryRequestSuccess,
    userRegistrationSuccess: userRegistrationSuccess,
    activationLinkIsSending: activationLinkIsSending,
    activationLinkSuccess: activationLinkSuccess,
    userActivationStatus: userActivationStatus,
    userActivationProgress: userActivationProgress,
    userActivationPasswordSuccess: userActivationPasswordSuccess,
    userRecoveryPasswordSuccess: userRecoveryPasswordSuccess
});
