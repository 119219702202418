import {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {Form, Input, Spin} from "antd";

import {userActivation, userActivationPassword} from "../../actions/auth";
import MainLayout from "../layout";
import PageNotFound from "../not-found";
import PasswordSuccess from "./success/password";
import {checkPasswordStrength} from "../../validators/password-strength";
import {Loading} from "../utils/loading";
import {loadingIcon, LockIcon} from "../utils/svg";
import { Title, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

function Activation() {
    const location = useLocation();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const {authFormIsSending, userActivationStatus, userActivationProgress, userActivationPasswordSuccess}= useSelector(state => state.auth);
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    const { t } = useTranslation();

    const title = {
        caption: t("ui.head.activation_title")
    };

    useEffect(() => {
        dispatch(userActivation(token));
    }, [dispatch, token]);

    const onFinish = (values) => {
        dispatch(userActivationPassword(values));
    }

    const compareToFirstPassword = (rule, value) => {
        if (value && value !== form.getFieldValue('password')) {
            return Promise.reject(t("auth.password_dont_match"));
        } else {
            return Promise.resolve();
        }
    };

    if(userActivationProgress) {
        return (<MainLayout title={title}><Loading contentPreloader/></MainLayout>);
    }

    if(!userActivationStatus) {
        return (<PageNotFound/>)
    }

    if(userActivationPasswordSuccess) {
        return <PasswordSuccess />;
    }

    return (<MainLayout chat={false} title={title}>
        <section className="page__auth">
            <Form
                colon={false}
                onFinish={onFinish}
                autoComplete="off"
                className="form form_auth"
                form={form}
            >
                <Title decor={false} className="form__title" data-key="auth.success_title">{t("auth.success_title")}</Title>

                <div className="form__note" data-key="auth.password_create_title">{t("auth.password_create_title")}</div>
                <div className="form__wrapper">
                    <Form.Item
                        name="password"
                        className="form__item form__item_single"
                        rules={[{
                            required: true,
                            message: t("auth.enter_password")
                        }, {
                            validator: checkPasswordStrength
                        }
                        ]}
                        validateTrigger="onSubmit"
                        data-key-error="auth.enter_password"
                    >
                        <Input.Password placeholder={t("auth.password")} type="password" prefix={<LockIcon />} data-key="auth.password" />
                    </Form.Item>
                    <Form.Item
                        name="password_confirm"
                        className="form__item form__item_single"
                        rules={[{
                            required: true,
                            message: t("auth.enter_password_repeat")
                        }, {
                            validator: compareToFirstPassword
                        }]}
                        validateTrigger="onSubmit"
                        data-key-error="auth.enter_password_repeat"
                    >
                        <Input.Password placeholder={t("auth.password_repeat")} type="password" prefix={<LockIcon />} data-key="auth.password_repeat" />
                    </Form.Item>
                    <Button type="submit" maxWidth={true} disabled={authFormIsSending} data-key="ui.save">
                        {authFormIsSending ? <Spin indicator={loadingIcon} />: t("ui.save") }
                    </Button>
                </div>
                <div className="form__note form__note_inverse" data-key="auth.password_note">
                    {t("auth.password_note")}
                </div>
            </Form>
        </section>
    </MainLayout>)
}

export default Activation;
