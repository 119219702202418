import { List } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";

import { DoneIcon, CheckingIcon, PaymentIcon, ProgressIcon, LockIcon } from "../utils/svg";

function PlanListItem({ item, planSlug, semesterNumber }) {
  const { t } = useTranslation();

  let icon = <ProgressIcon />;
  let iconClass = "";
  let markClass = "";
  let markText = t("ui.not_passed");
  let link = `/plans/${planSlug}/${semesterNumber}/${item.slug}`;

  if (item.state === "completed") {
    icon = <DoneIcon />;
    iconClass = "plan-semester__list-item-icon_done";
    markClass = "plan-semester__list-item-mark_done";
    markText = item.grade;
  }

  if (item.state === "moderation") {
    icon = <CheckingIcon />;
    iconClass = "plan-semester__list-item-icon_checking";
    markClass = "plan-semester__list-item-mark_checking";
    markText = t("ui.checking");
  }

  if (item.draft === true || item.hidden) {
    iconClass = "plan-semester__list-item-icon_process";
    markClass = "plan-semester__list-item-mark_process";
    markText = item.hidden ? t("ui.not_selected") : t("ui.process");
    item.hidden && (icon = <LockIcon />);
    link = undefined;
  }

  if (!item.free && !item.paid && !item.hidden) {
    icon = <PaymentIcon />;
    iconClass = "plan-semester__list-item-icon_payment";
    link = `/plans/${planSlug}/payments`;
  }

  return (
    <List.Item link={link} className="plan-semester__list-item" icon={icon} iconClass={iconClass} title={item.title} mark={markText} markClass={markClass} data-key="ui.not_passed, ui.checking">
      {item.user_description?.length > 0 && <span className="plan-semester__list-item-subtext">{item.user_description}</span>}
    </List.Item>
  );
}

export default PlanListItem;
