import { combineReducers } from "redux";
import { WORKS_DATA_LOADING, WORKS_DATA, SEND_RATING_WORKS_SUCCESS, RATING_WORKS_IS_SENDING, WORKS_REMOVE } from "../constants/works";

export function worksData(state = [], action) {
  switch (action.type) {
    case WORKS_DATA:
      return action.data;
    case WORKS_REMOVE:
      return state.filter((w) => w.id !== action.payload.id);
    default:
      return state;
  }
}

export function worksDataLoading(state = true, action) {
  switch (action.type) {
    case WORKS_DATA_LOADING:
      return action.bool;
    default:
      return state;
  }
}

export function ratingWorksSuccess(state = false, action) {
  switch (action.type) {
    case SEND_RATING_WORKS_SUCCESS:
      return action.bool;
    default:
      return state;
  }
}

export function ratingWorksIsSending(state = false, action) {
  switch (action.type) {
    case RATING_WORKS_IS_SENDING:
      return action.bool;
    default:
      return state;
  }
}

export const worksReducers = () =>
  combineReducers({
    worksData: worksData,
    worksDataLoading: worksDataLoading,
    ratingWorksSuccess: ratingWorksSuccess,
    ratingWorksIsSending: ratingWorksIsSending,
  });
