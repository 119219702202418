export const USER_SESSION = "USER_SESSION";
export const USER_SESSION_IS_LOADING = "USER_SESSION_IS_LOADING";
export const USER_SESSION_LOADED = "USER_SESSION_LOADED";
export const USER_ACTIVATION_STATE = "USER_ACTIVATION_STATE";
export const USER_COURSES = "USER_COURSES";
export const USER_COURSES_LOADING = "USER_COURSES_LOADING";
export const USER_DOCUMENTS = "USER_DOCUMENTS";
export const USER_DOCUMENTS_ARE_LOADING = "USER_DOCUMENTS_ARE_LOADING";
export const USER_DOCUMENT_IS_UPLOADING = "USER_DOCUMENT_IS_UPLOADING";
export const USER_PROFILE_IS_UPDATING = "USER_PROFILE_IS_UPDATING";
export const USER_PROFILE_UPDATED = "USER_PROFILE_UPDATED";
export const USER_CONSULT_INFO = "USER_CONSULT_INFO";
export const SET_USER_COURSE_SLUG = "SET_USER_COURSE_SLUG";
