import {useState} from "react";
import {useDispatch} from "react-redux";
import { Avatar, Upload, Modal} from "antd";
import { Button } from "@vshp/ui-components";

import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

import {submitProfile, cropProfileAvatar} from "../../../actions/users";
import {
    ProfileUserIcon,
    ArrowFullIcon,
    EditIcon,
    DeleteIcon,
    CrossIcon
} from "../../utils/svg";
import { useTranslation } from "react-i18next";

function ProfileAvatar(props) {
    const dispatch = useDispatch();
    const {user, photoExists, loading, updating, isEdit} = props;
    const [cropSrc, setCropSource] = useState(null);
    let initialCropSrc = null; // такая двойная переменная, чтобы кропнутая картинка была и в момент инициализации маски и во время кропа
    const [cropData, changeCropData] = useState({data: [], k: 1});
    const [crop, changeCrop] = useState({
        unit: "%",
        width: 50,
        aspect: 1
    });
    const [showCrop, toggleCrop] = useState(false);
    const [croppedImageUrl, setCroppedImageUrl] = useState(null);
    const { t } = useTranslation();

    const onImageLoaded = image => {
        setCropSource(image);
        initialCropSrc = image;
    }

    const onCropComplete = crop => {
        makeClientCrop(crop);
    }

    async function makeClientCrop(crop) {
        initialCropSrc = initialCropSrc || cropSrc;
        if (initialCropSrc && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                initialCropSrc,
                crop,
                'newFile.jpeg'
            );

            const image = initialCropSrc;
            const scaleX = image.naturalWidth / image.width;

            changeCropData({data: crop, k: scaleX});
            setCroppedImageUrl(croppedImageUrl);
        }
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;
        const ctx = canvas.getContext('2d');

        let img = new Image();
        img.crossOrigin = "Anonymous";

        return new Promise((resolve, reject) => {
            img.onload = function () {
                ctx.drawImage(
                    img,
                    crop.x * scaleX,
                    crop.y * scaleY,
                    crop.width * scaleX,
                    crop.height * scaleY,
                    0,
                    0,
                    crop.width,
                    crop.height
                );

                resolve();
            };

            img.src = image.src;

        }).then(function(){
            return new Promise((resolve, reject) => {
                canvas.toBlob(blob => {
                    if (!blob) {
                        console.error('Canvas is empty');
                        return;
                    }
                    blob.name = fileName;
                    const fileUrl = window.URL.createObjectURL(blob);
                    resolve(fileUrl);
                }, 'image/jpeg');
            });
        });
    }

    const onCropChange = (crop, percentCrop) => {
        changeCrop(crop);
    };

    const cropAvatar = () => {
        dispatch(cropProfileAvatar(cropData));

        toggleCrop(false);
    }

    const handleUpload = (info) => {
        dispatch(submitProfile(null, info.file));
    };

    const handleRemove = (e) => {
        dispatch(submitProfile({"remove_photo": 1}));
    };

    const uploadProps = {
        name: "photo",
        accept: ".jpg, .jpeg, .png",
        multiple: false,
        showUploadList: false,
        onChange: handleUpload,
        beforeUpload: file => {
            return false;
        }
    };

    return (
        <div className="profile-info__avatar">

            <Avatar className="profile-info__avatar-source" size={100} icon={<ProfileUserIcon />} src={loading ? null: user.photo.profile } />

            {isEdit ? (<div className="profile-info__avatar-actions">
                <Upload {...uploadProps}><Button size="small" icon={true} disabled={updating}><ArrowFullIcon /></Button></Upload>
                <Button size="small" icon={true} onClick={toggleCrop} disabled={!photoExists}><EditIcon /></Button>
                <Button size="small" icon={true} onClick={handleRemove} disabled={!photoExists}><DeleteIcon /></Button>
            </div>):""}

            <Modal
                visible={showCrop}
                footer={null}
                onCancel={() => toggleCrop(!showCrop)}
                closeIcon={<CrossIcon />}
                wrapClassName="avatar-crop"
            >
                <div className="avatar-crop__wrapper">
                    <ReactCrop
                        src={user.photo.original}
                        crop={crop}
                        ruleOfThirds
                        onImageLoaded={onImageLoaded}
                        onComplete={onCropComplete}
                        onChange={onCropChange}
                    />
                    {croppedImageUrl && (
                        <img src={croppedImageUrl} className="avatar-crop__preview" alt=""/>
                    )}
                    <Button className="avatar-crop__submit" onClick={(e) => cropAvatar(e)} data-key="ui.save_changes">{t("ui.save_changes")}</Button>
                </div>
            </Modal>
        </div>
    )
}

export default ProfileAvatar;
