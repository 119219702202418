import Cookie from "js-cookie";

import { RESPONSE_ERRORS, RESPONSE_SUCCESS, RESPONSE_ERROR_SERVER } from "../constants/global";
import { DOMAIN } from "../config/config";

export function isLoggedIn() {
  if (getToken()) {
    return true;
  }
  return false;
}

export function setToken(token) {
  Cookie.set("token", token, { expires: 7, domain: DOMAIN, secure: true });
}

export function setRefId(refid) {
  Cookie.set("refid", refid, { domain: DOMAIN });
}

export function setGetParam(name, param, time = 7) {
  if (!name) {
    return;
  }
  Cookie.set(name, param, { expires: time, domain: DOMAIN });
}

export function getToken() {
  return Cookie.get("token") || null;
}

export function getRoistatVisit() {
  return Cookie.get("roistat_visit") || null;
}

export function getRefId() {
  return Cookie.get("refid") || null;
}

export function getReferral() {
  return Cookie.get("referral") || null;
}

export function getGetParam(name) {
  return Cookie.get(name) || null;
}

export function removeToken() {
  Cookie.remove("token", { domain: DOMAIN });
}

export function removeParam(name) {
  Cookie.remove(name, { domain: DOMAIN });
}

export function errorMessage(errors) {
  return {
    type: RESPONSE_ERRORS,
    errors,
  };
}

export function successMessage(success) {
  return {
    type: RESPONSE_SUCCESS,
    success,
  };
}

export function errorServer(bool) {
  return {
    type: RESPONSE_ERROR_SERVER,
    bool,
  };
}

export function setNoticeLocale() {
  Cookie.set("notice_locale", "no", { expires: 365, domain: DOMAIN });
}

export function getNoticeLocale() {
  return Cookie.get("notice_locale") || null;
}

export function setNoticeCookies() {
  Cookie.set("notice_cookies_accept", "yes", { expires: 365, domain: DOMAIN });
}

export function getNoticeCookies() {
  return Cookie.get("notice_cookies_accept") || null;
}

export function setTheme() {
  Cookie.set("theme_dark", "yes", { expires: 365 });
}

export function getTheme() {
  return Cookie.get("theme_dark") || null;
}

export function removeTheme() {
  Cookie.remove("theme_dark");
}
