import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Form, Radio, Checkbox } from "antd";
import { useTranslation } from "react-i18next";
import { sendAnswer, sendingAnswerSuccess } from "../../actions/plan";

function TestQuestion({ q, questionNumber, testLength, slug, semesterNumber, themeSlug, testQuestion }) {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const answers = q.attributes["answer-ids"];
  const { t } = useTranslation();
  const { planTestQuestionsWithoutAnswer, planTestAnswerSuccess } = useSelector((state) => state.plan);

  useEffect(() => {
    q.attributes["answer-ids"].length > 0 && dispatch(sendingAnswerSuccess(true));
  }, [dispatch, q]);

  const handleSendAnswer = (e) => {
    dispatch(sendAnswer(slug, semesterNumber, themeSlug, q.id, testQuestion, e.target.value));
  };

  const nextLink =
    questionNumber === testLength && planTestQuestionsWithoutAnswer.length > 0
      ? `/plans/${slug}/${semesterNumber}/${themeSlug}/test/${planTestQuestionsWithoutAnswer[0] + 1}/#question`
      : `/plans/${slug}/${semesterNumber}/${themeSlug}/test/${questionNumber + 1}/#question`;

  const handleNextLinkBtn = () => {
    dispatch(sendingAnswerSuccess(false));
  };

  return (
    <div className="test__question">
      <div className="test__content">
        <h3 className="test__title" data-key="test.question_title">
          {t("test.question_title")} – {questionNumber}/{testLength}
        </h3>
        <div className="test__subtitle" dangerouslySetInnerHTML={{ __html: q.attributes.content }}></div>
        {q.attributes.images &&
          q.attributes.images.length > 0 &&
          q.attributes.images.map(({ image }, id) => <img className="test__question-img" src={image} alt="" key={id} onClick={() => window.open(image, "_blank")} />)}

        <Form colon={false} className="test__form form" form={form}>
          {!q.attributes.multiply ? (
            <Radio.Group onChange={handleSendAnswer} className="test__answers" value={answers.length ? (q.attributes.multiply ? answers : answers[0]) : null}>
              {q.attributes.answers.map((item, key) => {
                return (
                  <div key={key}>
                    <Radio value={item.id}>
                      <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                    </Radio>
                  </div>
                );
              })}
            </Radio.Group>
          ) : (
            <Checkbox.Group onChange={handleSendAnswer} className="test__answers" value={answers.length ? (q.attributes.multiply ? answers : answers[0]) : null} name="answer">
              {q.attributes.answers.map((item, key) => {
                return (
                  <div key={key}>
                    <Checkbox value={item.id}>
                      <span dangerouslySetInnerHTML={{ __html: item.content }}></span>
                    </Checkbox>
                  </div>
                );
              })}
            </Checkbox.Group>
          )}
        </Form>
      </div>
      <div className="test__nav">
        <Link
          className={`btn btn_primary${questionNumber === 1 ? " disabled" : ""}`}
          to={`/plans/${slug}/${semesterNumber}/${themeSlug}/test/${questionNumber - 1}/#question`}
          data-key="test.question_prev_btn"
        >
          {t("test.question_prev_btn")}
        </Link>
        <Link
          className={`btn btn_primary${(questionNumber === testLength && planTestQuestionsWithoutAnswer.length === 0) || !planTestAnswerSuccess ? " disabled" : ""}`}
          to={nextLink}
          onClick={handleNextLinkBtn}
          data-key="test.question_next_btn"
        >
          {t("test.question_next_btn")}
        </Link>
      </div>
    </div>
  );
}

export default TestQuestion;
