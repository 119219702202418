import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, NavLink } from "react-router-dom";
import { fetchProgramsDegrees } from "../../actions/programs";

import { CHAT_OPEN_CLASS, MENU_OPEN_CLASS, NAVIGATION, RUSSIAN_COUNTRY_CODE } from "../../constants/global";
import { URL } from "../../config/config";
import { LogoIcon, UserIcon } from "../utils/svg";
import { LogoTextIcon } from "../utils/logo-text";
import { useTranslation } from "react-i18next";
import HeaderLanguages from "./header-languages";
import HeaderNotice from "./header-notice";
import HeaderNoticeError from "./header-notice-error";
import { getNoticeLocale } from "../../actions/global";
import i18next from "i18next";
import SwitchTheme from "../shared/Switch";

function AppHeader(props) {
  const dispatch = useDispatch();
  const [isNavToggled, toggleNav] = useState(false);
  const { session, sessionLoaded } = useSelector((state) => state.users);
  const { programsDegrees, programsDegreesAreLoading } = useSelector((state) => state.programs);
  const { responseErrorServer } = useSelector((state) => state.global);
  const { t } = useTranslation();
  let currentLanguage = i18next.language;
  let noticeLocale = getNoticeLocale();

  let languageBrowser = window.navigator ? window.navigator.language || window.navigator.systemLanguage || window.navigator.userLanguage : "en";
  languageBrowser = languageBrowser.substr(0, 2).toLowerCase();

  useEffect(() => {
    isNavToggled ? document.body.classList.add(MENU_OPEN_CLASS) : document.body.classList.remove(MENU_OPEN_CLASS);
    if (isNavToggled) {
      document.body.classList.remove(CHAT_OPEN_CLASS);
    }
  }, [isNavToggled]);

  useEffect(() => {
    dispatch(fetchProgramsDegrees({ locale: currentLanguage }));
  }, [dispatch, currentLanguage]);

  const renderNavLink = (url, name, isHref) =>
    url ? (
      isHref ? (
        <a href={`${URL}${url}`} rel="noreferrer" data-key={name}>
          {t(name)}
        </a>
      ) : (
        <NavLink to={url} data-key={name}>
          {t(name)}
        </NavLink>
      )
    ) : (
      <span data-key={name}>{t(name)}</span>
    );

  return (
    <>
      {currentLanguage !== languageBrowser && noticeLocale !== "no" && <HeaderNotice lang={languageBrowser} />}
      {responseErrorServer && <HeaderNoticeError />}
      <div className="header__wrap">
        <header className="header">
          <div className="header__container">
            <a className="header__logo" href={`${URL}${currentLanguage === "en" ? "" : `/${currentLanguage}`}/`}>
              <LogoIcon className="header__logo-icon header__logo-icon_mobile" />
              <LogoTextIcon className="header__logo-icon" />
            </a>

            <div className="header__buttons">
              <HeaderLanguages />
              <NavLink className="header__sign-in" to={!sessionLoaded ? "/sign_in" : "/profile"}>
                <UserIcon className="header__sign-in-icon" />
                <span className="header__sign-in-label" data-key="ui.account, ui.login">
                  {sessionLoaded ? t("ui.account") : t("ui.login")}
                </span>
              </NavLink>

              <div className="header__burger" onClick={() => toggleNav(!isNavToggled)}>
                <div className="header__burger-icon">
                  <span></span>
                  <span></span>
                  <span></span>
                  <span></span>
                </div>
                <div className="header__burger-label" data-key="ui.menu">
                  {t("ui.menu")}
                </div>
              </div>
            </div>
          </div>

          <div className="header__backdrop" onClick={() => toggleNav(!isNavToggled)}></div>

          <div className="header__body">
            <div className="header__close" onClick={() => toggleNav(!isNavToggled)}>
              <span></span>
              <span></span>
            </div>

            <div className="header__wrapper">
              <nav className="header__nav">
                {!programsDegreesAreLoading && programsDegrees.length > 0 ? (
                  <div className="header__nav-parent">
                    <div className="header__nav-label" data-key="ui.degrees">
                      {t("ui.degrees")}
                    </div>
                    {programsDegrees.map((item, ikey) => (
                      <NavLink to={`/programs/${item.attributes.slug}`} onClick={() => toggleNav(!isNavToggled)} key={ikey} className="header__nav-item">
                        {item.attributes.title}
                      </NavLink>
                    ))}
                  </div>
                ) : null}
                <div className="header__nav-parent">
                  {sessionLoaded ? (
                    <>
                      <div className="header__nav-label" data-key="ui.account">
                        {t("ui.account")}
                      </div>
                      <NavLink to="/profile" className="header__nav-item" data-key="ui.account">
                        {t("ui.account")}
                      </NavLink>
                      {session.staff && currentLanguage === RUSSIAN_COUNTRY_CODE && (
                        <a href="https://vshp.online/admin" className="header__nav-item" data-key="ui.staff_account">
                          {t("ui.staff_account")}
                        </a>
                      )}
                      <NavLink to="/sign_out" className="header__nav-item" data-key="ui.logout">
                        {t("ui.logout")}
                      </NavLink>
                    </>
                  ) : (
                    <>
                      <div className="header__nav-label" data-key="ui.account">
                        {t("ui.account")}
                      </div>
                      <NavLink to="/register" className="header__nav-item" data-key="ui.signup">
                        {t("ui.signup")}
                      </NavLink>
                      <NavLink to="/recovery" className="header__nav-item" data-key="ui.password_recovery">
                        {t("ui.password_recovery")}
                      </NavLink>
                      <NavLink to="/sign_in" className="header__nav-item" data-key="ui.login">
                        {t("ui.login")}
                      </NavLink>
                    </>
                  )}
                  <NavLink to="/account_delete" className="header__nav-item" data-key="ui.account_delete">
                    {t("ui.account_delete")}
                  </NavLink>
                </div>

                {NAVIGATION.map(({ name, url, submenu }, ikey) => (
                  <div className="header__nav-parent" key={ikey}>
                    <div className="header__nav-label">{renderNavLink(url, name)}</div>
                    {submenu &&
                      submenu.length > 0 &&
                      submenu.map(({ name, url, submenu, "is-href": isHref }, key) => (
                        <div key={key}>
                          <div className="header__nav-item">{renderNavLink(url, name, isHref)}</div>
                          <div className="header__nav-submenu">
                            {submenu &&
                              submenu.length > 0 &&
                              submenu.map(({ name, url }, key) => (
                                <div className="header__nav-item" key={key}>
                                  {renderNavLink(url, name, isHref)}
                                </div>
                              ))}
                          </div>
                        </div>
                      ))}
                  </div>
                ))}

                <div className="header__nav-parent">
                  <div className="header__nav-label" data-key="ui.dark_theme">
                    {t("ui.dark_theme")}
                  </div>
                  <SwitchTheme />
                </div>
              </nav>
            </div>
          </div>
        </header>
      </div>
    </>
  );
}

export default AppHeader;
