import { combineReducers } from 'redux';
import {
    PLAN,
    PLAN_IS_LOADING,
    PLAN_IS_LOADED,
    PLAN_PAYMENTS,
    PLAN_PAYMENTS_IS_LOADING,
    PAYMENT_LINK,
    PLAN_SEMESTER,
    PLAN_SEMESTER_IS_LOADING,
    PLAN_SEMESTER_THEMES,
    PLAN_SEMESTER_THEMES_ARE_LOADING,
    PLAN_SEMESTER_THEMES_LOADED,
    PLAN_SEMESTER_UNIT,
    PLAN_SEMESTER_UNIT_IS_LOADING,
    PLAN_SEMESTER_UNIT_LOADED,
    PLAN_TEST,
    PLAN_TEST_IS_LOADING,
    PLAN_TEST_ANSWER_IS_SENDING,
    PLAN_TEST_ANSWER_SUCCESS,
    PLAN_TEST_COMPLETE,
    PLAN_TEST_COMPLETE_RESPONSE,
    PLAN_TEST_RESULTS_SENDING,
    HOMEWORK_IS_UPLOADING,
    PLAN_UNIT_COMPLETE_SENDING,
    PLAN_UNIT_COMPLETE_DONE,
    PLAN_SEMESTERS,
    PLAN_SEMESTERS_UPDATE,
    QUESTIONS_WITHOUT_ANSWER
} from "../constants/plan";

export function plan(state = {}, action) {
    switch (action.type) {
        case PLAN:
            return action.data;
        default:
            return state;
    }
}

export function planIsLoading(state = true, action) {
    switch (action.type) {
        case PLAN_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planIsLoaded(state = true, action) {
    switch (action.type) {
        case PLAN_IS_LOADED:
            return action.bool;
        default:
            return state;
    }
}

export function planPayments(state = {}, action) {
    switch (action.type) {
        case PLAN_PAYMENTS:
            return action.data;
        default:
            return state;
    }
}

export function planPaymentsIsLoading(state = true, action) {
    switch (action.type) {
        case PLAN_PAYMENTS_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planSemester(state = {}, action) {
    switch (action.type) {
        case PLAN_SEMESTER:
            return action.data;
        default:
            return state;
    }
}

export function planSemesterIsLoading(state = true, action) {
    switch (action.type) {
        case PLAN_SEMESTER_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planSemesterThemes(state = {}, action) {
    switch (action.type) {
        case PLAN_SEMESTER_THEMES:
            return action.data;
        default:
            return state;
    }
}

export function planSemesterThemesAreLoading(state = true, action) {
    switch (action.type) {
        case PLAN_SEMESTER_THEMES_ARE_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planSemesterThemesLoaded(state = false, action) {
    switch (action.type) {
        case PLAN_SEMESTER_THEMES_LOADED:
            return action.bool;
        default:
            return state;
    }
}

export function planSemesterUnit(state = {}, action) {
    switch (action.type) {
        case PLAN_SEMESTER_UNIT:
            return action.data;
        default:
            return state;
    }
}

export function planSemesterUnitIsLoading(state = true, action) {
    switch (action.type) {
        case PLAN_SEMESTER_UNIT_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planSemesterUnitLoaded(state = false, action) {
    switch (action.type) {
        case PLAN_SEMESTER_UNIT_LOADED:
            return action.bool;
        default:
            return state;
    }
}

export function planTest(state = {}, action) {
    switch (action.type) {
        case PLAN_TEST:
            return action.data;
        default:
            return state;
    }
}

export function planTestIsLoading(state = true, action) {
    switch (action.type) {
        case PLAN_TEST_IS_LOADING:
            return action.bool;
        default:
            return state;
    }
}

export function planTestAnswerIsSending(state = false, action) {
    switch (action.type) {
        case PLAN_TEST_ANSWER_IS_SENDING:
            return action.bool;
        default:
            return state;
    }
}

export function planTestAnswerSuccess(state = false, action) {
    switch (action.type) {
        case PLAN_TEST_ANSWER_SUCCESS:
            return action.bool;
        default:
            return state;
    }
}

export function planTestQuestionsWithoutAnswer(state = [], action) {
    switch (action.type) {
        case QUESTIONS_WITHOUT_ANSWER:
            return action.data;
        default:
            return state;
    }
}

export function planTestComplete(state = false, action) {
    switch (action.type) {
        case PLAN_TEST_COMPLETE:
            return action.bool;
        default:
            return state;
    }
}

export function planTestCompleteResponse(state = {}, action) {
    switch (action.type) {
        case PLAN_TEST_COMPLETE_RESPONSE:
            return action.data;
        default:
            return state;
    }
}

export function planTestResultsSending(state = false, action) {
    switch (action.type) {
        case PLAN_TEST_RESULTS_SENDING:
            return action.data;
        default:
            return state;
    }
}

export function planPaymentLink(state = "", action) {
    switch (action.type) {
        case PAYMENT_LINK:
            return action.link;
        default:
            return state;
    }
}

export function homeWorkIsUploading(state = false, action) {
    switch (action.type) {
        case HOMEWORK_IS_UPLOADING:
            return action.bool;
        default:
            return state;
    }
}

export function completePlanUnitSending(state = false, action) {
    switch (action.type) {
        case PLAN_UNIT_COMPLETE_SENDING:
            return action.bool;
        default:
            return state;
    }
}

export function completePlanUnitDoneState(state = false, action) {
    switch (action.type) {
        case PLAN_UNIT_COMPLETE_DONE:
            return action.bool;
        default:
            return state;
    }
}

export function planSemesters(state = [], action) {
    switch (action.type) {
        case PLAN_SEMESTERS:
            return action.semesters;
        case PLAN_SEMESTERS_UPDATE:
            let currentUnit = state[action.semesterNum].data[action.number];
            currentUnit.state = "completed";
            currentUnit.grade = action.grade;
            return state;
        default:
            return state;
    }
}

export const plansReducers = () => combineReducers({
    plan: plan,
    planIsLoading: planIsLoading,
    planIsLoaded: planIsLoaded,
    planPayments: planPayments,
    planPaymentsIsLoading: planPaymentsIsLoading,
    planPaymentLink: planPaymentLink,
    planSemester: planSemester,
    planSemesterIsLoading: planSemesterIsLoading,
    planSemesterUnitLoaded: planSemesterUnitLoaded,
    planSemesterUnit: planSemesterUnit,
    planSemesterUnitIsLoading: planSemesterUnitIsLoading,
    planSemesterThemes: planSemesterThemes,
    planSemesterThemesAreLoading: planSemesterThemesAreLoading,
    planSemesterThemesLoaded: planSemesterThemesLoaded,
    planTest: planTest,
    planTestIsLoading: planTestIsLoading,
    planTestAnswerIsSending: planTestAnswerIsSending,
    planTestAnswerSuccess: planTestAnswerSuccess,
    planTestComplete: planTestComplete,
    planTestCompleteResponse: planTestCompleteResponse,
    planTestResultsSending: planTestResultsSending,
    homeWorkIsUploading: homeWorkIsUploading,
    completePlanUnitSending: completePlanUnitSending,
    completePlanUnitDoneState: completePlanUnitDoneState,
    planSemesters: planSemesters,
    planTestQuestionsWithoutAnswer: planTestQuestionsWithoutAnswer
});
