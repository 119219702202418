import {useState} from "react";
import {useDispatch} from "react-redux";
import { Link } from "react-router-dom";
import {DeleteIcon} from "../utils/svg";
import {deleteUserCourse} from "../../actions/users";
import { Button, Card, Progress } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";


function ProfileProgram(props) {
    const {item} = props;
    const dispatch = useDispatch();
    const [isDelete, toggleDelete] = useState(false);
    const { t } = useTranslation();
    const progress = item.attributes.progress;

    const deleteProgram = (e) => {
        dispatch(deleteUserCourse(item.attributes.slug));
        toggleDelete(false);
    }

    const programCardLink = ({ children, ...props }) => {
        return (
          <Link to={`/plans/${item.attributes.slug}`} {...props}>
            {children}
          </Link>
        );
      };

    return (
        <Card
            link={programCardLink}
            cover={
                <img alt={item.attributes.course.title} src={item.attributes.photo.preview} />
            }
            title={item.attributes.course.title}
            btnText={t("ui.see_more")}
            data-key="ui.see_more"
        >
            {item.attributes["can-destroy"] && (
                <Card.Delete
                    isDelete={isDelete}
                    textConfirm={t("program.delete_title")}
                    btnDelete={
                        <Button className="card__delete" size={"small"} icon={true} onClick={(e) => toggleDelete(true)}><DeleteIcon /></Button>
                    }
                    btnConfirm={<Button className="card__delete-backdrop-btn" onClick={(e) => deleteProgram(e)} data-key="ui.yes">{t("ui.yes")}</Button>}
                    btnCancel={<Button className="card__delete-backdrop-btn" onClick={(e) => toggleDelete(false)} data-key="ui.no">{t("ui.no")}</Button>}
                    data-key="program.delete_title"
                />
            )}

                <Card.Row>
                    <Card.Title data-key="program.passed">{t("program.passed")}</Card.Title>
                    <Progress progress={progress}/>
                </Card.Row>
                <Card.Row>
                    <Card.Title data-key="program.degree">{t("program.degree")}</Card.Title>
                    <Card.Subtitle>{item.attributes.degree}</Card.Subtitle>
                </Card.Row>
                <Card.Row>
                    <Card.Title data-key="program.language">{t("program.language")}</Card.Title>
                    <Card.Subtitle>{item.attributes.language}</Card.Subtitle>
                </Card.Row>
        </Card>
    );
}

export default ProfileProgram;
