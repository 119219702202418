import { useEffect, useState, useRef } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Badge, Form, Input, Upload, Spin, Popover } from "antd";
import { ActionCableConsumer, ActionCableProvider } from "react-actioncable-provider";
import DayJS from "react-dayjs";
import CustomScroll from "react-custom-scroll";
import nl2br from "react-nl2br";
import { useTranslation } from "react-i18next";

import { DATE_FORMAT, TIME_FORMAT, CHAT_MENU_OPEN_CLASS, CHAT_OPEN_CLASS, CHAT_STICKERS_PREFIX } from "../../constants/global";
import { API_WS_ROOT } from "../../config/config";
import { getToken } from "../../actions/global";
import {
  fetchChatHistory,
  fetchChatSearchResult,
  fetchChatHistoryLoadMore,
  chatHistoryLoadMoreAreLoaded,
  recieveMessage,
  sendingMessage,
  submitAttachment,
  viewAttachment,
  chatSearchResultSuccess,
  fetchChatStickers,
  updateStatusMessages,
} from "../../actions/chat";

import { ArrowFullIcon, CrossIcon, ClipIcon, MessageIcon, ChatIcon, CloseIcon, ProfileUserIcon, SearchIcon, ArrowIcon, SmileIcon } from "../utils/svg";
import { Linker } from "../utils/linker";
import i18next from "i18next";

import ChatBtnConsult from "./common/consult-chat-btn";
import { Button } from "@vshp/ui-components";
import cn from "classnames";

function Chat(props) {
  const { user } = props;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formSearch] = Form.useForm();
  const { TextArea, Search } = Input;
  const [unreadMessages, changeUnreadMessages] = useState(0);
  const [consultIsOnline, changeConsultOnlineStatus] = useState(false);
  const [showChatButtons, toggleChatButtons] = useState(false);
  const [uploadDisplay, toggleUploadDisplay] = useState(true);
  const [chatCollapsed, toggleChatWindow] = useState(false);
  const [messageIsSend, setMessageIsSend] = useState(false);

  const [searchOpen, toggleSearchOpen] = useState(false);
  const [scrollPosY, setScrollPosY] = useState(0);
  const [scrollIsUp, setScrollIsUp] = useState(null);
  const [scrollLoadMoreActive, setScrollLoadMoreActive] = useState(false);
  const [searchCurrentMessage, setSearchCurrentMessage] = useState({});
  const [searchCurrentMessageNum, setSearchCurrentMessageNum] = useState(0);
  const [isSearched, setIsSearched] = useState(false);
  const [isConsultTyping, setConsultTyping] = useState(false);
  const [isUserTyping, setUserTyping] = useState(false);

  const [popoverVisible, setPopoverVisible] = useState(false);

  const {
    history,
    historyLoading,
    sending,
    uploading,
    loadMoreLink,
    loadMoreLinkNext,
    chatHistoryMoreAreLoading,
    chatHistoryMoreAreLoaded,
    chatSearchResult,
    chatSearchResultAreLoading,
    chatStickers,
    chatStickersAreLoading,
  } = useSelector((state) => state.chat);

  const chatChannel = useRef(null);
  const inputSearchRef = useRef(null);
  const contentRef = useRef(null);
  const textAreaField = useRef(null);

  const { t } = useTranslation();
  let locale = i18next.language;

  const token = getToken();

  useEffect(() => {
    changeUnreadMessages(user["unread-messages-count"]);
    changeConsultOnlineStatus(user["consultant-online"]);
  }, [user]);

  useEffect(() => {
    dispatch(fetchChatHistory(locale));
  }, [dispatch, locale]);

  useEffect(() => {
    if (!showChatButtons && isEmpty(chatStickers)) {
      dispatch(fetchChatStickers());
    }
  }, [dispatch, showChatButtons]);

  useEffect(() => {
    searchOpen &&
      inputSearchRef.current.focus({
        cursor: "start",
      });
  }, [searchOpen]);

  useEffect(() => {
    if (messageIsSend) {
      textAreaField.current.focus({
        cursor: "start",
      });
      setMessageIsSend(false);
    }
  }, [messageIsSend]);

  useEffect(() => {
    if (chatSearchResult.length > 0 && !chatSearchResultAreLoading) {
      setSearchCurrentMessage(chatSearchResult[searchCurrentMessageNum]);
      let firstSearchResultMessage = chatSearchResult[searchCurrentMessageNum];
      dispatch(fetchChatHistory({ id: firstSearchResultMessage.id }, locale));
    }
  }, [dispatch, chatSearchResult, locale, searchCurrentMessageNum]);

  useEffect(() => {
    if (!showChatButtons) {
      document.body.classList.remove(CHAT_MENU_OPEN_CLASS);
    } else {
      document.body.classList.add(CHAT_MENU_OPEN_CLASS);
    }
  }, [showChatButtons]);

  useEffect(() => {
    const container = document.getElementsByClassName("rcs-inner-container")[0];
    !scrollIsUp && container.scroll(0, scrollPosY);
    chatHistoryMoreAreLoaded && scrollIsUp && container.scroll(0, Math.abs(container.scrollHeight - (scrollPosY + container.clientHeight)));
  }, [chatHistoryMoreAreLoaded]);

  useEffect(() => {
    if (chatSearchResult.length > 0 && !chatSearchResultAreLoading) {
      const currentFoundMessage = document.getElementsByClassName("js-chat-message-found")[0];
      if (!scrollLoadMoreActive) {
        currentFoundMessage && currentFoundMessage.scrollIntoView({ block: "center" });
      } else {
        setScrollLoadMoreActive(false);
      }
    }
  }, [history]);

  const toggleCollapsed = () => {
    toggleChatButtons(false);

    if (unreadMessages > 0) {
      chatChannel.current.perform("mark_as_read");
      changeUnreadMessages(0);
    }

    toggleChatWindow(!chatCollapsed);

    document.body.classList.toggle(CHAT_OPEN_CLASS);

    !chatCollapsed &&
      textAreaField.current.focus({
        cursor: "start",
      });
  };

  const toCloseSearch = () => {
    toggleSearch();
    formSearch.resetFields();
    dispatch(chatSearchResultSuccess([]));
    setIsSearched(false);
    setMessageIsSend(true);
  };

  const toggleSearch = () => {
    toggleSearchOpen(!searchOpen);
  };

  const onSearch = () => {
    formSearch.submit();
  };

  const onFinishSearch = (values) => {
    dispatch(fetchChatSearchResult({ query: values.chatSearch }, locale));
    setIsSearched(true);
    setSearchCurrentMessageNum(0);
  };

  const toNextSearchResult = () => {
    chatSearchResult.length !== 1 && searchCurrentMessageNum < chatSearchResult.length - 1 && setSearchCurrentMessageNum(searchCurrentMessageNum + 1);
  };

  const toPrevSearchResult = () => {
    chatSearchResult.length !== 1 && searchCurrentMessageNum !== 0 && setSearchCurrentMessageNum(searchCurrentMessageNum - 1);
  };

  const onMessagesRecieved = (response) => {
    if (response.action === "typing" && !isConsultTyping) {
      setConsultTyping(true);
      !consultIsOnline && changeConsultOnlineStatus(true);

      setTimeout(function () {
        setConsultTyping(false);
      }, 2000);

      return;
    }

    if (response.action === "read_all") {
      dispatch(updateStatusMessages());
      !consultIsOnline && changeConsultOnlineStatus(true);
      return;
    }

    if (response.action !== "message") {
      return;
    }

    dispatch(recieveMessage(response));

    if (!response.own && !chatCollapsed) {
      changeUnreadMessages(unreadMessages + 1);
    }

    if (!response.own) {
      !consultIsOnline && changeConsultOnlineStatus(true);
    }
  };

  const onFinish = (values) => {
    chatChannel.current.perform("send_message", { message: values.message });
    dispatch(sendingMessage({ content: values.message }));
    form.resetFields();
    setMessageIsSend(true);
  };

  const handleUpload = (info) => {
    dispatch(submitAttachment(info.file));
  };

  const handleView = (e, attachmentId) => {
    e.preventDefault();

    dispatch(viewAttachment(attachmentId, locale));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      onFinish(values);
    });
  };

  const checkMessageLength = (e) => {
    const value = e.target.value;

    e.preventDefault();

    toggleUploadDisplay(!value);
  };

  const handleMessageKeyPress = (e) => {
    const addKey = e.metaKey || e.ctrlKey || e.shiftKey;
    const value = form.getFieldValue("message");

    if (!isUserTyping) {
      chatChannel.current.perform("typing");
      setUserTyping(true);
      setTimeout(function () {
        setUserTyping(false);
      }, 2000);
    }

    if (addKey && e.keyCode === 13) {
      form.setFieldsValue({ message: `${value ? value : ""}\n` });
      e.preventDefault();
    }

    if (!addKey && e.keyCode === 13) {
      handleSubmit(e);
      e.preventDefault();
    }
  };

  const uploadProps = {
    showUploadList: false,
    disabled: uploading,
    name: "file",
    multiple: false,
    onChange: handleUpload,
    beforeUpload: (file) => {
      return false;
    },
  };

  const loadMoreMessages = (e) => {
    if (e.target.scrollTop === 0 && e.target.scrollHeight !== e.target.clientHeight && loadMoreLink !== null) {
      setScrollPosY(Math.abs(e.target.scrollHeight - e.target.scrollTop - e.target.clientHeight));
      dispatch(fetchChatHistoryLoadMore(loadMoreLink));
      setScrollLoadMoreActive(true);
      setScrollIsUp(true);
    } else if (e.target.scrollHeight - e.target.clientHeight === e.target.scrollTop && e.target.scrollHeight !== e.target.clientHeight && loadMoreLinkNext !== null) {
      setScrollPosY(Math.abs(e.target.scrollTop));
      dispatch(fetchChatHistoryLoadMore(loadMoreLinkNext, true));
      setScrollLoadMoreActive(true);
      setScrollIsUp(false);
    } else {
      scrollLoadMoreActive && setScrollLoadMoreActive(false);
      chatHistoryMoreAreLoaded && dispatch(chatHistoryLoadMoreAreLoaded(false));
    }
  };

  const chatFormClasses = cn("form chat__form", {
    chat__form_small: uploadDisplay,
  });

  const chatSearchResultIconNext = cn("chat__search-result-icon", {
    "chat__search-result-icon_disabled": chatSearchResult.length === 0 || (chatSearchResult.length > 0 && searchCurrentMessageNum === chatSearchResult.length - 1),
  });

  const chatSearchResultIconPrev = cn("chat__search-result-icon", {
    "chat__search-result-icon_disabled": searchCurrentMessageNum === 0,
  });

  const handleStickerClick = (id) => () => {
    setPopoverVisible(false);
    const stickersArray = Object.entries(chatStickers);
    const currentSticker = stickersArray[id];
    chatChannel.current.perform("send_message", { message: currentSticker[0] });
    dispatch(sendingMessage({ content: currentSticker[0] }));
  };

  const contentStickers = () => {
    const stickersArray = Object.entries(chatStickers);

    return (
      <CustomScroll>
        <div className="chat__stickers-wrap">
          {chatStickersAreLoading ? (
            <Spin size="small" />
          ) : (
            stickersArray.map(([name, src], id) => (
              <div className="chat__sticker" key={id} onClick={handleStickerClick(id)}>
                <img src={src} alt={name} />
              </div>
            ))
          )}
        </div>
      </CustomScroll>
    );
  };

  const handlePopoverVisible = (open) => (open ? setPopoverVisible(true) : setPopoverVisible(false));

  const chatAttachmentClasses = cn("chat__attachment", {
    "chat__attachment_space-right": !isEmpty(chatStickers),
  });

  return (
    <ActionCableProvider url={`${API_WS_ROOT}?token=${token}`}>
      <div className="chat">
        <ActionCableConsumer ref={chatChannel} channel="ChatChannel" onReceived={onMessagesRecieved} />

        {showChatButtons ? <div className="chat__backdrop"></div> : ""}

        <div className="chat__buttons">
          <Button className={`chat__btn${unreadMessages && !showChatButtons ? " chat__btn_unread" : ""}`} size="small" onClick={(e) => toggleChatButtons(!showChatButtons)}>
            {showChatButtons ? <CloseIcon /> : <ChatIcon />}
            {unreadMessages && !showChatButtons ? <div className="chat__btn-unread">{unreadMessages}</div> : null}
          </Button>

          <Button className={`chat__btn chat__btn_chat${showChatButtons ? "" : " chat__btn_hidden"}${unreadMessages ? " chat__btn_unread" : ""}`} size="small" onClick={toggleCollapsed}>
            <MessageIcon />
            {unreadMessages ? <div className="chat__btn-unread">{unreadMessages}</div> : null}
            <div className="chat__btn-hint" data-key="chat.write_btn">
              {t("chat.write_btn")}
            </div>
          </Button>

          <ChatBtnConsult isShowButtons={showChatButtons} />
        </div>

        <div className="chat__wrapper">
          <div className="chat__inner">
            <div className="chat__header">
              <div className="chat__header-top">
                {!searchOpen ? (
                  <div className="chat__title" data-key="chat.title">
                    {t("chat.title")}
                  </div>
                ) : (
                  <>
                    <div className="chat__header-icon chat__header-icon-arrow" onClick={toCloseSearch}>
                      <ArrowFullIcon />
                    </div>
                    <Form colon={false} onFinish={onFinishSearch} autoComplete="off" name="chat-search" form={formSearch} className="form chat__search">
                      <Form.Item
                        name="chatSearch"
                        data-key="chat.search_placeholder"
                        rules={[
                          {
                            required: true,
                          },
                        ]}
                        validateTrigger="onSubmit"
                      >
                        <Search
                          placeholder={t("chat.search_placeholder")}
                          className="form__search-field chat__search-field"
                          allowClear
                          onSearch={onSearch}
                          loading={false}
                          enterButton={<SearchIcon />}
                          ref={inputSearchRef}
                        />
                      </Form.Item>
                    </Form>
                  </>
                )}
                <div className="chat__header-icons">
                  {!searchOpen && (
                    <div className="chat__header-icon chat__header-icon-search" onClick={toggleSearch}>
                      <SearchIcon />
                    </div>
                  )}
                  <div className="chat__header-icon" onClick={toggleCollapsed}>
                    <CrossIcon />
                  </div>
                </div>
              </div>

              <div className="chat__consultant">
                <div className="chat__consultant-avatar">
                  <Badge status={consultIsOnline ? "success" : "error"} dot>
                    <Avatar size={70} icon={<ProfileUserIcon />} src={user["consultant-avatar-url"]} />
                  </Badge>
                </div>
                <h3 className="chat__consultant-name">{user["consultant-name"]}</h3>
                <span className="chat__consultant-position">
                  {isConsultTyping ? (
                    <>
                      {t("chat.consultant_typing")}
                      <span></span>
                      <span></span>
                      <span></span>
                    </>
                  ) : (
                    user["consultant-position"]
                  )}
                </span>
              </div>
            </div>
            <div className="chat__content-wrap">
              <CustomScroll keepAtBottom={true} heightRelativeToParent="100%" onScroll={loadMoreMessages}>
                <div className="chat__content" ref={contentRef}>
                  {chatHistoryMoreAreLoading && (
                    <div className={`chat__spin-wrap${!scrollIsUp ? " chat__spin-wrap_bottom" : ""}`}>
                      <Spin size="small" />
                    </div>
                  )}
                  {historyLoading ? (
                    <div className="chat__spin-wrap chat__spin-wrap_center">
                      <Spin size="small" />
                    </div>
                  ) : (
                    history.map((item, key) => {
                      return (
                        <div
                          className={`chat-message chat__message${item.margin ? " chat__message_group" : ""}${
                            item.attributes.own ? (item.attributes.read ? " chat__message_own" : " chat__message_own chat__message_unread") : ""
                          }${item.id === searchCurrentMessage.id ? " chat__message_found js-chat-message-found" : ""}`}
                          key={key}
                        >
                          {item.attributes.service && (
                            <div className="chat-message__name" data-key="chat.auto_message_title">
                              {t("chat.auto_message_title")}
                            </div>
                          )}
                          <div className="chat-message__body">
                            {item.attributes.content ? (
                              item.attributes.content.startsWith(CHAT_STICKERS_PREFIX) && !isEmpty(chatStickers) && chatStickers[item.attributes.content] !== undefined ? (
                                <div className="chat-message__sticker">
                                  <img src={chatStickers[item.attributes.content]} alt={item.attributes.content} />
                                </div>
                              ) : (
                                <Linker text={item.id === searchCurrentMessage.id ? searchCurrentMessage.content : item.attributes.content} />
                              )
                            ) : null}
                            {item.attributes["file-url"] ? (
                              <span className="chat-message__attachment" onClick={(e) => handleView(e, item.id)}>
                                {item.attributes["file-name"]}
                              </span>
                            ) : null}
                          </div>
                          <div className="chat-message__date" data-key="chat.date_at">
                            <DayJS format={DATE_FORMAT}>{item.attributes["created-at"]}</DayJS>
                            &nbsp;{t("chat.date_at")}&nbsp;
                            <DayJS format={TIME_FORMAT}>{item.attributes["created-at"]}</DayJS>
                          </div>
                        </div>
                      );
                    })
                  )}
                  {isEmpty(sending) ? null : (
                    <div className="chat__message chat__message_own chat__message_sending">
                      <p>{nl2br(sending.content)}</p>
                    </div>
                  )}
                  {uploading ? (
                    <div className="chat__message chat__message_uploading">
                      <Spin size="small" />
                    </div>
                  ) : null}
                </div>
              </CustomScroll>
            </div>

            <div className="chat__footer">
              {!searchOpen ? (
                <Form onFinish={onFinish} colon={false} autoComplete="off" form={form} className={chatFormClasses}>
                  <Form.Item
                    name="message"
                    className="form__item"
                    rules={[
                      {
                        required: true,
                        message: t("chat.enter_message"),
                        whitespace: true,
                      },
                    ]}
                    validateTrigger="onSubmit"
                  >
                    <TextArea
                      ref={textAreaField}
                      autoSize={{ minRows: 1, maxRows: 5 }}
                      placeholder={t("chat.enter_message")}
                      onChange={checkMessageLength}
                      onKeyDown={handleMessageKeyPress}
                      data-key="chat.enter_message"
                    />
                  </Form.Item>

                  {!isEmpty(chatStickers) && (
                    <Popover content={contentStickers} visible={popoverVisible} onVisibleChange={handlePopoverVisible}>
                      <Button className="chat__stickers-btn" size="small" type="button">
                        <SmileIcon />
                      </Button>
                    </Popover>
                  )}

                  {uploadDisplay && (
                    <Upload className={chatAttachmentClasses} {...uploadProps}>
                      <ClipIcon />
                    </Upload>
                  )}

                  <Button size="small" type="submit" disabled={uploading}>
                    <ArrowFullIcon />
                  </Button>
                </Form>
              ) : (
                <div className="chat__search-result">
                  {chatSearchResult.length > 0 ? (
                    <div>
                      {searchCurrentMessageNum + 1} {t("chat.search_result.from")} {chatSearchResult.length}
                    </div>
                  ) : (
                    isSearched && t("chat.search_result.not_found")
                  )}

                  <div className="chat__search-result-icons">
                    <div className={chatSearchResultIconNext} onClick={toNextSearchResult}>
                      <ArrowIcon />
                    </div>
                    <div className={chatSearchResultIconPrev} onClick={toPrevSearchResult}>
                      <ArrowIcon />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </ActionCableProvider>
  );
}

export default Chat;
