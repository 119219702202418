import i18n from "i18next";
import axios from "axios";

import { initReactI18next } from "react-i18next";
import ChainedBackend from "i18next-chained-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import { API_URL, DOMAIN } from "./config/config";

const loadResources = async (lng) => {
  return await axios
    .get(`${API_URL}translations?source=siil&locale=${lng}`)
    .then((res) => {
      return JSON.stringify(res.data);
    })
    .catch((error) => {
      console.log(error);
    });
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .use(ChainedBackend)
  .init({
    // debug: true,
    supportedLngs: ["en", "ru", "fr"],
    preload: ["en", "ru"],
    fallbackLng: "en",
    keySeparator: false,
    partialBundledLanguages: true,
    detection: {
      order: ["cookie", "localStorage", "sessionStorage"],
      caches: ["cookie"],
      cookieDomain: DOMAIN,
      cookieMinutes: 60 * 24 * 365,
    },
    backend: {
      backends: [HttpApi, resourcesToBackend((lng, ns) => import(`../public/locales/${lng}/common.json`))],
      backendOptions: [
        {
          loadPath: "{{lng}}",
          request: (options, url, payload, callback) => {
            try {
              loadResources(url).then((response) => {
                callback(null, {
                  data: response,
                  status: 200,
                });
              });
            } catch (e) {
              console.log(e, "error from language");
              callback(null, {
                status: 500,
              });
            }
          },
        },
      ],
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
      format: (value, format, lng) => {
        if (format === "intlDate") {
          return new Intl.DateTimeFormat(lng, {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "2-digit",
            minute: "2-digit",
            timeZoneName: "short",
          }).format(new Date(value));
        }

        return value;
      },
    },
  });

export default i18n;
