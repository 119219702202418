import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './reducers';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [
    thunk,
    routerMiddleware(history)
];

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers
);

export default function configureStore(initialState) {
    const store = createStore(
        rootReducer(history), // root reducer with router state
        initialState,
        composedEnhancers
    );

    return store;
}