import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate } from "react-router-dom";

import { removeToken, removeParam } from "../../actions/global";
import { userActivationStatus, fetchUserSessionSuccess, fetchUserConsultSuccess, userSessionLoaded } from "../../actions/users";
import { userRegistrationSuccess } from "../../actions/auth";

const SignOut = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(fetchUserSessionSuccess({}));
    dispatch(fetchUserConsultSuccess({}));
    dispatch(userActivationStatus(false));
    dispatch(userRegistrationSuccess(false));
    dispatch(userSessionLoaded(false));

    return () => {
      removeToken();
      removeParam("refid");
    };
  }, [dispatch]);

  return <Navigate to="/sign_in" replace={true} />;
};

export default SignOut;
