import {FIELDS_NAMES, FILE_MAX_SIZE} from "../constants/global";

export function documentUrl(url, target=true) {
    const link = document.createElement('a');
    link.href = url;

    if(target) {
        link.target = "_blank";
    }

    document.body.appendChild(link);
    link.click();
}

export function collectErrors(errors) {
    let result = [];
    for (let key in errors) {
        result.push({key: FIELDS_NAMES[key] ? FIELDS_NAMES[key] : null, errors: errors[key]});
    }

    return result;
}

export function checkFilesSize(files) {
    let size = 0;

    files.forEach(item => size += item.size);

    return checkFileSize(size);
}

export function checkFileSize(size) {
    return size <= FILE_MAX_SIZE;
}
