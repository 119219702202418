import React, { useState } from "react";
import { VideoIcon, ArrowIcon } from "../utils/svg";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { formatDate, formatTime } from "../utils/format-date";

const ArchiveItem = ({ name, place, username, start_at, end_at, extra }) => {
  const { t } = useTranslation();
  const [isToggled, setToggled] = useState(false);

  const handleQuestionToggle = () => {
    setToggled(!isToggled);
  };

  let dateStart = formatDate("ru", start_at);

  return (
    <div className={`collapse__item archive__item${isToggled ? " collapse__item_open" : ""}`}>
      <div className="collapse__header" onClick={handleQuestionToggle}>
        <div className="collapse__header-icon">
          <ArrowIcon />
        </div>
        <div className="collapse__header-text">
          <div className="archive__item-header">
            <div className="archive__item-cell">{dateStart}</div>
            <div className="archive__item-cell">{name}</div>
            {extra && (
              <div className="archive__item-video-icon">
                <VideoIcon />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="collapse__content">
        <div className="archive__item-content">
          <div className="archive__item-cell archive__item-cell_hidden-m">
            <div className="archive__item-title" data-key="pages.archive.item.date">
              {t("pages.archive.item.date")}
            </div>
            {dateStart}
          </div>

          <div className="archive__item-cell">
            <div className="archive__item-title" data-key="pages.archive.item.time">
              {t("pages.archive.item.time")}
            </div>
            {formatTime("ru", start_at)} - {formatTime("ru", end_at)}
          </div>

          <div className="archive__item-cell archive__item-cell_hidden-m">
            <div className="archive__item-title" data-key="pages.archive.item.event">
              {t("pages.archive.item.event")}
            </div>
            {name}
          </div>

          <div className="archive__item-cell">
            <div className="archive__item-title" data-key="pages.archive.item.responsible">
              {t("pages.archive.item.responsible")}
            </div>
            {username}
          </div>

          <div className="archive__item-cell">
            <div className="archive__item-title" data-key="pages.archive.item.location">
              {t("pages.archive.item.location")}
            </div>
            {place}
          </div>

          {extra && (
            <div className="archive__item-video-wrap">
              <Link className="btn btn_primary archive__item-video-btn" to={`/webinars/${extra}`}>
                {t("pages.archive.item.webinar")}
              </Link>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ArchiveItem;
