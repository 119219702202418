import { List } from "@vshp/ui-components";
import { DocumentIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";

function PlanDocuments(props) {
  const { materials } = props;
  const { t } = useTranslation();

  let icon = <DocumentIcon />;

  return (
    <List headerText={t("plan.documents_title")} data-key="plan.documents_title">
      {materials.map((item, key) => (
        <List.Item
          className="plan__doc-link"
          isLinkOut={true}
          key={key}
          link={item.url}
          title={item.title}
          icon={icon}
          target="_blank"
          rel="noopener noreferrer"
        />
      ))}
    </List>
  );
}

export default PlanDocuments;
