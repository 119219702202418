import { Title, Section } from "@vshp/ui-components";
import { Image } from "antd";
import { useTranslation } from "react-i18next";
import { COURSE_DEGREE_ID } from "../../constants/global";

const ProgramCertificates = ({ title, certificates, degree, border }) => {
  const { t } = useTranslation();

  return (
    <Section border={border}>
      <Title type="h2" data-key="program.certificate_examples.title">
        {t("program.certificate_examples.title")}
      </Title>
      <div className="wysiwyg">
        {degree === COURSE_DEGREE_ID ? (
          <p data-key="program.course.certificate_examples.description">{t("program.course.certificate_examples.description", { program: `${title}` })}</p>
        ) : (
          <p data-key="program.certificate_examples.description">{t("program.certificate_examples.description", { program: `${title}` })}</p>
        )}
      </div>
      <div className="photos">
        <Image.PreviewGroup>
          {certificates.map((item, key) => (
            <Image
              key={key}
              src={item.preview}
              preview={{
                src: item.origin,
              }}
            />
          ))}
        </Image.PreviewGroup>
      </div>
    </Section>
  );
};

export default ProgramCertificates;
