import { useEffect } from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import "../actions/axios";
import PrivateRoute from "./auth/route";
import { setRefId, setGetParam, getGetParam } from "../actions/global";
import { fetchUserInfo } from "../actions/users";
import i18next from "i18next";

import ScrollToTop from "./utils/scrollToTop";
import Activation from "./auth/activation";
import SignIn from "./auth/sign_in";
import SignOut from "./auth/sign_out";
import Recovery from "./auth/recovery";
import RecoveryPassword from "./auth/recovery-form";
import Register from "./auth/register";
import Profile from "./profile";
import ProgramsList from "./programs/list";
import ProgramPage from "./programs/page";
import Plan from "./plan";
import PlanSemester from "./plan/plan";
import PlanTest from "./plan/plan-test";
import PlanTheme from "./plan/plan-theme";
import PlanPayments from "./plan/plan-payments";
import WebinarPage from "./webinars/WebinarPage";
import AccountDelete from "./auth/AccountDelete";
import PageNotFound from "./not-found";
import Archive from "./archive/Archive";

export default function Main() {
  const dispatch = useDispatch();
  const location = useLocation();
  let currentLanguage = i18next.language;

  useEffect(() => {
    dispatch(fetchUserInfo(currentLanguage));
  }, [dispatch, currentLanguage]);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("refid");
    const refP = params.get("refp");
    const refPr = params.get("refpr");

    if (token) {
      setRefId(token);
    }

    if (refP) {
      setGetParam("refp", refP);
    }

    if (refPr) {
      setGetParam("refpr", refPr);
    }
  }, [location]);

  useEffect(() => {
    if (getGetParam("referer") === null || location.search.indexOf("utm_") > -1) {
      setGetParam("referer", document.referrer + window.location.search);
    }
  }, []);

  return (
    <div className="app__wrapper">
      <ScrollToTop>

        <Routes>
          <Route
            path="/"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route exact path="r/:slug" element={<PrivateRoute><Profile /></PrivateRoute>} />

          <Route exact path="/sign_in" element={<PrivateRoute redirect={false}><SignIn /></PrivateRoute>} />
          <Route exact path="/recovery" element={<PrivateRoute redirect={false}><Recovery /></PrivateRoute>} />
          <Route exact path="/recovery/password" element={<PrivateRoute redirect={false}><RecoveryPassword /></PrivateRoute>} />
          <Route exact path="/register" element={<PrivateRoute redirect={false}><Register /></PrivateRoute>} />
          <Route exact path="/register/:slug" element={<PrivateRoute redirect={false}><Register /></PrivateRoute>} />
          <Route exact path="/activation" element={<Activation />} />

          <Route
            path="profile"
            element={
              <PrivateRoute>
                <Profile />
              </PrivateRoute>
            }
          />

          <Route
            path="sign_out"
            element={
              <PrivateRoute>
                <SignOut />
              </PrivateRoute>
            }
          />

          <Route
            path="archive"
            element={
              <PrivateRoute>
                <Archive />
              </PrivateRoute>
            }
          />

          <Route
            path="programs"
            element={
              <PrivateRoute>
                <ProgramsList />
              </PrivateRoute>
            }
          >
            <Route path=":slug" element={<ProgramsList />} />
          </Route>

          <Route
            path="programs/:slug/:id"
            element={
              <PrivateRoute>
                <ProgramPage />
              </PrivateRoute>
            }
          />

          <Route
            path="plans"
            element={
              <PrivateRoute>
                <Plan />
              </PrivateRoute>
            }
          >
            <Route path=":slug" element={<PlanSemester />} />
            <Route exact path=":slug/payments" element={<PlanPayments />} />
            <Route exact path=":slug/payments/success" element={<PageNotFound reason="page.payments.success.title" details="page.payments.success.details" error="" goBackPayments={true}/>} />
            <Route exact path=":slug/payments/failed" element={<PageNotFound reason="page.payments.failed.title" details="page.payments.failed.details" error="" goBackPayments={true}/>} />
            <Route exact path=":slug/:semesterNumber/:themeSlug" element={<PlanTheme />} />
            <Route exact path=":slug/:semesterNumber/:themeSlug/:themeNumber" element={<PlanTheme />} />
            <Route exact path=":slug/:semesterNumber/:themeSlug/test/:testQuestion" element={<PlanTest />} />
          </Route>

          <Route
            path="webinars/:external_id"
            element={
              <PrivateRoute>
                <WebinarPage />
              </PrivateRoute>
            }
          />

          <Route path="account_delete" element={<AccountDelete />} />

          <Route path="*" element={<PageNotFound needLayout={true} />} />
        </Routes>
      </ScrollToTop>
    </div>
  );
}
