export const PROGRAMS_DEGREES = "PROGRAMS_DEGREES";
export const PROGRAMS_DEGREES_LOADING = "PROGRAMS_DEGREES_LOADING";

export const PROGRAMS_FORMS = "PROGRAMS_FORMS";
export const PROGRAMS_FORMS_LOADING = "PROGRAMS_FORMS_LOADING";

export const PROGRAMS_CATEGORIES = "PROGRAMS_CATEGORIES";
export const PROGRAMS_CATEGORIES_LOADING = "PROGRAMS_CATEGORIES_LOADING";

export const PROGRAM = "PROGRAM";
export const PROGRAM_LOADING = "PROGRAM_LOADING";
export const PROGRAM_LOADED = "PROGRAM_LOADED";

export const PROGRAMS = "PROGRAMS";
export const PROGRAMS_ARE_LOADING = "PROGRAMS_ARE_LOADING";

export const PROGRAMS_POPULAR = "PROGRAMS_POPULAR";
export const PROGRAMS_POPULAR_LOADING = "PROGRAMS_POPULAR_LOADING";
