import { URL } from "../../config/config";

function PartnerItem({ partners, locale }) {
  return (
    <div className="partners">
      {partners.length > 0 &&
        partners.map((partner, key) => (
          <a className="partners__item" href={`${URL}/partners/${partner.attributes.slug}`} key={key}>
            <img src={partner.attributes.logo} alt={partner.attributes.title} />
          </a>
        ))}
    </div>
  );
}

export default PartnerItem;
