import { Link } from 'react-router-dom';
import { Title } from "@vshp/ui-components";

import MainLayout from "../../layout";
import { useTranslation } from "react-i18next";


function RecoverySuccess(props) {
    const { t } = useTranslation();

    return (
        <MainLayout chat={false} title={{ caption: t("ui.head.recovery_title_success") }}>
            <section className="page__auth">
                <div className="form form_auth">
                    <Title decor={false} className="form__title" data-key="auth.instructions_sent_title">{t("auth.instructions_sent_title")}</Title>
                    <div className="form__note" data-key="auth.instructions_sent_text">{t("auth.instructions_sent_text")}</div>
                    <Link className="btn btn_primary btn_max-width-sm" to="/sign_in" data-key="auth.login_again">{t("auth.login_again")}</Link>
                    <Link className="btn btn_primary btn_max-width-sm" to="/" data-key="ui.to_home_page">{t("ui.to_home_page")}</Link>
                </div>
            </section>
        </MainLayout>
    );
}

export default RecoverySuccess;
