import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Avatar, Form, Input, Rate, Spin } from "antd";
import { fetchWorksData, sendRatingWorks, removeWork } from "../../actions/works";
import { Loading } from "../utils/loading";
import { Title, Button, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";
import { DocumentIcon, ProfileUserIcon, ArrowIcon, loadingIcon, EmptyIcon } from "../utils/svg";

function ProfileWorks() {
  const dispatch = useDispatch();
  const rate = useRef(null);
  const { worksData, worksDataLoading, ratingWorksIsSending } = useSelector((state) => state.works);
  const [form] = Form.useForm();
  const [currentWorkNumber, setCurrentWorkNumber] = useState(1);
  const { t } = useTranslation();
  const { TextArea } = Input;
  let locale = i18next.language;

  useEffect(() => {
    dispatch(fetchWorksData(locale));
  }, [dispatch, locale]);

  const onFinish = (values) => {
    worksData.length === currentWorkNumber && worksData.length !== 1 && setCurrentWorkNumber(currentWorkNumber - 1);

    let formValues = { ...values, grading: worksData[currentWorkId].attributes.grades[values.grading - 1] };
    dispatch(sendRatingWorks(worksData[currentWorkId].id, formValues));
    dispatch(removeWork(worksData[currentWorkId].id));
    form.resetFields();
  };

  const handleClickPrev = () => {
    currentWorkNumber === 1 ? setCurrentWorkNumber(worksData.length) : setCurrentWorkNumber(currentWorkNumber - 1);
    form.resetFields();
  };

  const handleClickNext = () => {
    worksData.length === currentWorkNumber ? setCurrentWorkNumber(1) : setCurrentWorkNumber(currentWorkNumber + 1);
    form.resetFields();
  };

  let currentWorkId = currentWorkNumber - 1;

  const setActiveRate = (el) => {
    Array.from(rate.current.rate.querySelectorAll(".works__rate-item")).map((item) => item.classList.remove("active"));
    el.target.classList.add("active");
  };

  const handleOnClickRate = (e) => {
    e.target.matches(".active") ? e.target.classList.remove("active") : setActiveRate(e);
  };

  return (
    <Section>
      <Title type="h2" data-key="profile.works_title">{t("profile.works_title")}</Title>
      {worksDataLoading ? (
        <Loading />
      ) : (
        <div className="works">
          {worksData.length > 0 ? (
            <>
              <div className="works__item">
                <div className="works__item-photo">
                  <Avatar className="" size={100} icon={<ProfileUserIcon />} src={worksData[currentWorkId].attributes["user-photo"].profile} />
                </div>
                <div className="works__item-name text-center">{worksData[currentWorkId].attributes["user-name"]}</div>
                <div className="works__item-status text-center" data-key="homework.status">
                  {t("homework.status")}
                  {` «${worksData[currentWorkId].attributes.name}»`}
                </div>

                <hr className="works__item-divider"></hr>

                {worksData[currentWorkId].attributes.files.map(({ name, url }, key) => (
                  <div className="works__item-link-wrap" key={key}>
                    <div className="works__item-link-icon">
                      <DocumentIcon />
                    </div>
                    <div className="works__item-link-col">
                      <a href={url} target="_blank" className="works__item-link" rel="noopener noreferrer">
                        {name}
                      </a>
                      <div className="works__item-link-subtitle" data-key="homework.uploaded">{t("homework.uploaded", { date: worksData[currentWorkId].attributes["files-uploaded-at"] })}</div>
                    </div>
                  </div>
                ))}

                <Form className="form" form={form} name="works" onFinish={onFinish} autoComplete="off">
                  <Form.Item className="works__feedback-wrap" data-key="homework.enter_feedback" name="comment" rules={[{ required: true, message: t("homework.enter_feedback") }]}>
                    <TextArea className="works__feedback" data-key="homework.feedback_placeholder" placeholder={t("homework.feedback_placeholder")} rows={4} autoSize={{ minRows: 4, maxRows: 4 }} />
                  </Form.Item>

                  <Form.Item
                    className="works__rate-wrap text-center"
                    name="grading"
                    rules={[
                      { required: true, message: t("homework.enter_rate") },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (value) {
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error(t("homework.enter_rate")));
                        },
                      }),
                    ]}
                    data-key="homework.enter_rate, homework.enter_rate"
                  >
                    <Rate
                      ref={rate}
                      className="works__rate"
                      count={worksData[currentWorkId].attributes.grades.length}
                      character={({ index }) => (
                        <div className="works__rate-item" onClick={handleOnClickRate}>
                          {worksData[currentWorkId].attributes.grades[index]}
                        </div>
                      )}
                    />
                  </Form.Item>

                  <Button className="works__btn" type="submit" disabled={ratingWorksIsSending} data-key="homework.rate_btn">
                    {ratingWorksIsSending ? <Spin indicator={loadingIcon} /> : t("homework.rate_btn")}
                  </Button>
                </Form>

                {worksData.length !== 1 && <hr className="works__item-divider" />}
              </div>

              {worksData.length !== 1 && (
                <div className="works__nav">
                  <Button className="slider-arrow slider-prev works__nav-prev" size="small" corners="square" icon={true} onClick={handleClickPrev}>
                    <ArrowIcon />
                  </Button>

                  <div className="works__count text-center">
                    {currentWorkNumber}/{worksData.length}
                  </div>

                  <Button className="slider-arrow slider-next works__nav-next" size="small" corners="square" icon={true} onClick={handleClickNext}>
                    <ArrowIcon />
                  </Button>
                </div>
              )}
            </>
          ) : (
            <div className="empty__wrap" data-key="ui.no_data">
              <EmptyIcon />
              {t("ui.no_data")}
            </div>
          )}
        </div>
      )}
    </Section>
  );
}

export default ProfileWorks;
