import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DayJS from "react-dayjs";
import { fetchPlanPaymentsBySlug } from "../../actions/plan";
import { DATE_FORMAT } from "../../constants/global";
import MainLayout from "../layout";
import PlanPaymentsForm from "./plan-payments-form";
import { Loading } from "../utils/loading";
import { BudgetUpIcon, BudgetBottomIcon } from "../utils/svg";
import PlanListEmpty from "./plan-list-empty";
import { Title, List, Button } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import i18next from "i18next";

function PlanPayments() {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const { plan, planPayments, planPaymentsIsLoading } = useSelector((state) => state.plan);
  const { t } = useTranslation();

  let locale = i18next.language;

  useEffect(() => {
    dispatch(fetchPlanPaymentsBySlug(slug, locale));
  }, [dispatch, slug, locale]);

  const title = {
    caption: `${t("plan.payment_and_schedule")}. ${plan.attributes.course.title}`,
  };

  const handleScroll = () => {
    const currentEl = document.getElementById("scroll");
    currentEl.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <MainLayout title={title}>
      <div className="page__header page__header_short">
        <img className="page__header-cover" src={plan.attributes.photo.cover} alt="" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="plan.payment_and_schedule">
            {t("plan.payment_and_schedule")}
          </Title>
          <h2 className="page__header-subtitle t-h2">{plan.attributes.course.title}</h2>
          {!plan.attributes.free && (
            <Button className="page__header-btn" onClick={handleScroll} data-key="plan.payments_pay">
              {t("plan.payments_pay")}
            </Button>
          )}
        </div>
      </div>
      <div className="page__blocks">
        <div className="page__block">
          {planPaymentsIsLoading ? (
            <Loading />
          ) : (
            <List isLarge={true} noBorderLast={true} noSpaceAround={true}>
              {planPayments.length ? (
                planPayments.map((item, key) => (
                  <List.Item
                    className={item.attributes.amount <= 0 ? "payments-list__item_debt" : ""}
                    key={key}
                    icon={item.attributes.amount <= 0 ? <BudgetBottomIcon /> : <BudgetUpIcon />}
                    iconClass="payments-list__item-icon"
                    title={item.attributes.description}
                    titleClass="payments-list__item-title"
                    mark={`${new Intl.NumberFormat("ru-RU", { minimumFractionDigits: 2 }).format(item.attributes.amount)} ${item.attributes.currency}`}
                    markClass="payments-list__item-amount"
                  >
                    <div className="payments-list__item-date">
                      <DayJS format={DATE_FORMAT}>{item.attributes.at}</DayJS>
                    </div>
                  </List.Item>
                ))
              ) : (
                <PlanListEmpty />
              )}
            </List>
          )}
        </div>

        {planPaymentsIsLoading ? null : !plan.attributes.free && <PlanPaymentsForm plan={plan} />}
      </div>
    </MainLayout>
  );
}

export default PlanPayments;
