import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";

import { settingsReducers } from "./settings";
import { authReducers } from "./auth";
import { chatReducers } from "./chat";
import { globalReducers } from "./global";
import { plansReducers } from "./plan";
import { programsReducers } from "./programs";
import { webinarsReducers } from "./webinars";
import { usersReducers } from "./users";
import { worksReducers } from "./works";
import { partnersReducers } from "./partners";
import { scheduleReducers } from "./schedule";
import { archiveReducers } from "./archive";

export default (history) =>
  combineReducers({
    router: connectRouter(history),
    settings: settingsReducers(),
    auth: authReducers(),
    chat: chatReducers(),
    global: globalReducers(),
    plan: plansReducers(),
    programs: programsReducers(),
    webinars: webinarsReducers(),
    users: usersReducers(),
    works: worksReducers(),
    partners: partnersReducers(),
    schedule: scheduleReducers(),
    archive: archiveReducers(),
  });
