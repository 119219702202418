let API_URL       = "https://my.siil.ch/api/v2/";
let REACT_APP_ENV = process.env.REACT_APP_MODE || "development";
let API_WS_ROOT   = "wss://my.siil.ch/cable";
let DOMAIN        = ".siil.ch";
let URL           = "https://siil.ch";
let RETURN_URL    = "https://my.siil.ch/sign_in";

if (REACT_APP_ENV === "staging") {
  API_URL         = "https://my-siil-ch.vshp.tech/api/v2/";
  API_WS_ROOT     = "wss://my-siil-ch.vshp.tech/cable";
  DOMAIN          = "my-siil-ch.vshp.tech";
  URL             = "https://siil-ch.vshp.tech";
  RETURN_URL      = "https://my-siil-ch.vshp.tech/sign_in";

} else if (REACT_APP_ENV === "development") {
  API_URL         = "https://my-siil-ch.vshp.tech/api/v2/";
  API_WS_ROOT     = "wss://my-siil-ch.vshp.tech/cable";
  DOMAIN          = "localhost";
  URL             = "https://siil-ch.vshp.tech";
  RETURN_URL      = "https://my-siil-ch.vshp.tech/sign_in";
}

console.log("App environment is set to " + REACT_APP_ENV);

export { API_URL, REACT_APP_ENV, API_WS_ROOT, DOMAIN, URL, RETURN_URL };
