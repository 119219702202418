import { combineReducers } from "redux";
import { RESPONSE_ERRORS, RESPONSE_SUCCESS, RESPONSE_ERROR_SERVER } from "../constants/global";

export function responseErrors(state = [], action) {
  switch (action.type) {
    case RESPONSE_ERRORS:
      return action.errors;
    default:
      return state;
  }
}

export function responseSuccess(state = [], action) {
  switch (action.type) {
    case RESPONSE_SUCCESS:
      return action.success;
    default:
      return state;
  }
}

export function responseErrorServer(state = false, action) {
  switch (action.type) {
    case RESPONSE_ERROR_SERVER:
      return action.bool;
    default:
      return state;
  }
}

export const globalReducers = () =>
  combineReducers({
    responseErrors: responseErrors,
    responseSuccess: responseSuccess,
    responseErrorServer: responseErrorServer,
  });
