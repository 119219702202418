import { useTranslation } from "react-i18next";
import FailedQuestion from "./FailedQuestion";

const PlanTestFailed = (props) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="test__content test__content_center">
        <h3 className="test__title" data-key="test.result_title">
          {t("test.result_title")}
        </h3>
        <h3 className="test__subtitle test__subtitle_failed" data-key="test.failed">
          {t("test.failed")}
        </h3>
        <div className="test__note wysiwyg">
          <p data-key="test.failed_note" dangerouslySetInnerHTML={{ __html: t("test.failed_note") }}></p>
          <p>
            <span data-key="test.failed.passed.count" dangerouslySetInnerHTML={{ __html: t("test.failed.passed.count", { count: props.info.answered_questions }) }}></span>{" "}
            <strong>{props.testLength}</strong>.
            <br />
            <span data-key="test.min.count" dangerouslySetInnerHTML={{ __html: t("test.min.count", { count: props.info.number_to_pass }) }}></span>.
          </p>
        </div>

        <FailedQuestion info={props.info} />
      </div>
    </div>
  );
};

export default PlanTestFailed;
