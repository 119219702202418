import {useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";

import {fetchUserDocuments} from "../../actions/users";

import { PassportIcon, CertificateIcon, DiplomaIcon } from "../utils/svg";
import {Loading} from "../utils/loading";
import { Title, Section } from "@vshp/ui-components";

import ProfileDocument from "./document";
import { useTranslation } from "react-i18next";


function ProfileDocuments({ border }) {
    const {documents, documentsAreLoading} = useSelector(state => state.users);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const DOCUMENT_TYPES = [
        {
            "id": 1,
            "type": "passport",
            "name": t("profile.documents_passport")
        },
        {
            "id": 2,
            "type": "certificate",
            "name": t("profile.documents_certificate")
        },
        {
            "id": 3,
            "type": "diploma",
            "name": t("profile.documents_diploma")
        }
    ];

    useEffect(() => {
        dispatch(fetchUserDocuments());
    }, [dispatch]);

    return (
        <Section border={border}>
            <Title type="h2" data-key="profile.documents_title">{t("profile.documents_title")}</Title>

            {documentsAreLoading ? <Loading/>:(
            <div className="documents" data-key="profile.documents_passport, profile.documents_certificate, profile.documents_diploma">
                {DOCUMENT_TYPES.map((item, key) => {
                    let icon = null;
                    const document = documents.find(doc => doc["attributes"]["category-id"] === item.id);

                    switch (item.type) {
                        case "passport":
                            icon = <PassportIcon/>;
                            break;
                        case "certificate":
                            icon = <CertificateIcon/>;
                            break;
                        case "diploma":
                            icon = <DiplomaIcon/>;
                            break;
                        default:
                            break;
                    }

                    return <ProfileDocument item={item} icon={icon} document={document} key={key}/>
                })}
            </div>)}
        </Section>
    );
}

export default ProfileDocuments;
