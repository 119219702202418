import { useTranslation } from "react-i18next";
import FailedQuestion from "./FailedQuestion";

const PlanTestCompleted = ({ grade, answers, testLength, info }) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="test__content test__content_center">
        <h3 className="test__title" data-key="test.result_title">
          {t("test.result_title")}
        </h3>
        <h3 className="test__subtitle test__subtitle_success" data-key="test.passed">
          {t("test.passed")}
        </h3>
        <div className="test__note">
          <p data-key="test.passed_grade">
            {t("test.passed_grade")} — {grade}
          </p>
          <p>
            <span data-key="test.passed.count" dangerouslySetInnerHTML={{ __html: t("test.passed.count", { count: answers }) }}></span> <strong>{testLength}</strong>.
          </p>
        </div>
        <FailedQuestion info={info} />
      </div>
    </div>
  );
};

export default PlanTestCompleted;
