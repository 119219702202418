import axios from "axios";
import {
  CHAT_LOADING,
  CHAT_HISTORY,
  SENDING_MESSAGE,
  CHAT_ATTACHMENT_UPLOADING,
  CHAT_LOAD_MORE_LINK,
  CHAT_HISTORY_LOAD_MORE_LOADING,
  CHAT_HISTORY_LOAD_MORE,
  CHAT_HISTORY_LOAD_MORE_LOADED,
  CHAT_SEARCH_RESULT_ARE_LOADING,
  CHAT_SEARCH_RESULT,
  CHAT_LOAD_MORE_LINK_NEXT,
  CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS,
  CHAT_STICKERS,
  CHAT_STICKERS_LOADING,
} from "../constants/chat";
import { FILE_MAX_SIZE_ERROR } from "../constants/errors";
import { SOURCE } from "../constants/global";

import i18next from "i18next";

import { errorMessage } from "./global";
import { API_URL } from "../config/config";
import { documentUrl, checkFileSize, collectErrors } from "./helpers";

export function fetchChatHistory(params, locale) {
  return (dispatch) => {
    dispatch(chatHistoryAreLoading(true));
    const config = { params: { locale: locale, ...params } };

    axios
      .get(`${API_URL}user/chat/messages`, config)
      .then((response) => {
        const data = setChatMargins(response.data.data);
        dispatch(chatHistorySuccess(data));
        dispatch(chatHistoryAreLoading(false));
        dispatch(chatLoadMoreLink(response.data.links.prev));
        dispatch(chatLoadMoreLinkNext(response.data.links.next));

        return response;
      })
      .catch((e) => console.log("error loading chat", e));
  };
}

export function fetchChatHistoryLoadMore(url, isNext = false) {
  return (dispatch) => {
    dispatch(chatHistoryLoadMoreAreLoading(true));

    axios
      .get(`${url}`)
      .then((response) => {
        if (isNext) {
          dispatch(chatHistoryLoadMoreNextSuccess(response.data.data));
          dispatch(chatLoadMoreLinkNext(response.data.links.next));
        } else {
          dispatch(chatHistoryLoadMoreSuccess(response.data.data));
          dispatch(chatLoadMoreLink(response.data.links.prev));
        }

        dispatch(chatHistoryLoadMoreAreLoaded(true));
        dispatch(chatHistoryLoadMoreAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading more chat history", e));
  };
}

function chatHistoryLoadMoreAreLoading(bool) {
  return {
    type: CHAT_HISTORY_LOAD_MORE_LOADING,
    bool,
  };
}

export function chatHistoryLoadMoreAreLoaded(bool) {
  return {
    type: CHAT_HISTORY_LOAD_MORE_LOADED,
    bool,
  };
}

function chatHistoryLoadMoreSuccess(data) {
  return {
    type: CHAT_HISTORY_LOAD_MORE,
    data,
  };
}

function chatHistoryLoadMoreNextSuccess(data) {
  return {
    type: CHAT_HISTORY_LOAD_MORE_NEXT_SUCCESS,
    data,
  };
}

export function recieveMessage(message) {
  return (dispatch, getState) => {
    let messages = getState().chat.history;

    messages = [...messages, message.data];
    const messagesUpdated = setChatMargins(messages);

    dispatch(sendingMessage({}));
    dispatch(chatHistorySuccess(messagesUpdated));
  };
}

export function updateStatusMessages() {
  return (dispatch, getState) => {
    let messages = getState().chat.history;

    const updatedMessagesStatus = messages.map((item) => {
      item.attributes.own && !item.attributes.read && (item.attributes.read = true);
      return item;
    });

    const messagesUpdated = setChatMargins(updatedMessagesStatus);
    dispatch(chatHistorySuccess(messagesUpdated));
  };
}

function setChatMargins(data) {
  return data.map((item, key) => {
    return { ...item, margin: key !== data.length - 1 && item.attributes.own !== data[key + 1].attributes.own };
  });
}

function chatHistoryAreLoading(bool) {
  return {
    type: CHAT_LOADING,
    bool,
  };
}

function chatHistorySuccess(data) {
  return {
    type: CHAT_HISTORY,
    data,
  };
}

function chatLoadMoreLink(data) {
  return {
    type: CHAT_LOAD_MORE_LINK,
    data,
  };
}

function chatLoadMoreLinkNext(data) {
  return {
    type: CHAT_LOAD_MORE_LINK_NEXT,
    data,
  };
}

export function sendingMessage(data) {
  return {
    type: SENDING_MESSAGE,
    data,
  };
}

export function fetchChatSearchResult(params, locale) {
  return (dispatch) => {
    dispatch(chatSearchResultAreLoading(true));

    const config = { params: { source: SOURCE, locale: locale, ...params } };

    axios
      .get(`${API_URL}user/chat/search`, config)
      .then((response) => {
        dispatch(chatSearchResultSuccess(response.data));
        dispatch(chatSearchResultAreLoading(false));

        return response;
      })
      .catch((e) => {
        console.log("error loading search result", e);
      });
  };
}

function chatSearchResultAreLoading(bool) {
  return {
    type: CHAT_SEARCH_RESULT_ARE_LOADING,
    bool,
  };
}

export function chatSearchResultSuccess(data) {
  return {
    type: CHAT_SEARCH_RESULT,
    data,
  };
}

export function submitAttachment(file) {
  return (dispatch) => {
    if (!checkFileSize(file.size)) {
      dispatch(errorMessage(collectErrors({ error: [i18next.t(FILE_MAX_SIZE_ERROR)] })));
      return;
    }

    dispatch(attachmentUploading(true));

    const formData = new FormData();
    formData.append("file", file);

    axios
      .post(`${API_URL}user/chat/upload`, formData)
      .then((response) => {
        return response.data;
      })
      .then((response) => {
        dispatch(attachmentUploading(false));
      })
      .catch((error) => console.log("error uploading attachment", error));
  };
}

function attachmentUploading(bool) {
  return {
    type: CHAT_ATTACHMENT_UPLOADING,
    bool,
  };
}

export function viewAttachment(id, locale) {
  return (dispatch) => {
    const config = { params: { locale: locale } };
    axios
      .get(`${API_URL}user/chat/documents/${id}`, config)
      .then((response) => {
        documentUrl(response.data.url);

        return response.data;
      })
      .catch((e) => console.log("error viewing document", e));
  };
}

export function fetchChatStickers() {
  return (dispatch) => {
    dispatch(chatStickersAreLoading(true));

    const config = { params: { source: SOURCE } };

    axios
      .get(`${API_URL}stickers`, config)

      .then((response) => {
        dispatch(chatStickersSuccess(response.data));
        dispatch(chatStickersAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading stickers", e));
  };
}

function chatStickersAreLoading(bool) {
  return {
    type: CHAT_STICKERS_LOADING,
    bool,
  };
}

function chatStickersSuccess(data) {
  return {
    type: CHAT_STICKERS,
    data,
  };
}
