import React from "react";
import NumberFormat from "react-number-format";
import {Input} from "antd";

class InputMask extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isFocus: false,
      value: "",
    };
  }

  componentDidMount() {
    this.setState({
      value: this.props.val
    })
  }

  onFocus () {
    this.setState({
      isFocus: true,
    });
  }

  onBlur () {
    this.state.isFocus && this.setState({isFocus: false});
  }

  onValueChange (val) {
    this.setState({
      value: val.value
    });

    if (val.formattedValue.substring(0, 1) === "+") {
      const firstNumber = val.value.substring(0, 1);

      (val.value.length === 1 && firstNumber === "8") && this.setState({value: ""});
    }
  }


  render() {
    return (
      <span className="ant-input-affix-wrapper">
        <span className="ant-input-prefix">{this.props.icon}</span>

        <NumberFormat
          {...this.props}
          customInput={Input}
          isNumericString="true"

          onFocus={(e) => {this.onFocus()}}
          onBlur={(e) => {this.onBlur()}}
          allowEmptyFormatting={this.state.isFocus}

          value={this.state.value}
          onValueChange={(values) => {this.onValueChange(values)}}
        />
      </span>
    );
  }
}

export default InputMask;
