import { EmptyIcon } from "../utils/svg";
import { useTranslation } from "react-i18next";

function PlanListEmpty(props) {
  const { t } = useTranslation();

  return (
    <li className="empty__wrap" data-key="ui.no_data">
      <EmptyIcon />
      {t("ui.no_data")}
    </li>
  );
}

export default PlanListEmpty;
