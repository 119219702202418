import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import MainLayout from "../layout";
import { Title, Section } from "@vshp/ui-components";
import { useTranslation } from "react-i18next";
import profileBg from "../../images/profile.jpg";
import ArchiveItem from "./ArchiveItem";
import { fetchArchive } from "../../actions/archive";
import { Loading } from "../utils/loading";
import i18next from "i18next";

const Archive = () => {
  const { t } = useTranslation();
  const { archive, archiveAreLoading } = useSelector((state) => state.archive);
  const dispatch = useDispatch();
  let locale = i18next.language;

  useEffect(() => {
    dispatch(fetchArchive(locale));
  }, [dispatch, locale]);

  const title = {
    caption: t("pages.archive.hero.title"),
  };

  return (
    <MainLayout title={title} global={false}>
      <div className="page__header page__header_plan page__header_short">
        <img className="page__header-cover" src={profileBg} alt="archive" />
        <div className="page__header-body page__header-body_center">
          <Title decor={false} className="page__header-title" data-key="pages.archive.hero.title">
            {t("pages.archive.hero.title")}
          </Title>
        </div>
      </div>
      <div className="page__blocks">
        <Section>
          <Title className="schedule__title" type="h2" data-key="pages.archive.title">
            {t("pages.archive.title")}
          </Title>

          {archiveAreLoading ? (
            <Loading />
          ) : (
            archive.length > 0 && (
              <div className="collapse">
                {archive.map(({ name, place, username, start_at, end_at, extra }, key) => (
                  <ArchiveItem start_at={start_at} end_at={end_at} name={name} username={username} place={place} extra={extra} key={key} />
                ))}
              </div>
            )
          )}
        </Section>
      </div>
    </MainLayout>
  );
};

export default Archive;
