import axios from "axios";
import {
  PROGRAMS_DEGREES,
  PROGRAMS_DEGREES_LOADING,
  PROGRAMS_FORMS,
  PROGRAMS_FORMS_LOADING,
  PROGRAMS_CATEGORIES,
  PROGRAMS_CATEGORIES_LOADING,
  PROGRAM,
  PROGRAM_LOADING,
  PROGRAM_LOADED,
  PROGRAMS,
  PROGRAMS_ARE_LOADING,
  PROGRAMS_POPULAR,
  PROGRAMS_POPULAR_LOADING,
} from "../constants/programs";

import { API_URL } from "../config/config";
import { SOURCE } from "../constants/global";

export function fetchProgramsDegrees(params) {
  return (dispatch) => {
    dispatch(programsDegreesAreLoading(true));

    const config = { params: { source: SOURCE, ...params } };

    axios
      .get(`${API_URL}degrees`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programsDegreesSuccess(response.data.data));
        dispatch(programsDegreesAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading programs degrees", e));
  };
}

function programsDegreesAreLoading(bool) {
  return {
    type: PROGRAMS_DEGREES_LOADING,
    bool,
  };
}

function programsDegreesSuccess(data) {
  return {
    type: PROGRAMS_DEGREES,
    data,
  };
}

export function fetchProgramsForms(params) {
  return (dispatch) => {
    dispatch(programsFormsAreLoading(true));

    const config = { params: { source: SOURCE, ...params } };

    axios
      .get(`${API_URL}forms`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programsFormsSuccess(response.data.data));
        dispatch(programsFormsAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading programs forms", e));
  };
}

function programsFormsAreLoading(bool) {
  return {
    type: PROGRAMS_FORMS_LOADING,
    bool,
  };
}

function programsFormsSuccess(data) {
  return {
    type: PROGRAMS_FORMS,
    data,
  };
}

export function fetchProgramsCategories(params) {
  return (dispatch) => {
    dispatch(programsCategoriesAreLoading(true));

    const config = { params: { source: SOURCE, ...params } };

    axios
      .get(`${API_URL}categories`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programsCategoriesSuccess(response.data.data));
        dispatch(programsCategoriesAreLoading(false));

        return response;
      })
      .catch((e) => console.log("error loading programs categories", e));
  };
}

function programsCategoriesAreLoading(bool) {
  return {
    type: PROGRAMS_CATEGORIES_LOADING,
    bool,
  };
}

function programsCategoriesSuccess(data) {
  return {
    type: PROGRAMS_CATEGORIES,
    data,
  };
}

export function fetchProgram(id, locale) {
  return (dispatch) => {
    dispatch(programIsLoading(true));
    dispatch(programIsLoaded(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}programs/${id}`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programSuccess(response.data.data));
        dispatch(programIsLoading(false));
        dispatch(programIsLoaded(true));

        return response;
      })
      .catch((e) => {
        console.log("error loading program", e);
        dispatch(programIsLoaded(false));
      });
  };
}

function programIsLoading(bool) {
  return {
    type: PROGRAM_LOADING,
    bool,
  };
}

function programIsLoaded(bool) {
  return {
    type: PROGRAM_LOADED,
    bool,
  };
}

function programSuccess(data) {
  return {
    type: PROGRAM,
    data,
  };
}

export function fetchPrograms(params) {
  return (dispatch) => {
    dispatch(programsAreLoading(true));

    const config = { params: { source: SOURCE, ...params } };

    axios
      .get(`${API_URL}programs`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programsSuccess(response.data.data));
        dispatch(programsAreLoading(false));

        return response;
      })
      .catch((e) => {
        console.log("error loading programs by search", e);
      });
  };
}

function programsAreLoading(bool) {
  return {
    type: PROGRAMS_ARE_LOADING,
    bool,
  };
}

function programsSuccess(data) {
  return {
    type: PROGRAMS,
    data,
  };
}

export function fetchProgramsPopular(locale) {
  return (dispatch) => {
    dispatch(programsPopularIsLoading(true));

    const config = { params: { source: SOURCE, locale: locale } };

    axios
      .get(`${API_URL}programs/popular`, config)
      .then((response) => {
        return response;
      })
      .then((response) => {
        dispatch(programsPopularSuccess(response.data.data));
        dispatch(programsPopularIsLoading(false));

        return response;
      })
      .catch((e) => {
        console.log("error loading program popular", e);
      });
  };
}

function programsPopularIsLoading(bool) {
  return {
    type: PROGRAMS_POPULAR_LOADING,
    bool,
  };
}

function programsPopularSuccess(data) {
  return {
    type: PROGRAMS_POPULAR,
    data,
  };
}
