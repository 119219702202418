import {useEffect, useState} from "react";
import {useSelector, useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import {Layout, message} from "antd";

import {errorMessage, isLoggedIn, successMessage, getNoticeCookies} from "../../actions/global";
import AppHeader from "./header";
import AppFooter from "./footer";
import CookiesNotice from "./cookies-notice";
import Chat from './chat';
import ChatBtnConsult from "./common/consult-chat-btn";
import {
    MESSAGE_DEFAULT_DURATION,
    MESSAGE_DESKTOP_TOP_OFFSET,
    MESSAGE_MOBILE_TOP_OFFSET,
    MESSAGE_TABLET_TOP_OFFSET
} from "../../constants/global";

import {ErrorsList} from "../utils/errors-list";
import DocumentTitle from "../utils/document-title";
import {ErrorIcon, SuccessIcon} from "../utils/svg";
import useWindowSize from "../../hooks/size";

const {Content} = Layout;

function MainLayout(props) {
    const {chat = true} = props;
    const dispatch = useDispatch();
    const location = useLocation();
    const size = useWindowSize();
    const [messageOffset, setMessageOffset] = useState(0);
    const {responseSuccess, responseErrors} = useSelector(state => state.global);
    const {session, sessionLoaded} = useSelector(state => state.users);

    const isMobile = size.width < 768;
    const isTablet = size.width >= 768 && size.width < 1025;
    const loggedIn = isLoggedIn();
    let noticeCookies = getNoticeCookies();

    useEffect(() => {
        return () => {
            message.destroy();
            dispatch(errorMessage([]));
            dispatch(successMessage([]));
        };
    }, [dispatch, location]);

    useEffect(() => {
        const newMessageOffset = isMobile ? MESSAGE_MOBILE_TOP_OFFSET : (isTablet ? MESSAGE_TABLET_TOP_OFFSET : MESSAGE_DESKTOP_TOP_OFFSET);
        setMessageOffset(newMessageOffset);
        message.config({
            top: newMessageOffset,
            maxCount: 1
        });
    }, [isMobile, isTablet]);

    useEffect(() => {
        if(responseErrors.length) {
            message.error({
                content: <ErrorsList data={responseErrors}/>,
                duration: MESSAGE_DEFAULT_DURATION,
                icon: <ErrorIcon/>,
                onClose: () => {
                    dispatch(errorMessage([]));
                }
            });
        }
    }, [dispatch, responseErrors]);

    useEffect(() => {
        if(responseSuccess.length) {
            message.success({
                content: <ErrorsList data={responseSuccess}/>,
                duration: MESSAGE_DEFAULT_DURATION,
                icon: <SuccessIcon/>,
                onClose: () => {
                    dispatch(successMessage([]));
                }
            });
        }
    }, [dispatch, responseSuccess]);

    return (
        <Layout>
            {props.title ? <DocumentTitle item={props.title} global={props.global} /> : null }
            <AppHeader/>
            <Content>
                <div className="content page">
                    {props.children}
                </div>
            </Content>
            <AppFooter user={session}/>

            {chat && loggedIn && sessionLoaded ? (
                <Chat user={session} />
            ) : (
                <div className="chat">
                    <div className="chat__buttons chat__buttons_single">
                        <ChatBtnConsult isShowButtons={true} />
                    </div>
                </div>
            )}
            {noticeCookies !== "yes" && <CookiesNotice />}
        </Layout>
    );
}

export default MainLayout;
